import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackArrowIcon, SearchIcon2 } from "../common/Icons";
import userImg from "../../assets/images/png/user-img2.png";
import { UseTopCreatorsContext } from "../ContextApis/TopCreatorsAndFollow";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";

const StreaRecommendedPeople = () => {
  const navigate = useNavigate();
  const [showpop, setShowpop] = useState(false);
  const [searchdata, setSearchdata] = useState("");
  const [followedUserID, setfollowedUserID] = useState("");
  const {
    getTopCreators,
    topCreators,
    followUser,
    location,
    university,
    unFollowUser,
    getPeoplebylocations,
    getUniversityorSchool,
    universityorschool,
    filterpeoplelocation,
  } = UseTopCreatorsContext();
  const { checkIsFollow } = UseCreatePostData();

  async function handleFollow(username) {
    followUser(username);
  }
  useEffect(() => {
    getPeoplebylocations();
    getUniversityorSchool();
    getTopCreators();
  }, []);
  useEffect(() => {
    if (searchdata.length > 0) {
      setShowpop(true);
    } else {
      setShowpop(false);
    }
  }, [searchdata]);
  function goback() {
    navigate(-1);
  }

  let totalPeople = [];

  if (Array.isArray(filterpeoplelocation) && filterpeoplelocation.length > 0) {
    totalPeople = [...totalPeople, ...filterpeoplelocation];
  }
  if (Array.isArray(universityorschool) && universityorschool.length > 0) {
    totalPeople = [...totalPeople, ...universityorschool];
  }
  if (Array.isArray(topCreators) && topCreators.length > 0) {
    totalPeople = [...totalPeople, ...topCreators];
  }

  async function filterfollowuser() {
    let id = [];
    if (
      Array.isArray(filterpeoplelocation) ||
      Array.isArray(topCreators) ||
      Array.isArray(universityorschool)
    ) {
      for (let filter of totalPeople) {
        let message = await checkIsFollow(filter?.userName);
        if (message === "following" && !id.includes(filter.id)) {
          id.push(filter.id);
        }
      }
      setfollowedUserID(id);
    }
  }
  useEffect(() => {
    if (Array.isArray(filterpeoplelocation) && filterpeoplelocation.length > 0) {
      filterfollowuser();
    }
  }, [
    Array.isArray(filterpeoplelocation) && filterpeoplelocation.length > 0,
    Array.isArray(topCreators) && topCreators.length > 0,
    Array.isArray(universityorschool) && universityorschool.length > 0,
  ]);

  function followUnfollow(id) {
    if (followedUserID.includes(id)) {
      let ids = followedUserID.filter((item) => item !== id);
      setfollowedUserID(ids);
    } else {
      setfollowedUserID([...followedUserID, id]);
    }
  }
  const uniqueUsernames = new Set();

  const filterdata = totalPeople.filter((item) => {
    const matchesSearch = item.fullName.toLowerCase().includes(searchdata.toLowerCase());

    // Check if the username is unique and matches the search term
    if (matchesSearch && !uniqueUsernames.has(item.userName)) {
      uniqueUsernames.add(item.userName);
      return true; // Include this item in the filtered data
    }

    return false; // Exclude this item
  });
  return (
    <>
      <section className="lg:mb-7">
        <div className=" container mx-auto px-5">
          <div className="flex justify-start sm:justify-center items-center mt-4 sm:mt-10">
            <button onClick={goback} className="cursor-pointer">
              <BackArrowIcon />
            </button>
            <p className="sm:text-xl text-base font-semibold ms-[18px]">Recommended people</p>
          </div>
          <div className="text-center flex justify-center mt-[16px] sm:mt-10 relative">
            <div className="flex justify-between bg-[#EEEFEF] w-[641px] items-center rounded-[40px] px-4 pe-7">
              <input
                onChange={(e) => {
                  setSearchdata(e.target.value);
                }}
                type="text"
                placeholder="search"
                className="border-none outline-none bg-transparent h-[40px] md:h-[58px] w-full px-2 "
              />
              <SearchIcon2 />
            </div>
            {showpop && (
              <div className="max-h-[350px] max-w-[600px] w-full md:max-w-[631px] bg-white rounded-xl shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] overflow-auto pb-5 px-4 absolute top-[47px] sm:top-[55px] md:top-[68px] left-2/4 -translate-x-2/4">
                {filterdata.map((item, i) => (
                  <div
                    className="flex items-center justify-between sm:mt-50 mt-[18px] md:mt-5"
                    key={i}>
                    <div className="flex items-center gap-2 md:gap-4 ">
                      <div className="rounded-full">
                        <img
                          className="h-[42px] w-[42px] md:h-[42px] md:w-[42px] lg:h-[67px] lg:w-[67px] rounded-full"
                          src={item.profileImageUrl || userImg}
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="font-bold lg:text-lg md:text-base text-base text-start">
                          {item.fullName}
                        </p>
                        <p className="font-normal md:text-sm lg:text-[18px] text-sm">
                          {item.designation}
                        </p>
                      </div>
                    </div>
                    <button
                      className={`border md:px-[30px] md:py-2 lg:px-[44px] lg:py-[18px] p-2 rounded-full text-[12px] md:text-sm lg:text-base  ${
                        !followedUserID.includes(item.id)
                          ? "bg-[#4D8AFF] text-white"
                          : "text-[black] bg-[white]"
                      }`}
                      onClick={() => {
                        if (!followedUserID.includes(item.id)) {
                          handleFollow(item.userName);
                        } else {
                          unFollowUser(item.userName);
                        }
                        followUnfollow(item.id);
                      }}>
                      {followedUserID.includes(item.id) ? "Following" : "Follow"}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {Array.isArray(topCreators) && topCreators.length > 0 && (
          <div>
            <p className="sm:text-xl text-sm font-semibold text-center mt-[18px] sm:mt-10">
              Top Creators
            </p>
            {Array.isArray(topCreators) &&
              topCreators.length > 0 &&
              topCreators.slice(0, 5).map((item, i) => {
                return (
                  <div
                    className="flex items-center justify-between sm:mt-50 mt-[18px] md:mt-10 container mx-auto px-5"
                    key={i}>
                    <div className="flex items-center gap-2 md:gap-4 ">
                      <div className="rounded-full">
                        <img
                          className="h-[42px] w-[42px] md:h-[42px] md:w-[42px] lg:h-[67px] lg:w-[67px] rounded-full"
                          src={item.profileImageUrl || userImg}
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="font-bold lg:text-lg text-base ">{item.fullName}</p>
                        <p className="font-normal text-sm lg:text-[18px] ">{item.designation}</p>
                      </div>
                    </div>
                    <button
                      className={`border md:px-[30px] md:py-2 lg:px-[44px] lg:py-[18px] p-2 rounded-full text-[14px] md:text-sm lg:text-base  ${
                        !followedUserID.includes(item.id)
                          ? "bg-[#4D8AFF] text-white"
                          : "text-[black] bg-[white]"
                      }`}
                      onClick={() => {
                        if (!followedUserID.includes(item.id)) {
                          handleFollow(item.userName);
                        } else {
                          unFollowUser(item.userName);
                        }
                        followUnfollow(item.id);
                      }}>
                      {!followedUserID.includes(item.id) ? "Follow" : "Following"}
                    </button>
                  </div>
                );
              })}
            <div className="text-center sm:mt-10 mt-[18px]">
              <button
                className="font-normal text-sm sm:py-2.5 sm:px-6 md:px-[30px] md:py-2.5 py-2 border rounded-[40px] bg-[#4D8AFF] text-white px-6 md:text-sm lg:text-base lg:px-10 lg:py-[18px]"
                onClick={() => navigate(`/Allcreator/topCreators`)}>
                See All
              </button>
            </div>
            <div className="border w-full bg-[#BEC1C3] my-[18px] sm:hidden"></div>
          </div>
        )}
        {Array.isArray(filterpeoplelocation) && filterpeoplelocation.length > 0 && (
          <div>
            {" "}
            <p className="sm:text-xl text-sm font-semibold text-center sm:mt-[100px]">
              Nearby Creators From {location}
            </p>
            {Array.isArray(filterpeoplelocation) &&
              filterpeoplelocation.length > 0 &&
              filterpeoplelocation.slice(0, 5).map((item, i) => {
                return (
                  <div
                    className="flex items-center justify-between sm:mt-50 mt-[18px] md:mt-10 container mx-auto px-5"
                    key={i}>
                    <div className="flex items-center gap-2 md:gap-4 ">
                      <div className="rounded-full">
                        <img
                          className="h-[42px] w-[42px] md:h-[42px] md:w-[42px] lg:h-[67px] lg:w-[67px] rounded-full"
                          src={item.profileImageUrl || userImg}
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="font-bold lg:text-lg text-base ">{item.fullName}</p>
                        <p className="font-normal text-sm lg:text-[18px] ">{item.designation}</p>
                      </div>
                    </div>
                    <button
                      className={`border md:px-[30px] md:py-2 lg:px-[44px] lg:py-[18px] p-2 rounded-full text-[14px] md:text-sm lg:text-base  ${
                        !followedUserID.includes(item.id)
                          ? "bg-[#4D8AFF] text-white"
                          : "text-[black] bg-[white]"
                      }`}
                      onClick={() => {
                        if (!followedUserID.includes(item.id)) {
                          handleFollow(item.userName);
                        } else {
                          unFollowUser(item.userName);
                        }
                        followUnfollow(item.id);
                      }}>
                      {!followedUserID.includes(item.id) ? "Follow" : "Following"}
                    </button>
                  </div>
                );
              })}
            <div className="text-center sm:mt-10 mt-[18px]">
              <button
                className="font-normal text-sm sm:py-2.5 sm:px-6 md:px-[30px] md:py-2.5 py-2 border rounded-[40px] bg-[#4D8AFF] text-white px-6 md:text-sm lg:text-base lg:px-10 lg:py-[18px]"
                onClick={() => navigate(`/Allcreator/byLocation`)}>
                See All
              </button>
            </div>
            <div className="border w-full bg-[#BEC1C3] my-[18px] sm:hidden"></div>
          </div>
        )}
        {Array.isArray(universityorschool) && universityorschool.length > 0 && (
          <div>
            <p className="sm:text-xl text-sm font-semibold text-center sm:mt-[100px]">
              From {university}
            </p>
            {Array.isArray(universityorschool) &&
              universityorschool.length > 0 &&
              universityorschool.slice(0, 5).map((item, i) => (
                <div
                  className="flex items-center justify-between sm:mt-50 mt-[18px] md:mt-10 container mx-auto px-5"
                  key={i}>
                  <div className="flex items-center gap-2 md:gap-4 ">
                    <div className="rounded-full">
                      <img
                        className="h-[42px] w-[42px] md:h-[42px] md:w-[42px] lg:h-[67px] lg:w-[67px] rounded-full"
                        src={item.profileImageUrl || userImg}
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="font-bold lg:text-lg text-base ">{item.fullName}</p>
                      <p className="font-normal text-sm lg:text-[18px] ">{item.designation}</p>
                    </div>
                  </div>
                  <button
                    className={`border md:px-[30px] md:py-2 lg:px-[44px] lg:py-[18px] p-2 rounded-full text-[14px] md:text-sm lg:text-base  ${
                      !followedUserID.includes(item.id)
                        ? "bg-[#4D8AFF] text-white"
                        : "text-[black] bg-[white]"
                    }`}
                    onClick={() => {
                      if (!followedUserID.includes(item.id)) {
                        handleFollow(item.userName);
                      } else {
                        unFollowUser(item.userName);
                      }
                      followUnfollow(item.id);
                    }}>
                    {!followedUserID.includes(item.id) ? "Follow" : "Following"}
                  </button>
                </div>
              ))}
            <div className="text-center sm:mt-10 mt-[18px]">
              <button
                className="font-normal text-sm sm:py-2.5 sm:px-6 md:px-[30px] md:py-2.5 py-2 border rounded-[40px] bg-[#4D8AFF] text-white px-6 md:text-sm lg:text-base lg:px-10 lg:py-[18px]"
                onClick={() => navigate(`/Allcreator/universityOrSchool`)}>
                See All
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default StreaRecommendedPeople;
