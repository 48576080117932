import React from "react";
import { useState, useEffect } from "react";
import { BackArrowIcon, CrossIcon } from "../common/Icons";
import StepBar from "../common/StepBar";
import PersonaliseCommonBtn from "../common/PersonaliseCommonBtn";
import { useNavigate } from "react-router-dom";
import UserInterestPopup from "./UserIntrestPopup";
import RecommondedPeople from "./RecommondedPeople";
import UserPersonalDetails from "./UserPersonalDetails";
import ProfessionalDetails from "./ProfessionalDetails";
import EducationalDetails from "./EducationalDetails";
import Welcome from "../Authentication/Welcome";
import { UseInterestContext } from "../ContextApis/SettingsContext";
import showErrorToast from "../ToastError";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import { UseAuth } from "../ContextApis/AuthContext";
export default function PersonliseComman({ setPersonlisePopup, signInRef, personlisePopup }) {
  const [activeSteps, setActiveSteps] = useState(0);
  const [seletedInterest, setSelectedInterest] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [educationError, seteducationError] = useState("");
  const [professnalError, setprofessnalError] = useState("");
  const [professionalData, setProfessionalData] = useState([]);
  const [folllowCount, setFollowCount] = useState([]);
  const [formData, setFormData] = useState({
    username: "",
    day: "",
    month: "",
    year: "",
  });

  const totalSteps = 5;
  const navigate = useNavigate();
  const { addInterest } = UseInterestContext();
  const { addProfile, addEducation, addProfessional } = UseProfileContext();
  const { activeStepsLogin, setactiveStepsLogin } = UseAuth();
  const [username, setusername] = useState("");
  useEffect(() => {
    setusername(
      localStorage.getItem("userName") === null
        ? sessionStorage.getItem("userName")
        : localStorage.getItem("userName")
    );
    if (username === "null") {
      setActiveSteps(1);
    }
  }, [username]);
  useEffect(() => {
    if (activeStepsLogin === 1) {
      setActiveSteps(activeSteps + 1);
      setactiveStepsLogin("");
    }
  }, [
    activeStepsLogin,
    localStorage.getItem("userName") === "null" || sessionStorage.getItem("userName") === "null",
  ]);

  const stepComponents = [
    <UserInterestPopup
      seletedInterest={seletedInterest}
      setSelectedInterest={setSelectedInterest}
    />,
    <UserPersonalDetails formData={formData} setFormData={setFormData} />,
    <EducationalDetails
      educationData={educationData}
      setEducationData={setEducationData}
      seteducationError={seteducationError}
    />,
    <ProfessionalDetails
      professionalData={professionalData}
      setProfessionalData={setProfessionalData}
      setprofessnalError={setprofessnalError}
    />,
    <RecommondedPeople setFollowCount={setFollowCount} folllowCount={folllowCount} />,
  ];

  function back() {
    if (activeSteps > 0) {
      setActiveSteps(activeSteps - 1);
    } else if (activeSteps === 0) {
      navigate("/");
    } else {
      navigate(-1);
    }
  }

  // Interest funcations
  const requestBody = {
    interests: seletedInterest,
    otherInterestsList: [],
  };

  function handleSkipStep() {
    setActiveSteps(activeSteps + 1);
  }
  const handleContinueClick = async () => {
    if (activeSteps === 0) {
      if (seletedInterest.length >= 5) {
        addInterest(requestBody);
        setActiveSteps(activeSteps + 1);
        sessionStorage.removeItem("user_register_details");
      } else {
        showErrorToast("Choose minimum 5 or more");
      }
    }
    if (activeSteps === 1) {
      if (
        formData.username !== "" &&
        formData.day !== "" &&
        formData.month !== "" &&
        formData.year !== ""
      ) {
        let userBasicData = {
          // dob: "02-05-2001",
          dob: `${formData.day <= 9 ? "0" : ""}${formData.day}-${formData.month <= 9 ? "0" : ""}${
            formData.month
          }-${formData.year}`,
          userName: formData.username,
        };
        addProfile(userBasicData, setActiveSteps, activeSteps);
      } else {
        showErrorToast("All field required !");
      }
    }
    if (activeSteps === 2) {
      if (educationError !== "") {
        showErrorToast(
          "Please click on add button if you want this field data otherwise you lose this data"
        );
        seteducationError("");
      }
      if (educationData.length === 0) {
        showErrorToast("Please add minimum one data otherwise click on skip button for next step");
      }
      if (educationData.length !== 0 && educationError === "") {
        for (let education of educationData) {
          let data = {
            universityName: education.school,
            instituteName: education.Institute,
            location: "India",
            type: "COLLEGE",
            specializationStream: education.Specialisation,
            fromDate: `01-${education.from_first <= 9 ? "0" : ""}${education.from_first}-${
              education.from_end
            }`,
            toDate:
              education.Currently_studying !== "yes"
                ? `30-${education.till_first <= 9 ? "0" : ""}${education.till_first}-${
                    education.till_end
                  }`
                : "",
            currentlyStudying: education.Currently_studying === "yes" ? true : false,
            currentYearClass: Number(education.if_Currently_studying_Year),
            currentSemester: Number(education.if_Currently_studying_Semester),
          };
          await addEducation(data);
        }
        setActiveSteps(activeSteps + 1);
      }
    }
    if (activeSteps === 3) {
      if (professnalError !== "") {
        showErrorToast(
          "Please click on add button if you want this field data otherwise you lose this data"
        );
        setprofessnalError("");
      }
      if (professionalData.length == 0) {
        showErrorToast("Please add minimum one data otherwise click on skip button for next step");
      }
      if (professionalData.length !== 0 && professnalError === "") {
        for (let professional of professionalData) {
          let data = {
            companyName: professional.Company,
            industry: professional.Industry,
            isCurrentlyEmployed: professional.Currently_working === "yes" ? true : false,
            designation: professional.Designation,
            fromDate: `01-${professional.from_first <= 9 ? "0" : ""}${professional.from_first}-${
              professional.from_end
            }`,
            toDate:
              professional.Currently_working !== "yes"
                ? `30-${professional.till_first <= 9 ? "0" : ""}${professional.till_first}-${
                    professional.till_end
                  }`
                : "",
            location: professional.Location,
          };
          await addProfessional(data);
        }
        setActiveSteps(activeSteps + 1);
        setProfessionalData([]);
      }
    }
    if (activeSteps === 4) {
      if (folllowCount.length >= 5) {
        navigate("/home");
        window.location.reload();
      } else {
        showErrorToast("Follow minimum 5 people or more !");
      }
    }
  };
  function handleClosePopup() {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
    if (personlisePopup) {
      setPersonlisePopup(false);
    }
  }
  return (
    <>
      <div className="hidden md:flex sm:blur-xl fixed inset-0">
        <Welcome />
      </div>
      <div
        ref={signInRef}
        className="md:max-w-[540px] w-full max-md:min-h-screen md:rounded-[40px] bg-[#ffffff] shadow absolute   md:py-[20px] 2xl:py-[43px] md:top-1/2 top-0 left-0 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 flex flex-col z-[50]">
        <div className="sticky top-0 bg-white">
          <div className="flex md:justify-between items-start md:items-center pt-3 px-2 sm:px-[22px] gap-4">
            <button
              onClick={() => {
                activeSteps === 0 ? handleClosePopup() : back();
              }}
              className="max-md:mt-1.5 max-sm:ps-4">
              <BackArrowIcon />
            </button>
            <div>
              <h2 className="font-semibold text-base text-black md:text-center">Personalise</h2>
              {activeSteps === 2 && <p className="text-xs mb-0 text-center">Educational details</p>}
              {activeSteps === 3 && (
                <p className="text-xs mb-0 text-center">Professional details</p>
              )}
            </div>
            <button
              type="button"
              className="hidden md:flex"
              onClick={() => {
                handleClosePopup();
              }}>
              <CrossIcon />
            </button>
          </div>
          <StepBar activeSteps={activeSteps} totalSteps={totalSteps} />
        </div>
        <div className="flex flex-col justify-between">
          <div className="md:flex-grow flex md:h-[420px] flex-col max-md:h-full">
            {stepComponents[activeSteps]}
          </div>
          <div>
            <div
              className={`${
                activeSteps === 0 || activeSteps === 1 ? "mt-10" : "mt-4"
              } md:mt-[36px] flex justify-center max-sm:text-sm  pb-4 px-4`}>
              <PersonaliseCommonBtn onClick={handleContinueClick} />
            </div>
            {(activeSteps === 2 || activeSteps === 3) && (
              <div className="flex justify-center h-[56px] mb-5 px-4">
                <button
                  onClick={handleSkipStep}
                  className="border border-solid border-grey rounded-full w-[320px] py-4  max-sm:text-sm">
                  Skip
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
