import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../common/NavBar";

const HelpPrivacyMain = () => {
  const [selectedPath, setSelectedPath] = useState(
    "/settings/accounts-settings"
  );
  const navigate = useNavigate();

  const handleSidebarClick = (path) => {
    setSelectedPath(path);
    navigate(path);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        navigate(`/settings`);
      } else {
        navigate(selectedPath);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigate, selectedPath]);

  return (
    <div>
      <div className=" hidden sm:block sticky top-0 left-0 z-50 bg-white">
        <NavBar />
      </div>
      <div className="flex flex-wrap">
        <div className="hidden sm:block sticky top-[81.6px] h-[calc(100vh-81.6px)] w-full sm:w-1/3 lg:w-3/12 border-r border-[#efeff0] bg-white z-50">
          <SideBar
            onSidebarClick={handleSidebarClick}
            selectedPath={selectedPath}
          />
        </div>
        <div className="w-full sm:w-2/3 lg:w-9/12">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default HelpPrivacyMain;