import React, { useState, useEffect, useRef } from "react";
import bansal_sndp from "../../assets/images/jpeg/dummyimage.jpg";
import profile_bg from "../../assets/images/png/bg-default.png";
import { About_Icon, CloseIcon, ThreeDots, Vertical_edit_btn } from "../common/Icons";
import SeeAllButton from "../Home/SeeAllButton";
import AboutPopup from "./AboutPopup";
import Follower from "./FollowersPopup";
import Following from "./FollowingPopup";
import EditProfilePopup from "./EditProfilePopup";
import success_giff from "../../assets/gif/success.gif";
import { useLocation, useParams } from "react-router-dom";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import { UseTopCreatorsContext } from "../ContextApis/TopCreatorsAndFollow";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
import { isObject } from "lodash";
const ProfileHeader = ({ btn, setIsblock, isBlock }) => {
  const {
    profileData,
    blockUser,
    unblockUser,
    getOtherUserProfileData,
    reportUser,
    getProfileData,
    getFollowingData,
    getFollowersData,
  } = UseProfileContext();
  const location = useLocation();
  const currentPath = location.pathname;
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [aboutPopup, setAboutPopup] = useState(false);
  const [showThreeDots, setShowThreeDots] = useState(false);
  const [block, setBlock] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [report, setReport] = useState(false);
  const [reported, setReported] = useState(false);
  const [followersPopup, setFollowersPopup] = useState(false);
  const [followingPopup, setFollowingsPopup] = useState(false);
  const [EditProfilePopups, setEditProfilePopup] = useState(false);
  const [userData, setUserData] = useState({});
  const [unblock, setUnblock] = useState(false);
  const { username } = useParams();
  const [selectedReportType, setSelectedReportType] = useState("");
  const [reason, setReason] = useState("");
  const [checkFollowing, setCheckFollowing] = useState([]);
  const { unFollowUser, followUser } = UseTopCreatorsContext();
  const { checkIsFollow } = UseCreatePostData();
  useEffect(() => {
    localStorage.setItem("userImg", userData?.profileImageUrl);
  });
  const handleReportSubmit = async () => {
    const reportData = {
      reportedUserName: userData.userName,
      reportReason: selectedReportType,
      reportDescription: reason,
    };

    try {
      if (!selectedReportType) {
        toast.success("Please select a report reason.");
        return;
      }
      const response = await reportUser(reportData);
      if (response.ok) {
        setReported(true);
        setReport(false);
      } else {
        toast.error("Failed to report user. Please try again.");
      }
    } catch (error) {
      console.error("Failed to report user:", error);
    }
  };
  async function isFollowed() {
    let id = [];
    let message = await checkIsFollow(userData.userName);
    if (message === "following") {
      id.push(userData.id);
    }

    setCheckFollowing(id);
  }
  useEffect(() => {
    if (userData) {
      isFollowed();
    }
  }, [userData]);

  function showfollowerpopup() {
    setFollowersPopup(true);
  }
  function showfollowingpopup() {
    setFollowingsPopup(true);
  }

  function followUnfollow(id) {
    if (checkFollowing.includes(id)) {
      let ids = checkFollowing.filter((item) => item !== id);
      setCheckFollowing(ids);
    } else {
      setCheckFollowing([...checkFollowing, id]);
    }
  }
  async function getUserProfileData() {
    const otherUserData = await getOtherUserProfileData(username);
    setUserData(otherUserData);
  }
  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    if (isObject(userData) && Object.keys(userData).length > 0) {
      getFollowersData(userData.userName);
      getFollowingData(userData.userName);
    }
  }, [userData]);
  const shouldShowThreeDotsButton = currentPath.includes("ViewByUserprofile");
  useEffect(() => {
    if (username !== undefined) {
      getUserProfileData();
    }
    if (username === undefined) {
      setUserData(profileData);
    }
  }, [username || profileData || location.pathname]);

  const handleBlockUser = async () => {
    if (userData && userData.userName) {
      await blockUser(userData.userName); // Pass the username to block
      setBlocked(true); // Set blocked state to true
      setBlock(false); // Close the block confirmation popup
      setIsblock(true);
      getUserProfileData();
    }
  };
  const handleUnblockUser = async () => {
    if (userData && userData.userName) {
      await unblockUser(userData.userName);
      setUnblock(false);
      setIsblock(false);
      getUserProfileData();
    }
  };
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (
    aboutPopup ||
    report ||
    blocked ||
    reported ||
    block ||
    showThreeDots ||
    followingPopup ||
    followersPopup ||
    EditProfilePopups ||
    unblock
  ) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }

  const popupRef = useRef();

  function handleClickOutside(event) {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setBlock(false);
      setShowThreeDots(false);
      setBlocked(false);
      setReport(false);
      setFollowersPopup(false);
      setFollowingsPopup(false);
      setEditProfilePopup(false);
      setAboutPopup(false);
      setUnblock(false);
    }
  }
  useEffect(() => {
    if (
      block ||
      showThreeDots ||
      blocked ||
      report ||
      reported ||
      followersPopup ||
      followingPopup ||
      EditProfilePopups ||
      aboutPopup ||
      unblock
    ) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    block,
    showThreeDots,
    blocked,
    report,
    reported,
    followersPopup,
    followingPopup,
    EditProfilePopups,
    aboutPopup,
    unblock,
  ]);

  const [followingPopupData, setFollowingPopupData] = useState(null);
  const handleDataFromChild = (childData) => {
    setFollowingPopupData(childData);
  };

  return (
    <>
      {(block ||
        showThreeDots ||
        blocked ||
        report ||
        reported ||
        followersPopup ||
        followingPopup ||
        EditProfilePopups ||
        aboutPopup ||
        unblock) && (
        <div className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full asdads"></div>
      )}
      {EditProfilePopups && (
        <div ref={popupRef}>
          <EditProfilePopup
            setEditProfilePopup={setEditProfilePopup}
            initialData={{
              profileImage: profileData.profileImageUrl,
              coverImage: profileData.coverImageUrl,
              fullName: profileData.fullName,
              userName: profileData.userName,
              designation: profileData.designation,
              bio: profileData.bio,
            }}
          />
        </div>
      )}
      {followersPopup && (
        <div ref={popupRef}>
          <Follower
            userData={userData}
            setUserData={setUserData}
            setFollowersPopup={setFollowersPopup}
          />
        </div>
      )}
      {followingPopup && (
        <div ref={popupRef}>
          <Following
            userData={userData}
            setUserData={setUserData}
            onSendData={handleDataFromChild}
            setFollowingPopup={setFollowingsPopup}
          />
        </div>
      )}
      {aboutPopup && (
        <div ref={popupRef}>
          <AboutPopup userName={userData?.userName} setAboutPopup={setAboutPopup} />
        </div>
      )}
      {showThreeDots && (
        <div
          ref={popupRef}
          className="max-w-[185px] sm:max-w-[320px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]  text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex sm:flex-row flex-col items-center sm:scroll-auto justify-center shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
          <button
            onClick={() => {
              setShowThreeDots(false);
              setBlock(true);
            }}
            className=" font-normal sm:text-base text-xs text-red-500 py-2 inline-block cursor-pointer">
            Block
          </button>
          <hr className="w-full" />
          <button
            onClick={() => {
              setReport(true);
              setShowThreeDots(false);
            }}
            className=" font-normal sm:text-base text-xs text-red-500 py-2 inline-block cursor-pointer">
            Report
          </button>
          <hr className="w-full" />

          <RWebShare
            data={{
              text: "Wised",
              url: `${username}`,
              title: "post",
            }}>
            <p
              onClick={() => setShowThreeDots(false)}
              className=" font-normal sm:text-base text-xs py-2 inline-block cursor-pointer">
              Share Profile
            </p>
          </RWebShare>
          <hr className="w-full" />

          <button
            className=" font-normal sm:text-base text-xs py-2 inline-block"
            onClick={() => setShowThreeDots(false)}>
            Cancel
          </button>
        </div>
      )}
      {block && (
        <div
          ref={popupRef}
          className="max-w-[320px] sm:max-w-[444px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] sm: py-[22px] sm:px-[42px] pb-[11px] pt-[38px] px-4 text-center w-full overflow-hidden z-[60] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] flex-col">
          <p className=" font-medium sm:text-base text-sm text-center  ">
            Block {userData.userName} ?
          </p>
          <p className="xl:text-base font-normal text-sm sm:my-6 my-4 text-center">
            They won't be able to find your profile and posts on Wised. We won't let them know that
            you've blocked them.
          </p>
          <div className="flex justify-center pb-6">
            <button className="inline-block me-[25px]">
              <div className="h-[40px]  justify-center items-center bg-primary flex rounded-[100px] ">
                <button
                  onClick={handleBlockUser}
                  className=" text-white font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-primary rounded-[100px] mx-auto sm:px-7 px-6">
                  Block
                </button>
              </div>
            </button>
            <button className="inline-block">
              <div className="h-[40px]  justify-center items-center bg-white flex rounded-[100px] ">
                <button
                  className=" text-black font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-white rounded-[100px] mx-auto sm:px-7 px-6 border-grey border"
                  onClick={() => setBlock(false)}>
                  Cancel
                </button>
              </div>
            </button>
          </div>
          <div
            className="absolute right-[20px] top-[15px] cursor-pointer"
            onClick={() => setBlock(false)}>
            <CloseIcon />
          </div>
        </div>
      )}
      {unblock && (
        <div
          ref={popupRef}
          className="max-w-[320px] sm:max-w-[444px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] xl:py-[59px] xl:px-10 sm:py-[53px] sm:px-[75px] py-[21px] px-[34px] flex-col shadow">
          <p className=" font-medium sm:text-base text-sm text-center ">
            Unblock {userData.userName}?
          </p>
          <p className="xl:text-base font-normal text-sm sm:my-[30px] my-17 text-center">
            They will now be able to see your profile and posts on Wised. We won't let them know
            that you've unblocked them.
          </p>
          <div className="flex justify-center">
            <button className="inline-block me-[25px]">
              <div className="h-[40px]  justify-center items-center bg-primary flex rounded-[100px] ">
                <button
                  onClick={handleUnblockUser}
                  className=" text-white font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-primary rounded-[100px] mx-auto sm:px-7 px-6">
                  Unblock
                </button>
              </div>
            </button>
            <button className="inline-block">
              <div className="h-[40px]  justify-center items-center bg-white flex rounded-[100px] ">
                <button
                  className=" text-black font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-white rounded-[100px] mx-auto sm:px-7 px-6 border-grey border"
                  onClick={() => setUnblock(false)}>
                  Cancel
                </button>
              </div>
            </button>
          </div>
          <button
            className="absolute right-[20px] top-[15px] cursor-pointer"
            onClick={() => setUnblock(false)}>
            <CloseIcon />
          </button>
        </div>
      )}
      {blocked && (
        <div
          ref={popupRef}
          className="max-w-[320px] sm:max-w-[444px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] flex-col px-9 py-6 ">
          <p className=" font-medium sm:text-base text-sm text-center ">
            Blocked {userData.userName}
          </p>
          <p className="xl:text-base font-normal text-sm  text-center pt-[11px]">
            You can unblock them from their profile or your settings.
          </p>
          <div
            className="absolute right-[20px] top-[15px] cursor-pointer"
            onClick={() => setBlocked(false)}>
            <CloseIcon />
          </div>
        </div>
      )}
      {report && (
        <div
          ref={popupRef}
          className="max-w-[320px] sm:max-w-[372px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] sm:pt-[45px] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] xl:pb-10 sm:px-[18px] sm:pb-[45px] px-6 py-5 flex-col">
          <p className=" font-medium sm:text-base text-sm text-center ">
            Report {userData.userName}?
          </p>
          <p className="xl:text-base font-normal text-sm sm:my-6 my-4 text-center">
            Use this option to report inappropriate content or behaviour and prevent further
            interaction with this user. Your action helps maintain a safe environment for our
            community. Thank you for your contribution to our platform's integrity.
          </p>
          <p className="xl:text-base font-normal text-sm sm:mb-6 mb-4  text-center">
            Why are you reporting this account ?
          </p>
          <div className="flex justify-center">
            <div className="">
              {["Spam", "Offensive contents", "Fake account", "Other"].map((type) => (
                <div className="flex items-center sm:mb-4 mb-[6px]" key={type}>
                  <input
                    type="radio"
                    name="report"
                    id={type}
                    value={type}
                    checked={selectedReportType === type}
                    onChange={(e) => setSelectedReportType(e.target.value)}
                  />
                  <label
                    htmlFor={type}
                    className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal">
                    {type}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <input
            type="text"
            placeholder="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className="bg-transparent border-b border-[#EEEFEF] w-[187px] max-sm:text-xs"
          />
          <div className="flex justify-center mt-6">
            <button className="inline-block me-[25px]">
              <div className="">
                <button
                  onClick={handleReportSubmit}
                  className=" text-white font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-primary rounded-[100px] mx-auto sm:px-7 px-6">
                  Report
                </button>
              </div>
            </button>
            <button className="inline-block">
              <div className="h-[40px]  justify-center items-center bg-white flex rounded-[100px] ">
                <button
                  className=" text-black font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-white rounded-[100px] mx-auto sm:px-7 px-6 border-grey border"
                  onClick={() => {
                    setReport(false);
                  }}>
                  Cancel
                </button>
              </div>
            </button>
          </div>
          <div
            className="absolute right-[20px] top-[15px] cursor-pointer"
            onClick={() => setReport(false)}>
            <CloseIcon />
          </div>
        </div>
      )}
      {reported && (
        <div
          ref={popupRef}
          className="max-w-[320px] sm:max-w-[444px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]  text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] flex-col  xl:px-[46px] xl:py-6 sm:px-[72px] sm:py-[47px] px-[39px] py-[21px] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] ">
          <img className="w-[72px] m-auto" src={success_giff} alt="" />
          <p className="xl:text-base font-normal text-sm sm:mt-[30px] sm:mb-[30px] text-center mt-[10px] mb-[10px]">
            Reported successfully. You can also block this account. They won't be able to find your
            profile and posts on Wised. We won't let them know that you've blocked them.
          </p>
          <div className="flex justify-center gap-5">
            <button
              onClick={() => {
                setBlock(true);
                setReported(false);
              }}
              className=" text-white font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-primary rounded-[100px] mx-auto sm:px-7 px-6">
              Block
            </button>

            <button
              className=" text-black font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-white rounded-[100px] mx-auto sm:px-7 px-6 border-grey border"
              onClick={() => {
                setReported(false);
              }}>
              Cancel
            </button>
          </div>
          <div
            className="absolute right-[20px] top-[15px] cursor-pointer"
            onClick={() => setReported(false)}>
            <CloseIcon />
          </div>
        </div>
      )}
      {userData ? (
        <>
          <div
            style={{
              backgroundImage: `url(${userData.coverImageUrl || profile_bg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
            className="w-full h-[109px] sm:h-[200px] md:h-[274px] "></div>
          {screenSize > 640 ? (
            <div className="md:max-w-[960px] xl:max-w-[1589px] sm:max-w-[653px] max-w-[327px] px-[54px] mx-auto  justify-between items-center mt-[-60px] xl:mb-9 sm:mb-[50px] mb-5 flex">
              <div className="">
                {userData && userData && (
                  <div className="border w-[130px] h-[145px] md:w-[216px] flex items-center mb-4 justify-center object-cover  max-w-[216px] md:h-[227px] rounded-[21px] max-h-[227px] border-[#dcdbdb]">
                    <img
                      src={userData.profileImageUrl || bansal_sndp}
                      alt="img"
                      className=" object-cover object-top  w-full h-full rounded-[21px]"
                    />
                  </div>
                )}
                <p className=" sm:font-semibold font-medium xl:text-xl text-sm xl:pb-4 sm:pb-[10px] pb-2">
                  {userData.userName}
                </p>
                <p
                  className=" font-normal xl:text-xl text-xs xl:pb-4 sm:pb-[10px] pb-2 max-w-[525px]"
                  style={{ wordBreak: "break-word" }}>
                  {userData.designation}
                </p>
                <p
                  className=" font-normal xl:text-xl text-xs xl:pb-4 sm:pb-[10px] pb-2  max-w-[525px]"
                  style={{ wordBreak: "break-word" }}>
                  {userData.bio}
                </p>
                <button onClick={() => setAboutPopup(true)} className="flex items-center">
                  <About_Icon />
                  <p className="font-normal xl:text-xl text-xs xl:ps-2 sm:ps-3 ps-[3px] sm:text-black text-primary">
                    About
                  </p>
                </button>
              </div>
              {currentPath.startsWith("/ViewByUserprofile") && (
                <button
                  onClick={() => {
                    if (checkFollowing.includes(userData.id)) {
                      unFollowUser(userData.userName);
                    } else {
                      followUser(userData.userName);
                    }
                    followUnfollow(userData.id);
                  }}
                  className={`text-base max-md:text-sm h-[28px]  px-[41px] md:h-[60px] rounded-[100px] max-md:px-2 max-sm:mt-1 ${
                    !checkFollowing.includes(userData.id)
                      ? "bg-primary text-white"
                      : "border border-[#BEC1C3]"
                  }`}>
                  {checkFollowing.includes(userData.id) ? "Following" : "Follow"}
                </button>
              )}
              <div className=" flex items-center ">
                <button
                  onClick={() => setFollowersPopup(true)}
                  className=" inline-block xl:pr-[70px] pr-6">
                  <p className=" font-semibold xl:text-xl sm:text-sm text-xs text-center">
                    {userData.followersCount || 0}
                  </p>
                  <p className="sm:font-semibold font-regular xl:text-xl">Followers</p>
                </button>
                <button onClick={() => setFollowingsPopup(true)}>
                  <p className=" font-semibold xl:text-xl sm:text-sm text-xs text-center">
                    {userData.followingCount || 0}
                  </p>
                  <p className="sm:font-semibold font-regular xl:text-xl">Following</p>
                </button>
              </div>
              <div>
                {btn === "editBtn" ? (
                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-3">
                      {currentPath === "/profile" && (
                        <button
                          onClick={() => {
                            setEditProfilePopup(true);
                          }}
                          className="xl:px-[25px] px-3 md:px-[38px] py-2 sm:py-[9px] md:py-[18px] h-[28px] sm:h-[40px] md:h-[60px] px-2 rounded-[100px] border-grey xl:text-base sm:text-sm text-xxs font-normal text-black border inline-block whitespace-nowrap">
                          Edit profile
                        </button>
                      )}
                      {shouldShowThreeDotsButton && !userData.blocked === true && (
                        <button onClick={() => setShowThreeDots(true)}>
                          <ThreeDots />
                        </button>
                      )}
                    </div>
                    {userData.blocked === true && (
                      <button onClick={() => setUnblock(true)}>
                        <SeeAllButton name={"Unblock"} />
                      </button>
                    )}
                  </div>
                ) : btn === "editIcon" ? (
                  <>
                    <div class="xl:block hidden">
                      <ThreeDots />
                    </div>
                    <div class="xl:hidden block">
                      <Vertical_edit_btn />
                    </div>
                  </>
                ) : btn === "BlockButton" ? (
                  <SeeAllButton name={"Unblock"} />
                ) : null}
              </div>
            </div>
          ) : (
            <div className="w-full px-4 mx-auto  justify-between sm:items-center sm:mt-[-60px] mt-[-50px] xl:mb-9 sm:mb-[50px] mb-5 flex">
              <div className="">
                {userData && userData && (
                  <img
                    src={userData.profileImageUrl || bansal_sndp}
                    alt="Cover"
                    className="xl:pb-[36px] sm:pb-[10px] mb-4 xl:max-w-[216px] xl:max-h-[227px] sm:max-w-[110px] sm:max-h-[120px] max-w-[95px] max-h-[100px] min-w-[95px] object-cover object-top rounded-[16px] border-[#dcdbdb]"
                  />
                )}
                <p className=" sm:font-semibold font-medium xl:text-xl text-sm xl:pb-4 sm:pb-[10px]pb-2">
                  {userData.userName}
                </p>
                <p
                  style={{ wordBreak: "break-word" }}
                  className="font-normal xl:text-xl text-xs xl:pb-4 sm:pb-[10px] pb-2 max-w-[262px]">
                  {userData.designation}AASA
                </p>
                <p
                  style={{ wordBreak: "break-word" }}
                  className=" font-normal xl:text-xl text-xs xl:pb-4 sm:pb-[10px] pb-2  max-w-[262px]">
                  {userData.bio}
                </p>
                <button
                  onClick={() => setAboutPopup(true)}
                  className="flex items-center about_icon">
                  <About_Icon />
                  <p className="font-normal xl:text-xl text-xs xl:ps-2 sm:ps-3 ps-[3px] sm:text-black text-primary">
                    About
                  </p>
                </button>
              </div>
              <div className="flex-col justify-end items-end max-sm:mt-16">
                <div className=" flex items-center">
                  <button onClick={showfollowerpopup} className=" inline-block xl:pr-[70px] pr-6">
                    <p className=" font-semibold xl:text-xl sm:text-sm  max-sm:text-center text-xs">
                      {userData.followersCount || 0}
                    </p>
                    <p className="sm:font-semibold font-regular text-xs xl:text-xl">Followers</p>
                  </button>
                  <button onClick={showfollowingpopup}>
                    <p className=" font-semibold xl:text-xl sm:text-sm  max-sm:text-center text-xs">
                      {userData.followingCount || 0}
                    </p>
                    <p className="sm:font-semibold font-regular text-xs xl:text-xl">Following</p>
                  </button>
                </div>
                <div className=" text-end pt-4 absolute right-[16px]">
                  {btn === "editBtn" ? (
                    <div className="flex items-center gap-3">
                      {!isBlock ? (
                        <div className="flex items-center gap-3">
                          {currentPath === "/profile" ? (
                            <button
                              onClick={() => {
                                setEditProfilePopup(true);
                              }}
                              className="xl:px-[25px] sm:px-[38px] sm:py-2 sm:py-[9px] md:py-[18px] h-[28px] sm:h-[40px] md:h-[60px] px-2 rounded-[100px] border-grey xl:text-base sm:text-sm text-xxs font-normal text-black border inline-block whitespace-nowrap">
                              Edit profile
                            </button>
                          ) : (
                            <>
                              {currentPath.startsWith("/ViewByUserprofile") && (
                                <button
                                  onClick={() => {
                                    if (checkFollowing.includes(userData.id)) {
                                      unFollowUser(userData.userName);
                                    } else {
                                      followUser(userData.userName);
                                    }
                                    followUnfollow(userData.id);
                                  }}
                                  className={`text-base max-md:text-sm h-[28px]  px-[41px] md:h-[60px] rounded-[100px] max-md:px-2 max-sm:mt-1 ${
                                    !checkFollowing.includes(userData.id)
                                      ? "bg-primary text-white"
                                      : "border border-[#BEC1C3]"
                                  }`}>
                                  {checkFollowing.includes(userData.id) ? "Following" : "Follow"}
                                </button>
                              )}
                              <button onClick={() => setShowThreeDots(true)}>
                                <ThreeDots />
                              </button>
                            </>
                          )}
                        </div>
                      ) : (
                        <button onClick={() => setUnblock(true)}>
                          <SeeAllButton name={"Unblock"} />
                        </button>
                      )}
                    </div>
                  ) : btn === "editIcon" ? (
                    <>
                      <div class="xl:block hidden">
                        <ThreeDots />
                      </div>
                      <div class="xl:hidden block">
                        <Vertical_edit_btn />
                      </div>
                    </>
                  ) : btn === "BlockButton" ? (
                    <SeeAllButton name={"Unblock"} />
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        ""
      )}
      <hr />
    </>
  );
};

export default ProfileHeader;
