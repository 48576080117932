import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Texteditor = ({ message, setContent }) => {
  const [value, setValue] = useState(message);

  const handleChange = (content, delta, source, editor) => {
    setValue(editor.getHTML());
    setContent(editor.getHTML());
  };

  return (
    <div className="texteditor-container h-[600px] sm:h-[340px] xl:h-[515px] overflow-x-hidden">
      <ReactQuill
        value={value}
        onChange={handleChange}
        theme="snow"
        modules={modules}
        formats={formats}
        placeholder="Write something..."
      />
    </div>
  );
};

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    ["link", "image"],
  ],
};

const formats = ["bold", "italic", "underline", "link", "image"];

export default Texteditor;
