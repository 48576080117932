import React, { useState } from "react";
import { BackArrowIcon, CloseIcon } from "../common/Icons";
import showErrorToast from "../ToastError";
const deactivatePopupData = [
  {
    lable: "Just need a break",
  },
  {
    lable: "Privacy concerns",
  },
  {
    lable: "Can't find people to follow/add",
  },
  {
    lable: "Created a second account",
  },
  {
    lable: "Can’t find relevant contents",
  },
  {
    lable: "Trouble getting started",
  },
  {
    lable: "Trouble saving contents in library",
  },
  {
    lable: "Concerned about my data",
  },
  {
    lable: "Too busy/too distracting",
  },
  {
    lable: "Something else",
  },
];
const DeactivatingAccountPopup = ({
  setsurepopup,
  title,
  title2,
  subHeading,
  setDeactivate,
  setFixResion,
  fixResion,
  setForget,
}) => {
  return (
    <>
      <div className="max-sm:w-full max-sm:h-screen flex justify-center items-center flex-col fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
        <div className="w-full max-sm:h-full sm:w-[523px] md:w-[640px] mx-auto bg-white pt-3 sm:pt-[42px] pb-[31px] sm:rounded-[40px] flex flex-col shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] max-md:mx-4">
          <div className=" flex justify-between items-cente max-sm:px-[22px] sm:px-9">
            <h2 className="font-medium md:font-semibold lg:font-bold text-base lg:text-lg text-black flex items-center gap-2">
              <button onClick={() => setDeactivate(false)} className="cursor-pointer pe-1">
                <BackArrowIcon />
              </button>
              {title}
              <span className="hidden sm:block">{title2}</span>
            </h2>
            <span
              className="cursor-pointe hidden sm:block"
              onClick={() => {
                setDeactivate(false);
              }}>
              <CloseIcon />
            </span>
          </div>
          <div className="mt-4 sm:mt-5 flex flex-col gap-5 sm:gap-2 flex-grow">
            <p className="text-black text-[14px] lg:text-base font-medium sm:font-normal lg:leading-6 max-sm:px-[22px] sm:px-9">
              {subHeading}
            </p>
            <div className="flex flex-col gap-5 sm:gap-2  sm:h-[300px] overflow-y-auto max-sm:px-[22px] sm:px-8 sm:mx-1 h-full">
              {deactivatePopupData.map((items, index) => (
                <div key={index} className="flex justify-between items-center lg:pe-2">
                  <label
                    className="font-normal text-[14px] lg:text-base text-black"
                    htmlFor={index + 1}>
                    {items.lable}
                  </label>
                  <div>
                    <input
                      onChange={(e) => setFixResion(e.target.value)}
                      className="w-[24px] sm:w-8 h-[24px] sm:h-8 inline-block"
                      type="radio"
                      name="break"
                      value={items.lable}
                      id={index + 1}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-center gap-4 sm:gap-[60px] lg:gap-[64px] mt-5 sm:mt-11 lg:mt-5">
            <button
              onClick={() => {
                if (fixResion !== "") {
                  setDeactivate(false);
                  if (fixResion == "Something else.") {
                    setsurepopup(true);
                  } else {
                    setForget(true);
                  }
                } else {
                  showErrorToast("Please choose one reason !");
                }
              }}
              className="font-normal text-sm sm:text-base text-black border border-[#BEC1C3]text-white  bg-white duration-500 px-6 sm:px-8 rounded-[100px] text-nowrap h-[40px] lg:h-[60px]">
              Continue
            </button>
            <button
              onClick={() => {
                setDeactivate(false);
              }}
              className="font-normal text-sm sm:text-base text-white border border-[#BEC1C3]text-white bg-primary duration-500 px-6 sm:px-8 rounded-[100px] text-nowrap h-[40px] lg:h-[60px]">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeactivatingAccountPopup;
