import React from "react";
import { CloseIcon } from "../components/common/Icons";
import showErrorToast from "../components/ToastError";
function SureDeleteAccount({
  setForget,
  data,
  setsurepopup,
  reportDiscription,
  setReportDiscription,
}) {
  return (
    <>
      <div className="max-w-[92%] md:max-w-[620px] rounded-[18px] md:rounded-[40px] w-full bg-white  text-center sm:pb-[30px] py-6 px-[24px] md:py-[40px] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
        <div className="text-end me-5 ">
          <span
            className="cursor-pointer absolute right-[20px] top-[20px] hidden sm:block"
            onClick={() => setsurepopup(false)}>
            <CloseIcon />
          </span>
        </div>
        <p className="font-semibold md:text-base  text-center sm:text-sm  text-sm">
          We'd like to know why you're {data} your account.
        </p>
        <textarea
          onChange={(e) => setReportDiscription(e.target.value)}
          type="text"
          className="rounded-lg sm:h-[103px] sm:max-w-[480px] w-full border border-1 border-[#BEC1C3] sm:rounded-2xl sm:mt-[38px] mt-[22px] h-[111px] sm:px-[20px] sm:py-[10px] px-[10px] py-[6px] outline-none resize-none max-md:text-sm"
        />
        <div className="flex justify-center gap-4 sm:gap-[60px] lg:gap-[64px] mt-5 sm:mt-11 lg:mt-5">
          <div
            onClick={() => {
              if (reportDiscription !== "") {
                setForget(true);
                setsurepopup(false);
              } else {
                showErrorToast("Please type some reason !");
              }
            }}>
            <button
              className="font-normal text-sm sm:text-base text-black border border-[#BEC1C3]text-white  bg-white
          duration-500 px-6 sm:px-8 rounded-[100px] text-nowrap h-[40px] lg:h-[60px]">
              Continue
            </button>
          </div>
          <button
            onClick={() => setsurepopup(false)}
            className="font-normal text-sm sm:text-base text-white border border-[#BEC1C3]text-white  bg-primary
          duration-500 px-6 sm:px-8 rounded-[100px] text-nowrap h-[40px] lg:h-[60px]">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default SureDeleteAccount;
