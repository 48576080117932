import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CheckConnection = () => {
  useEffect(() => {
    const handleOnline = () => {
      toast.dismiss();
      toast.success("Internet Connected", {
        autoClose: true,
      });
    };
    const handleOffline = () => {
      toast.error("No Internet Connection", {
        autoClose: false,
        toastId: "offline-toast",
      });
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      <ToastContainer />
    </>
  );
};

export default CheckConnection;
