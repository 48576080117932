import React, { useEffect, useState } from "react";
import { DeleteIcon, EditIcon } from "../common/Icons";
import dropDownIcon from "../../assets/icons/dropdown-arrow.svg";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import showErrorToast from "../ToastError";

function EducationalDetails({ setEducationData, seteducationError }) {
  const [newdata, setNewdata] = useState([]);
  const [errors, setErrors] = useState("");
  const [institutesData, setInstitutesData] = useState([]);
  const [id, setId] = useState("");
  const { searchUniversityData, searchUniversity } = UseProfileContext();
  const [dropdownVisible, setDropdownVisible] = useState({
    school: false,
    Institute: false,
    Specialisation: false,
  });
  const [userdata, setUserdata] = useState({
    school: "",
    Institute: "",
    Specialisation: "",
    Currently_studying: "",
    from_first: "",
    from_end: "",
    till_first: "",
    till_end: "",
    month: "",
    year: "",
    if_Currently_studying_Semester: "",
    if_Currently_studying_Year: "",
  });
  let token = null;
  useEffect(() => {
    let localTokenStore = JSON.parse(localStorage.getItem("tokens"));
    let sessionTokenStore = JSON.parse(sessionStorage.getItem("tokens"));
    if (localTokenStore === null) {
      token = sessionTokenStore;
    } else {
      token = localTokenStore;
    }
  });
  useEffect(() => {
    if (
      userdata.school.length !== 0 ||
      userdata.Institute.length !== 0 ||
      userdata.Specialisation.length !== 0
    ) {
      seteducationError(userdata);
    } else {
      seteducationError("");
    }
  }, [userdata.school, userdata.Institute, userdata.Specialisation]);
  useEffect(() => {
    if (userdata.school.length > 0) {
      searchUniversity(userdata.school, token);
    }
  }, [userdata.school]);
  const getColorClass = (value) => (value ? "text-black" : "text-grey");
  const onhandelchange = (e) => {
    const { name, value } = e.target;
    setUserdata({ ...userdata, [name]: value });
    setDropdownVisible({
      school: name === "school" && value.length > 0,
      Institute: name === "Institute" && value.length > 0,
      Specialisation: name === "Specialisation" && value.length > 0,
    });
  };
  useEffect(() => {
    if (userdata.school.length > 0) {
      for (let data in searchUniversityData) {
        if (userdata.school === data) {
          setInstitutesData(searchUniversityData[data]);
        }
      }
    }
  }, [userdata.school]);
  const schoolData = [...Object.keys(searchUniversityData)];
  const instituteData = [...institutesData];
  const specialisationData = ["Maths", "Physics", "English"];

  const validateForm = () => {
    if (!userdata.school) return "School is required";
    if (!userdata.Institute) return "Institute is required";
    if (!userdata.Specialisation) return "Specialisation is required";
    if (!userdata.Currently_studying) return "This field is required";
    if (!userdata.from_first) return "From year is required";
    if (!userdata.from_end) return "From end year is required";
    if (userdata.Currently_studying !== "yes") {
      if (!userdata.till_first) return "Till year is required";
      if (!userdata.till_end) return "Till end year is required";
    }
    if (userdata.Currently_studying === "yes") {
      if (!userdata.if_Currently_studying_Semester) return "Semester is required";
      if (!userdata.if_Currently_studying_Year) return "Year is required";
    }
    return "";
  };

  const onhandelsubmit = (e) => {
    const error = validateForm();
    if (error) {
      setErrors(error);
      showErrorToast("All field are required");
    } else {
      setNewdata([...newdata, userdata]);
      setEducationData([...newdata, userdata]);
      setUserdata({
        school: "",
        Institute: "",
        Specialisation: "",
        Currently_studying: "",
        from_first: "",
        from_end: "",
        till_first: "",
        till_end: "",
        if_Currently_studying_Semester: "",
        if_Currently_studying_Year: "",
      });
      setErrors("");
    }
  };

  function handleDelete(index) {
    let cloneUserData = [...newdata];
    cloneUserData.splice(index, 1);
    setNewdata(cloneUserData);
  }
  function handleEdit(index) {
    let editData = newdata.find((_, i) => i === index);
    setUserdata(editData);
  }
  function updateEducationData() {
    let updateData = newdata.with(id, userdata);
    setNewdata(updateData);
    setEducationData(updateData);
    setId("");
    setUserdata({
      school: "",
      Institute: "",
      Specialisation: "",
      Currently_studying: "",
      from_first: "",
      from_end: "",
      till_first: "",
      till_end: "",
      if_Currently_studying_Semester: "",
      if_Currently_studying_Year: "",
    });
  }
  const handleDropdownChange = (name, value) => {
    setUserdata({ ...userdata, [name]: value });
    setDropdownVisible({ ...dropdownVisible, [name]: false });
  };

  const renderDropdownOptions = (name, options) => {
    const filteredOptions = options.filter((option) =>
      option.toLowerCase().includes(userdata[name].toLowerCase())
    );

    if (filteredOptions.length === 0) {
      return <p className="text-sm py-0.5">No results found</p>;
    }

    return filteredOptions.map((option, index) => (
      <p
        key={index}
        className="text-sm py-0.5 cursor-pointer hover:bg-gray-100 rounded"
        onClick={() => handleDropdownChange(name, option)}>
        {option}
      </p>
    ));
  };
  return (
    <>
      <div className=" mt-6 xl:mt-8 h-full bg-white">
        <div className="items-center justify-between md:px-2 h-full">
          <form className="h-full">
            <div className=" flex justify-end px-4 md:px-12">
              <button
                onClick={() => {
                  id === "" ? onhandelsubmit() : updateEducationData();
                }}
                type="button"
                className=" flex items-center justify-center gap-2 px-6 max-sm:px-4 max-sm:py-[7px] py-[10px] border border-[#BEC1C3] rounded-[100px]">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.99976 4.95996C1.99976 3.57925 3.11904 2.45996 4.49976 2.45996H11.4998C12.8805 2.45996 13.9998 3.57925 13.9998 4.95996V11.96C13.9998 13.3407 12.8805 14.46 11.4998 14.46H4.49976C3.11904 14.46 1.99976 13.3407 1.99976 11.96V4.95996ZM4.49976 3.45996C3.67133 3.45996 2.99976 4.13153 2.99976 4.95996V11.96C2.99976 12.7884 3.67133 13.46 4.49976 13.46H11.4998C12.3282 13.46 12.9998 12.7884 12.9998 11.96V4.95996C12.9998 4.13153 12.3282 3.45996 11.4998 3.45996H4.49976ZM7.99976 4.95996C8.2759 4.95996 8.49976 5.18382 8.49976 5.45996V7.95996H10.9998C11.2759 7.95996 11.4998 8.18382 11.4998 8.45996C11.4998 8.7361 11.2759 8.95996 10.9998 8.95996H8.49976V11.46C8.49976 11.7361 8.2759 11.96 7.99976 11.96C7.72361 11.96 7.49976 11.7361 7.49976 11.46V8.95996H4.99976C4.72361 8.95996 4.49976 8.7361 4.49976 8.45996C4.49976 8.18382 4.72361 7.95996 4.99976 7.95996H7.49976V5.45996C7.49976 5.18382 7.72361 4.95996 7.99976 4.95996Z"
                    fill="#303135"
                  />
                </svg>
                <span className=" text-black text-base font-normal leading-6  max-sm:text-sm">
                  {id === "" ? "Add" : "Update"}
                </span>
              </button>
            </div>
            <div className="h_professional overflow-y-auto px-4 sm:px-5 md:px-12 mt-6">
              {errors && <p className="text-red-500">{errors}</p>}
              {newdata.length !== 0 ? (
                <div className="flex flex-col gap-3 max-md:mt-4 mb-4">
                  {newdata.map((value, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full max-md:rounded-[100px] bg-[#F7F7F7] py-3 px-9 rounded-xl mx-auto  overflow-auto max-sm:text-xs ">
                        <div className="flex items-center">
                          <div className="w-full">
                            <p>{value.school}</p>
                            <p>{value.Institute}</p>
                            <p>{value.Specialisation}</p>
                            <p>
                              {`01-${value.from_first <= 9 ? "0" : ""}${value.from_first}-${
                                value.from_end
                              }`}{" "}
                              {value.Currently_studying !== "yes" ? "/" : ""}{" "}
                              {value.Currently_studying !== "yes"
                                ? `30-${value.till_first <= 9 ? "0" : ""}${value.till_first}-${
                                    value.till_end
                                  }`
                                : ""}
                            </p>
                            {value.if_Currently_studying_Year !== "" && (
                              <p className="text-xs lg:text-base text-black font-normal leading-6">
                                {value.if_Currently_studying_Year} Year, Semester{" "}
                                {value.if_Currently_studying_Semester}
                              </p>
                            )}
                          </div>
                          <div className="flex gap-6 items-center">
                            <button
                              type="button"
                              className="w-4 h-4"
                              onClick={() => {
                                handleEdit(index);
                                setId(index);
                              }}>
                              <EditIcon />
                            </button>
                            <button
                              type="button"
                              className="w-4 h-4"
                              onClick={() => handleDelete(index)}>
                              <DeleteIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              <div className=" flex flex-col gap-6">
                <div>
                  <label
                    className=" text-base max-sm:text-sm font-medium text-black leading-5"
                    htmlFor="School">
                    School / University details*
                  </label>
                  <div className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer">
                    <input
                      type="text"
                      onChange={onhandelchange}
                      className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
                      name="school"
                      placeholder="University name"
                      value={userdata.school}
                      id="School"
                    />
                    <img src={dropDownIcon} alt="dropDownIcon" />
                    <img src="" alt="" />
                    {dropdownVisible.school && (
                      <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                        {renderDropdownOptions("school", schoolData)}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                    htmlFor="Institute">
                    Institute name
                  </label>
                  <div className="border border-[#BEC1C3]  mt-[6px] rounded-[100px] max-sm:text-sm relative flex items-center ps-7 pe-4 cursor-pointer">
                    <input
                      type="text"
                      onChange={onhandelchange}
                      className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
                      name="Institute"
                      id="Institute"
                      placeholder="Institute name"
                      value={userdata.Institute}
                    />
                    <img src={dropDownIcon} alt="dropDownIcon" />
                    {dropdownVisible.Institute && (
                      <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                        {renderDropdownOptions("Institute", instituteData)}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    className="text-base font-medium text-black leading-5 max-sm:text-sm"
                    htmlFor="Specialisation">
                    Specialisation*
                  </label>
                  <div className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer">
                    <input
                      type="text"
                      onChange={onhandelchange}
                      className="w-full outline-none max-sm:text-sm h-[40px]"
                      name="Specialisation"
                      id="Specialisation"
                      placeholder="Specialisation"
                      value={userdata.Specialisation}
                    />
                    <img src={dropDownIcon} alt="dropDownIcon" />
                    {dropdownVisible.Specialisation && (
                      <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                        {renderDropdownOptions("Specialisation", specialisationData)}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                    htmlFor="Currently_studying">
                    Currently studying here*
                  </label>
                  <select
                    onChange={onhandelchange}
                    className={`w-full outline-none max-sm:text-sm h-[40px] px-7 border border-[#BEC1C3]  mt-[6px] rounded-[100px] ${getColorClass(
                      userdata.Currently_studying
                    )}`}
                    name="Currently_studying"
                    id="Currently_studying"
                    value={userdata.Currently_studying}>
                    <option disabled value="">
                      Select an option
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="w-full flex gap-3">
                  <div className="w-1/2">
                    <label
                      className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                      htmlFor="From_first">
                      From*
                    </label>
                    <div className="flex gap-1">
                      <div
                        className={`flex justify-between border border-[#BEC1C3] h-[40px] rounded-3xl mt-[6px] w-full ${getColorClass(
                          userdata.from_first
                        )}`}>
                        <select
                          id="month"
                          className={`font-normal text-[14px] ps-2 md:px-3 max-sm:bg-[length:16px_16px] sm:text-base w-full outline-none ${getColorClass(
                            userdata.from_first
                          )}`}
                          value={userdata.from_first}
                          name="from_first"
                          onChange={onhandelchange}>
                          <option disabled value="">
                            MM
                          </option>
                          {[...Array(12)].map((_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        className={`flex justify-between border border-[#BEC1C3] mt-[6px] h-[40px]  rounded-3xl w-full ${getColorClass(
                          userdata.from_end
                        )}`}>
                        <select
                          id="year"
                          className={`font-normal text-[14px] max-sm:bg-[length:16px_16px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                            userdata.from_end
                          )}`}
                          value={userdata.from_end}
                          name="from_end"
                          onChange={onhandelchange}>
                          <option disabled value="">
                            YYYY
                          </option>
                          {[...Array(100)].map((_, i) => {
                            const year = new Date().getFullYear() - i;
                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {userdata.Currently_studying !== "yes" && (
                    <div className="w-1/2">
                      <label
                        className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                        htmlFor="till_first">
                        Till*
                      </label>
                      <div className=" flex gap-1">
                        <div className=" border border-[#BEC1C3] mt-[6px] h-[40px] flex items-center rounded-[100px]  w-full">
                          <select
                            id="month"
                            className={`font-normal text-[14px] max-sm:bg-[length:16px_16px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                              userdata.till_first
                            )}`}
                            name="till_first"
                            value={userdata.till_first}
                            onChange={onhandelchange}>
                            <option disabled value="">
                              MM
                            </option>
                            {[...Array(12)].map((_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" border border-[#BEC1C3]  mt-[6px] h-[40px] rounded-[100px] flex items-center w-full max-sm:text-sm">
                          <select
                            id="year"
                            className={`font-normal text-[14px] max-sm:bg-[length:16px_16px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                              userdata.till_end
                            )}`}
                            name="till_end"
                            value={userdata.till_end}
                            onChange={onhandelchange}>
                            <option disabled value="">
                              YYYY
                            </option>
                            {[...Array(100)].map((_, i) => {
                              const year = new Date().getFullYear() - i;
                              return (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {userdata.Currently_studying === "yes" && (
                  <div>
                    <label
                      className=" text-base font-medium text-black "
                      htmlFor="if_Currently_studying_Semester max-sm:text-sm">
                      If currently studying*
                    </label>
                    <div className="flex w-full gap-3">
                      <div className=" w-full sm:1/2">
                        <div className="  border border-[#BEC1C3]  mt-[6px] rounded-[100px] py-2 ps-2 pr-4">
                          <select
                            onChange={onhandelchange}
                            className={`w-full outline-none max-sm:text-sm px-2 md:px-3 ${getColorClass(
                              userdata.if_Currently_studying_Year
                            )}`}
                            name="if_Currently_studying_Year"
                            id="if_Currently_studying_Year"
                            value={userdata.if_Currently_studying_Year}>
                            <option disabled value="">
                              year
                            </option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select>
                        </div>
                      </div>{" "}
                      <div className=" w-full sm:1/2">
                        <div className="  border border-[#BEC1C3]  mt-[6px] rounded-[100px] py-2 ps-2 pr-4">
                          <select
                            onChange={onhandelchange}
                            className={`w-full outline-none max-sm:text-sm px-2 md:px-3 ${getColorClass(
                              userdata.if_Currently_studying_Semester
                            )}`}
                            name="if_Currently_studying_Semester"
                            id="if_Currently_studying_Semester"
                            value={userdata.if_Currently_studying_Semester}>
                            <option disabled value="">
                              semester
                            </option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EducationalDetails;
