import React from "react";
import error from "../assets/images/png/cuate.png";
import errorMobile from "../assets/images/png/404_error_mobile.png";
import { Link } from "react-router-dom";
const Error = () => {
  return (
    <div className="h-screen">
      <p className="text-xs sm:text-base md:text-xl text-center  mt-9">
        Whoops! Something went wrong.
      </p>
      <p className="text-xs sm:text-base md:text-xl text-center leading-7 mb-9 ">
        Please come back later.
      </p>
      <div className="flex justify-center">
        {" "}
        <Link to="/" className="text-center">
          <button
            className="text-sm lg:text-lg d-flex items-center justify-center bg-primary w-[139px] sm:w-[151px]  lg:w-[211px] py-[18px] text-white rounded-[100px]"
            type="button"
          >
            Back to home
          </button>
        </Link>
      </div>
      <div className="md:max-w-[1200px] xxl:w-[1370px] ms-auto">
        <img className="w-full h-full hidden lg:flex" src={error} alt="error" />
        <img
          className="w-[360px] h-[343px] lg:hidden mx-auto"
          src={errorMobile}
          alt="error"
        />
      </div>
      {/* <div className="min-h-[calc(100vh-174.48px)] sm:min-h-[calc(100vh-191.7px)] md:min-h-[calc(100vh-198.3px)] lg:min-h-[calc(100vh-210px)]   bg-center   md:bg-right-top  error_bg_img  bg-contain bg-no-repeat ">
         
        </div> */}
    </div>
  );
};

export default Error;
