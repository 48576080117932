import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDzYAkkkXe3mJVU9DOUwIckR6ayV0bW2Uk",
  authDomain: "wised-cb29d.firebaseapp.com",
  projectId: "wised-cb29d",
  storageBucket: "wised-cb29d.appspot.com",
  messagingSenderId: "788598197775",
  appId: "1:788598197775:web:2af6249ac52cdfc6d11d7a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
