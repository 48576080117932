import React, { useEffect, useState } from "react";
import { CrossIcon } from "../components/common/Icons";
import dropDownIcon from "../assets/icons/dropdown-arrow.svg";
import shape from "../assets/images/svg/Shape.svg";
import Btn from "../components/popup/Btn";
import { UseProfileContext } from "../components/ContextApis/ProfileContext";

function Educationapop({
  setEducationalPopup,
  addeducationalData,
  id,
  setEducationData,
  educationData,
  handleEducationEdit,
}) {
  const { addEducation, updateEducation, searchUniversityData, searchUniversity } =
    UseProfileContext();

  const [errors, setErrors] = useState("");
  const [institutesData, setInstitutesData] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState({
    school: false,
    Institute: false,
    Specialisation: false,
    Semester: false,
    Year: false,
  });
  const [userdata, setUserdata] = useState({
    school: "",
    Institute: "",
    Specialisation: "",
    Currently_studying: "",
    from_first: "",
    from_end: "",
    till_first: "",
    till_end: "",
    location: "",
    month: "",
    year: "",
    if_Currently_studying_Semester: "",
    if_Currently_studying_Year: "",
  });
  let data = {
    universityName: userdata.school,
    instituteName: userdata.Institute,
    location: userdata.location,
    type: "COLLEGE",
    specializationStream: userdata.Specialisation,
    fromDate: `01-${userdata.from_first <= 9 ? "0" : ""}${userdata.from_first}-${
      userdata.from_end
    }`,
    toDate:
      userdata.Currently_studying !== "yes"
        ? `30-${userdata.till_first <= 9 ? "0" : ""}${userdata.till_first}-${userdata.till_end}`
        : "",
    currentlyStudying: userdata.Currently_studying === "yes" ? true : false,
    currentYearClass: Number(userdata.if_Currently_studying_Year),
    currentSemester: Number(userdata.if_Currently_studying_Semester),
  };

  const filterData = educationData.filter((item, _) => item.id == id);
  useEffect(() => {
    if (id !== undefined) {
      data["id"] = filterData[0].id;
    }
  });
  useEffect(() => {
    if (id !== undefined) {
      setUserdata({
        id: filterData[0].id,
        school: filterData[0].universityName,
        Institute: filterData[0].instituteName,
        Specialisation: filterData[0].specializationStream,
        if_Currently_studying_Semester: filterData[0].currentSemester,
        if_Currently_studying_Year: filterData[0].currentYearClass,
        Currently_studying: filterData[0].currentlyStudying === false ? "no" : "yes",
        location: filterData[0].location,
        from_first: filterData[0].fromDate.split("-")[1].split("")[
          filterData[0].fromDate.split("-")[1].split("")[0] === "0" ? 1 : 0
        ],
        from_end: filterData[0].fromDate.split("-")[2],
        till_first:
          filterData[0].toDate !== null &&
          filterData[0].toDate !== "" &&
          userdata.Currently_studying !== "Yes"
            ? filterData[0].toDate.split("-")[1].split("")[
                filterData[0].toDate.split("-")[1].split("")[0] === "0" ? 1 : 0
              ]
            : "",
        till_end:
          filterData[0].toDate !== null &&
          filterData[0].toDate !== "" &&
          userdata.Currently_studying !== "Yes"
            ? filterData[0].toDate.split("-")[2]
            : "",
      });
    }
  }, [id, educationData]);

  const getColorClass = (value) => (value ? "text-black" : "text-grey");
  const onhandelchange = (e) => {
    const { name, value } = e.target;
    setUserdata({ ...userdata, [name]: value });
    setDropdownVisible({
      school: name === "school" && value.length > 0,
      Institute: name === "Institute" && value.length > 0,
      Specialisation: name === "Specialisation" && value.length > 0,
      Semester: name === "if_Currently_studying_Semester" && value.length > 0,
      Year: name === "if_Currently_studying_Year" && value.length > 0,
    });
  };
  useEffect(() => {
    if (userdata.school.length > 0) {
      for (let data in searchUniversityData) {
        if (userdata.school === data) {
          setInstitutesData(searchUniversityData[data]);
        }
      }
    }
  }, [userdata.school]);
  const schoolData = [...Object.keys(searchUniversityData)];
  const instituteData = [];
  const specialisationData = [...institutesData];

  const validateForm = () => {
    if (!userdata.school) return "School is required";
    if (!userdata.Institute) return "Institute is required";
    if (!userdata.Specialisation) return "Specialisation is required";
    if (!userdata.Currently_studying) return "This field is required";
    if (!userdata.from_first) return "From year is required";
    if (!userdata.from_end) return "From end year is required";
    if (userdata.Currently_studying !== "yes") {
      if (!userdata.till_first) return "Till year is required";
      if (!userdata.till_end) return "Till end year is required";
    }
    if (userdata.Currently_studying === "yes") {
      if (!userdata.if_Currently_studying_Semester) return "Semester is required";
      if (!userdata.if_Currently_studying_Year) return "Year is required";
    }
    return "";
  };

  // setInnerHeight(window.innerHeight);

  const onhandelsubmit = (e) => {
    e.preventDefault();
    const error = validateForm();
    if (error) {
      setErrors(error);
    } else {
      if (id === undefined) {
        addEducation(data);
        addeducationalData(data);
      } else {
        updateEducation(id, data);
        handleEducationEdit(data);
      }
      setEducationalPopup(false);
      setUserdata({
        school: "",
        Institute: "",
        Specialisation: "",
        Currently_studying: "",
        from_first: "",
        from_end: "",
        till_first: "",
        till_end: "",
        if_Currently_studying_Semester: "",
        if_Currently_studying_Year: "",
      });
      setErrors("");
    }
  };

  const handleDropdownChange = (name, value) => {
    setUserdata({ ...userdata, [name]: value });
    setDropdownVisible({ ...dropdownVisible, [name]: false });
  };

  const renderDropdownOptions = (name, options) => {
    const filteredOptions = options.filter((option) =>
      option.toLowerCase().includes(userdata[name].toLowerCase())
    );

    if (filteredOptions.length === 0) {
      return <p className="text-sm py-0.5">No results found</p>;
    }

    return filteredOptions.map((option, index) => (
      <p
        key={index}
        className="text-sm py-0.5 cursor-pointer hover:bg-gray-100 rounded"
        onClick={() => handleDropdownChange(name, option)}>
        {option}
      </p>
    ));
  };

  useEffect(() => {
    if (userdata.school.length !== 0) {
      searchUniversity(userdata.school);
    }
  }, [userdata.school]);

  return (
    <>
      <div className="bg-white py-4 md:py-[42px] w-full sm:w-[540px] overflow-hidden sm:rounded-[40px] max-sm:h-screen sm:h-auto shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
        <div className="md:px-2 flex flex-col h-full">
          <div className=" flex gap-4 sm:justify-between items-center px-4 sm:px-5 md:px-12 ">
            <img
              className="cursor-pointer"
              width={"18px"}
              src={shape}
              onClick={() => setEducationalPopup(false)}
              alt="shape"
            />
            <p className=" text-base font-semibold leading-5 text-black">
              {id === undefined ? "Add" : "Edit"} educational details
            </p>
            <div
              className="cursor-pointer hidden sm:block"
              onClick={() => setEducationalPopup(false)}>
              <CrossIcon />
            </div>
          </div>
          <form className="overflow-y-auto flex-grow" onSubmit={onhandelsubmit}>
            <div className=" overflow-y-auto px-4 sm:px-5 md:px-12 md:mt-6 mt-4 sm:h-[400px] sm:mx-1">
              {errors && <p className="text-red-500">{errors}</p>}
              <div className=" flex flex-col gap-6">
                <div>
                  <label
                    className=" text-base max-sm:text-sm font-medium text-black leading-5"
                    htmlFor="School">
                    School / University details*
                  </label>
                  <div className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer">
                    <input
                      type="text"
                      onChange={onhandelchange}
                      className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
                      name="school"
                      placeholder="University name"
                      value={userdata.school}
                      id="School"
                      autocomplete="school"
                    />
                    <img src={dropDownIcon} alt="dropDownIcon" />
                    <img src="" alt="" />
                    {dropdownVisible.school && (
                      <div className="absolute max-h-[200px] overflow-y-auto top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                        {renderDropdownOptions("school", schoolData)}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                    htmlFor="Institute">
                    Institute name
                  </label>
                  <div className="border border-[#BEC1C3]  mt-[6px] rounded-[100px] max-sm:text-sm relative flex items-center ps-7 pe-4 cursor-pointer">
                    <input
                      type="text"
                      onChange={onhandelchange}
                      className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
                      name="Institute"
                      id="Institute"
                      placeholder="Institute name"
                      value={userdata.Institute}
                    />
                    <img src={dropDownIcon} alt="dropDownIcon" />
                    {dropdownVisible.Institute && (
                      <div className="absolute max-h-[200px] overflow-y-auto top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                        {renderDropdownOptions("Institute", instituteData)}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    className="text-base font-medium text-black leading-5 max-sm:text-sm"
                    htmlFor="Specialisation">
                    Specialisation*
                  </label>
                  <div className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer">
                    <input
                      type="text"
                      onChange={onhandelchange}
                      className="w-full outline-none max-sm:text-sm h-[40px]"
                      name="Specialisation"
                      id="Specialisation"
                      placeholder="Specialisation"
                      value={userdata.Specialisation}
                    />
                    <img src={dropDownIcon} alt="dropDownIcon" />
                    {dropdownVisible.Specialisation && (
                      <div className="absolute max-h-[200px] overflow-y-auto top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                        {renderDropdownOptions("Specialisation", specialisationData)}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                    htmlFor="Currently_studying">
                    Currently studying here*
                  </label>
                  <select
                    onChange={onhandelchange}
                    className={`w-full outline-none max-sm:text-sm h-[40px] px-7 border border-[#BEC1C3]  mt-[6px] rounded-[100px] ${getColorClass(
                      userdata.Currently_studying
                    )}`}
                    name="Currently_studying"
                    id="Currently_studying"
                    value={userdata.Currently_studying}>
                    <option disabled value="">
                      Select an option
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="w-full flex gap-3">
                  <div className="w-1/2">
                    <label
                      className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                      htmlFor="From_first">
                      From*
                    </label>
                    <div className="flex gap-1">
                      <div
                        className={`flex justify-between border border-[#BEC1C3] h-[40px] rounded-3xl mt-[6px] w-full ${getColorClass(
                          userdata.from_first
                        )}`}>
                        <select
                          id="month"
                          className={`font-normal text-[14px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                            userdata.from_first
                          )}`}
                          value={userdata.from_first}
                          name="from_first"
                          onChange={onhandelchange}>
                          <option disabled value="">
                            MM
                          </option>
                          {[...Array(12)].map((_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        className={`flex justify-between border border-[#BEC1C3] mt-[6px] h-[40px]  rounded-3xl w-full ${getColorClass(
                          userdata.from_end
                        )}`}>
                        <select
                          id="year"
                          className={`font-normal text-[14px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                            userdata.from_end
                          )}`}
                          value={userdata.from_end}
                          name="from_end"
                          onChange={onhandelchange}>
                          <option disabled value="">
                            YYYY
                          </option>
                          {[...Array(100)].map((_, i) => {
                            const year = new Date().getFullYear() - i;
                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {userdata.Currently_studying !== "yes" && (
                    <div className="w-1/2">
                      <label
                        className=" text-base font-medium text-black leading-5 max-sm:text-sm"
                        htmlFor="till_first">
                        Till*
                      </label>
                      <div className=" flex gap-1">
                        <div className=" border border-[#BEC1C3] mt-[6px] h-[40px] flex items-center rounded-[100px]  w-full">
                          <select
                            id="month"
                            className={`font-normal text-[14px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                              userdata.till_first
                            )}`}
                            name="till_first"
                            value={userdata.till_first}
                            onChange={onhandelchange}>
                            <option disabled value="">
                              MM
                            </option>
                            {[...Array(12)].map((_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className=" border border-[#BEC1C3]  mt-[6px] h-[40px] rounded-[100px] flex items-center w-full max-sm:text-sm">
                          <select
                            id="year"
                            className={`font-normal text-[14px] ps-2 md:px-3 sm:text-base w-full outline-none ${getColorClass(
                              userdata.till_end
                            )}`}
                            name="till_end"
                            value={userdata.till_end}
                            onChange={onhandelchange}>
                            <option disabled value="">
                              YYYY
                            </option>
                            {[...Array(100)].map((_, i) => {
                              const year = new Date().getFullYear() - i;
                              return (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full">
                  <div className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer">
                    <input
                      type="text"
                      onChange={onhandelchange}
                      className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
                      name="location"
                      placeholder="location"
                      value={userdata.location}
                      id="location"
                      autocomplete="school"
                    />
                  </div>
                </div>
                <div>
                  {userdata.Currently_studying === "yes" && (
                    <>
                      <label
                        className="text-base max-sm:text-sm font-medium text-black leading-5"
                        htmlFor="if_Currently_studying_Semester max-sm:text-sm">
                        If currently studying*
                      </label>
                      <div className="flex w-full gap-3">
                        <div className=" w-full sm:1/2">
                          <div className="  border border-[#BEC1C3]  mt-[6px] rounded-[100px] py-2 ps-2 pr-4">
                            <select
                              onChange={onhandelchange}
                              className={`w-full outline-none max-sm:text-sm px-2 md:px-3 ${getColorClass(
                                userdata.if_Currently_studying_Year
                              )}`}
                              name="if_Currently_studying_Year"
                              id="if_Currently_studying_Year"
                              value={userdata.if_Currently_studying_Year}>
                              <option disabled value="">
                                year
                              </option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                            </select>
                          </div>
                        </div>{" "}
                        <div className=" w-full sm:1/2">
                          <div className="  border border-[#BEC1C3]  mt-[6px] rounded-[100px] py-2 ps-2 pr-4">
                            <select
                              onChange={onhandelchange}
                              className={`w-full outline-none max-sm:text-sm px-2 md:px-3 ${getColorClass(
                                userdata.if_Currently_studying_Semester
                              )}`}
                              name="if_Currently_studying_Semester"
                              id="if_Currently_studying_Semester"
                              value={userdata.if_Currently_studying_Semester}>
                              <option disabled value="">
                                semester
                              </option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>6</option>
                              <option>7</option>
                              <option>8</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className=" text-center mt-6  sm:w-full lg:w-auto">
              <button>
                <Btn btnname={"Save"} />
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Educationapop;
