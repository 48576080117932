import React, { useEffect, useState } from "react";
import { CloseIcon, SearchIcon, CheckIcon } from "../components/common/Icons";
import { UseInterestContext } from "../components/ContextApis/SettingsContext";

const EditInterest = ({ value, setshowAddInterest, homePopup, deleteInterestData }) => {
  const {
    addInterest,
    interestData,
    deteteInterest,
    searchInterestData,
    searchInterest,
    allInterest,
  } = UseInterestContext();
  const interests = [...allInterest].splice(0, 7);
  console.log("first", interests);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDropdown, setSearchDropdown] = useState(false);
  let suggestedInterests = interests.filter((item) => !interestData.includes(item));
  const [checkedAddedInterests, setCheckedAddedInterests] = useState([...interestData]);
  const [removeInterst, setRemoveInterst] = useState([]);
  const [newInterest, setnewInterest] = useState([]);
  function DeleteInterestData(unSelectInterest) {
    setRemoveInterst([...removeInterst, unSelectInterest]);
  }

  const handleToggleAddedInterest = (interest) => {
    setCheckedAddedInterests((prevChecked) =>
      prevChecked.includes(interest)
        ? prevChecked.filter((item) => item !== interest)
        : [...prevChecked, interest]
    );
    setSearchTerm("");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSave = async () => {
    let addTopics = checkedAddedInterests.filter((item) => item === !interestData.includes(item));
    const requestBody = {
      interests: [...addTopics, ...newInterest],
      otherInterestsList: [],
    };
    if (checkedAddedInterests.length !== 0) {
      addInterest(requestBody);
    }
    if (removeInterst.length !== 0) {
      deleteInterestData(removeInterst);
      for (let id of removeInterst) {
        await deteteInterest(id);
      }
    }
    if (!homePopup) {
      value(false);
    } else {
      setshowAddInterest(false);
    }
  };
  useEffect(() => {
    if (searchTerm.length >= 1) {
      searchInterestData(searchTerm);
      setSearchDropdown(true);
    } else {
      setSearchDropdown(false);
    }
  }, [searchTerm]);

  return (
    <div className="sm:max-w-[420px] fixed top-0 sm:top-[50%] left-0 sm:left-[50%] sm:translate-x-[-50%] sm:translate-y-[-50%] bg-[#ffffff] sm:rounded-[16px] py-7 sm:pb-10 w-full overflow-hidden z-[60] shadow max-sm:h-full">
      <div
        onClick={() => {
          if (!homePopup) {
            value(false);
          } else setshowAddInterest(false);
        }}
        className="absolute right-[20px] top-[15px] cursor-pointer">
        <CloseIcon />
      </div>
      <p className="text-sm sm:text-base lg:text-md font-medium text-center">ADD interests</p>
      <div className="relative  mx-[30px]">
        <div className="flex items-center justify-between bg-[#EEEFEF] rounded-[28px] mt-6 h-[40px] ps-[22px] pe-[33px]">
          <input
            className="w-full bg-transparent outline-none"
            placeholder="Search"
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <SearchIcon />
        </div>
        {searchTerm.length > 0 && searchDropdown && (
          <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown max-h-[250px] overflow-y-scroll">
            {searchInterest.length !== 0 ? (
              searchInterest
                .filter((item) => !interestData.includes(item))
                .map((item, index) => {
                  return (
                    <p
                      key={index}
                      onClick={() => {
                        handleToggleAddedInterest(item);
                        interestData.push(item);
                        newInterest.push(item);
                      }}
                      className="text-sm py-0.5 cursor-pointer hover:bg-gray-100 rounded my-2 first:mt-0 last:mb-0">
                      {item}
                    </p>
                  );
                })
            ) : (
              <p className="text-sm">No matches found for {searchTerm}</p>
            )}
          </div>
        )}
      </div>

      <div className="px-2 mt-7">
        <p className="text-base sm:text-center px-4">Suggested interests</p>
        <div className="mt-[18px] flex flex-wrap gap-3 sm:justify-center max-sm:text-xs px-4 overflow-y-auto">
          {suggestedInterests.length > 0 ? (
            suggestedInterests.map((interest) => (
              <span
                key={interest}
                className="py-[6px] px-[18px] border border-primary rounded-[5px] whitespace-nowrap flex items-center gap-1 cursor-pointer text-primary h-[38px]"
                onClick={() => {
                  handleToggleAddedInterest(interest);
                  newInterest.push(interest);
                }}>
                {checkedAddedInterests.includes(interest) && <CheckIcon />}
                {interest}
              </span>
            ))
          ) : (
            <p className="text-gray-500">No results found</p>
          )}
        </div>
      </div>
      <div className="px-2 mt-[18px]">
        <p className="sm:text-base sm:text-center px-4">Added interests</p>
        <div className="overflow-y-auto mt-[18px] px-4 sm:h-[190px]">
          <div className="flex flex-wrap gap-3 sm:justify-center max-sm:text-xs">
            {interestData.length > 0 ? (
              interestData.map((interest) => (
                <span
                  key={interest}
                  className="py-[6px] px-[18px] border border-primary rounded-[5px] whitespace-nowrap flex items-center gap-1 cursor-pointer text-primary"
                  onClick={() => {
                    handleToggleAddedInterest(interest);
                    DeleteInterestData(interest);
                  }}>
                  {checkedAddedInterests.includes(interest) && <CheckIcon />}
                  {interest}
                </span>
              ))
            ) : (
              <p className="text-gray-500">No results found</p>
            )}
          </div>
        </div>
      </div>
      <div className="text-center">
        <button
          onClick={handleSave}
          className="text-base max-lg:sm max-md:py-[11px] max-md:px-[30px] px-10 py-[18px] bg-primary rounded-[80px] text-white mt-7">
          Save
        </button>
      </div>
    </div>
  );
};

export default EditInterest;
