import React, { useState } from "react";
import shape from "../../assets/images/png/Shape.png";

import eye from "../../assets/images/svg/eye.svg";
import open_eye from "../../assets/images/svg/open-eye.svg";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import Welcome from "../Authentication/Welcome";
import { BackArrowIcon, CloseIcon } from "../common/Icons";
import { UseAuth } from "../ContextApis/AuthContext";

import Loader from "../Loader";
function Signup({ signInRef, setSignInPopup, setSignUpPopup, setVerifyOtpPopup, setEmail, email }) {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confriomPassword, setConfriomPassword] = useState("");
  const [error, setError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { loading, generateOtp } = UseAuth();

  function UserSignUp(e) {
    e.preventDefault();
    if (
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password) ||
      !password.length >= 8
    ) {
      setError("Password should contain one [A-Z],[a-z],[0-9],[@#$&*] and minimum 8 characters");
    } else if (password !== confriomPassword) {
      setError("Password and Confirm Password does not match");
    }
    if (
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password) &&
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password) &&
      password.length >= 8 &&
      password === confriomPassword
    ) {
      generateOtp(email, setVerifyOtpPopup, setSignUpPopup);
      let userDtls = {
        fullName: name,
        email: email,
        password: password,
        confirmpassword: confriomPassword,
        rememberMe: rememberMe,
      };
      sessionStorage.setItem("user_register_details", JSON.stringify(userDtls));
    }
  }

  const [showhidepassword, setShowhidepassword] = useState({
    password: false,
    conformpassword: false,
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div
          ref={signInRef}
          className="max-md:h-screen w-full md:max-w-[540px] bg-white popup_shadow md:rounded-[40px] py-7 mx-auto fixed top-0 md:top-1/2 start-0 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 z-[50]">
          <div className="flex items-center justify-between px-4 sm:px-5">
            <button
              className="cursor-pointer sm:ms-4 md:ms-0 md:mb-0"
              onClick={() => setSignUpPopup(false)}>
              <BackArrowIcon />
            </button>

            <div onClick={() => setSignUpPopup(false)} className="cursor-pointer hidden md:block">
              <CloseIcon />
            </div>
          </div>
          <div className="text-center max-w-[330px] max-sm:px-[10px] w-full mx-auto max-md:mt-5">
            <p className="text-lg font-bold text-[#000000] text-start ">Sign Up</p>
            <div className="text-center flex items-center justify-between mt-5 md:mt-2 xl:mt-5">
              <a
                href="https://www.google.com/"
                target="_blank"
                className="border border-slate-400 px-8 sm:px-10 py-[14px] rounded-3xl">
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.92008 15.892L6.09733 18.9634L3.0902 19.027C2.19151 17.3602 1.68176 15.4531 1.68176 13.4264C1.68176 11.4667 2.15837 9.61863 3.00318 7.99136H3.00383L5.68102 8.48219L6.85379 11.1433C6.60833 11.8589 6.47454 12.6271 6.47454 13.4264C6.47464 14.294 6.63178 15.1252 6.92008 15.892Z"
                    fill="#FBBB00"
                  />
                  <path
                    d="M25.1116 11.2186C25.2474 11.9335 25.3181 12.6718 25.3181 13.4264C25.3181 14.2725 25.2292 15.0978 25.0597 15.8939C24.4844 18.603 22.9811 20.9686 20.8987 22.6426L20.898 22.6419L17.526 22.4699L17.0487 19.4906C18.4305 18.6803 19.5104 17.4121 20.0793 15.8939H13.7598V11.2186H20.1715H25.1116Z"
                    fill="#518EF8"
                  />
                  <path
                    d="M20.898 22.642L20.8986 22.6427C18.8733 24.2706 16.3006 25.2446 13.4999 25.2446C8.9993 25.2446 5.08633 22.729 3.09021 19.0271L6.92008 15.892C7.91812 18.5556 10.4876 20.4518 13.4999 20.4518C14.7947 20.4518 16.0078 20.1017 17.0486 19.4907L20.898 22.642Z"
                    fill="#28B446"
                  />
                  <path
                    d="M21.0434 4.32898L17.2149 7.46339C16.1376 6.79003 14.8642 6.40104 13.4999 6.40104C10.4194 6.40104 7.80186 8.38414 6.85382 11.1433L3.00382 7.99133H3.00317C4.97007 4.19912 8.93239 1.60822 13.4999 1.60822C16.3675 1.60822 18.9967 2.62966 21.0434 4.32898Z"
                    fill="#F14336"
                  />
                </svg>
              </a>
              <a
                href="https://www.apple.com/in/"
                target="_blank"
                className="border border-slate-400 px-8 sm:px-10 py-[14px] rounded-3xl mx-2">
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.5 1.6875C6.97676 1.6875 1.6875 7.11387 1.6875 13.8006C1.6875 19.1531 5.07305 23.6883 9.76641 25.2914C9.83224 25.3057 9.89943 25.3128 9.9668 25.3125C10.4045 25.3125 10.5732 24.9908 10.5732 24.7113C10.5732 24.4213 10.5627 23.6619 10.5574 22.6494C10.1667 22.741 9.76695 22.7888 9.36563 22.7918C7.09277 22.7918 6.57598 21.0252 6.57598 21.0252C6.03809 19.6277 5.26289 19.2533 5.26289 19.2533C4.23457 18.5309 5.25762 18.5098 5.33672 18.5098H5.34199C6.52852 18.6152 7.15078 19.7648 7.15078 19.7648C7.74141 20.7984 8.53242 21.0885 9.23906 21.0885C9.70631 21.0791 10.1663 20.9713 10.5891 20.7721C10.6945 19.9916 11.0004 19.459 11.3379 19.1531C8.71699 18.8473 5.95898 17.8084 5.95898 13.1678C5.95898 11.8441 6.41777 10.7631 7.17188 9.91934C7.05059 9.61348 6.64453 8.37949 7.28789 6.71309C7.37418 6.69241 7.46288 6.68354 7.55156 6.68672C7.97871 6.68672 8.94375 6.85019 10.5363 7.95762C12.4714 7.41617 14.518 7.41617 16.4531 7.95762C18.0457 6.85019 19.0107 6.68672 19.4379 6.68672C19.5266 6.68354 19.6153 6.69241 19.7016 6.71309C20.3449 8.37949 19.9389 9.61348 19.8176 9.91934C20.5717 10.7684 21.0305 11.8494 21.0305 13.1678C21.0305 17.8189 18.2672 18.842 15.6357 19.1426C16.0576 19.517 16.4373 20.2553 16.4373 21.3838C16.4373 23.0027 16.4215 24.3105 16.4215 24.7061C16.4215 24.9908 16.585 25.3125 17.0227 25.3125C17.0935 25.3128 17.1642 25.3057 17.2336 25.2914C21.9322 23.6883 25.3125 19.1479 25.3125 13.8006C25.3125 7.11387 20.0232 1.6875 13.5 1.6875Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                className="border border-slate-400 px-8 sm:px-10 py-[14px] rounded-3xl">
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.2956 25.4265V14.4967H18.8496L19.3779 10.2174H15.2956V7.49163C15.2956 6.25677 15.6272 5.4113 17.3355 5.4113H19.5V1.59604C18.4468 1.47894 17.3882 1.4224 16.329 1.42668C13.1877 1.42668 11.0308 3.41632 11.0308 7.0689V10.2094H7.5V14.4887H11.0386V25.4265H15.2956Z"
                    fill="#4092FF"
                  />
                </svg>
              </a>
            </div>
            <div className="flex items-center justify-center mt-5 md:mt-2 xl:mt-5">
              <span className="block w-[90px] border-t-2 border-[#EEEFEF] me-3"></span>
              <p className="font-normal text-md max-md:text-[14px] text-center whitespace-nowrap text-[#303135]">
                Or continue with
              </p>
              <span className="block w-[90px] border-t-2 border-[#EEEFEF] ms-3"></span>
            </div>
            <form onSubmit={UserSignUp}>
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                placeholder="Full name"
                className="mt-5 max-w-[330px] w-full bg-[#F8F8F8] h-[56px] px-8 rounded-[100px] outline-none border border-gray-300 max-sm:text-[14px]"
              />
              <input
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                placeholder="Enter your email"
                className="mt-5 max-w-[330px] w-full bg-[#F8F8F8] h-[56px] px-8 rounded-[100px] outline-none border border-gray-300 max-sm:text-[14px]"
              />
              <div className="flex bg-[#F8F8F8] max-w-[330px] w-full mx-auto mt-5 h-[56px] xl:mt-5  ps-8 justify-between pe-5 py-3 rounded-[100px] items-center border border-[#BEC1C3]">
                <input
                  required
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError(false);
                  }}
                  value={password}
                  type={showhidepassword.password ? "text" : "password"}
                  placeholder="Enter your password"
                  className="outline-none bg-transparent w-full pe-2 max-sm:text-[14px]"
                />
                <button
                  type="button"
                  onClick={() =>
                    setShowhidepassword({
                      ...showhidepassword,
                      password: showhidepassword.password ? false : true,
                    })
                  }>
                  {!showhidepassword.password ? (
                    <img width={"21px"} src={eye} alt="eye" />
                  ) : (
                    <img width={"21px"} src={open_eye} alt="eye" />
                  )}
                </button>
              </div>
              <div className="flex bg-[#F8F8F8] max-w-[330px] w-full mx-auto mt-5 xl:mt-5 h-[56px] ps-8 justify-between pe-5 py-3 rounded-[100px] items-center border border-[#BEC1C3]">
                <input
                  required
                  onChange={(e) => {
                    setConfriomPassword(e.target.value);
                    setError(false);
                  }}
                  value={confriomPassword}
                  type={showhidepassword.conformpassword ? "text" : "password"}
                  placeholder="Confirm your password"
                  className="outline-none bg-transparent w-full pe-2 max-sm:text-[14px]"
                />
                <button
                  type="button"
                  onClick={() =>
                    setShowhidepassword({
                      ...showhidepassword,
                      conformpassword: showhidepassword.conformpassword ? false : true,
                    })
                  }>
                  {!showhidepassword.conformpassword ? (
                    <img width={"21px"} src={eye} alt="eye" />
                  ) : (
                    <img width={"21px"} src={open_eye} alt="eye" />
                  )}
                </button>
              </div>
              <div className="flex items-center justify-center my-5">
                <input
                  onChange={() => setRememberMe(!rememberMe)}
                  checked={rememberMe === true}
                  type="checkbox"
                  id="remember"
                />
                <label
                  htmlFor="remember"
                  className="text-base font-medium text-[#BEC1C3] ms-3 max-sm:text-sm">
                  Remember me
                </label>
              </div>
              {error && <p className="text-[red] max-sm:text-sm text-[14px] mb-3">{error}</p>}
              <button className="w-full" type="submit">
                <Button btnname="Sign Up" changecolor="bg-primary" />
              </button>
            </form>

            <div className="flex text-center justify-center mt-5 pb-5">
              <p className="text-base font-semibold ">Already have an account?</p>
              <button
                onClick={() => {
                  setSignUpPopup(false);
                  setSignInPopup(true);
                }}
                className="text-base font-semibold text-[#4D8AFF] ms-3 ">
                Sign in
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Signup;
