import React, { createContext, useState, useContext, useEffect } from "react";
import Loader from "../Loader";
import { path } from "../common/Api";
const HomeContext = createContext();
let token = null;
let localTokenStore = JSON.parse(localStorage.getItem("tokens"));
let sessionTokenStore = JSON.parse(sessionStorage.getItem("tokens"));
if (localTokenStore === null) {
  token = sessionTokenStore;
} else {
  token = localTokenStore;
}
export const HomeProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [Spotlightdata, setSpotlightdata] = useState([]);
  const [updatedcontents, setUpdatedcontents] = useState([]);
  const [populargenres, setPopulargenres] = useState([]);
  const [topics, setTopis] = useState("");
  const [generesCategory, setGeneresCategory] = useState([]);

  async function GetSpotlightdata(paths, filters) {
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      setTopis(paths);
      let modifyPath = "";
      let modifyfilters = [];
      if (Array.isArray(filters) && filters.length > 0) {
        for (let a of filters) {
          modifyfilters.push(a.split(" ").join("_").toLocaleUpperCase());
        }
        sessionStorage.setItem("filters", JSON.stringify(filters));
      }
      if (paths !== undefined) {
        modifyPath = paths;
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify(modifyfilters),
      };

      // Make the API call
      let url =
        filters === undefined
          ? `${path}home/spotlight?topic=${modifyPath}`
          : `${path}home/spotlight?topic=${modifyPath}&filters=${modifyfilters}`;
      const response = await fetch(url, requestOptions);
      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to get spotlight");
      } else {
        setLoading(false);
      }
      const data = await response.json();
      setSpotlightdata(data.posts);
      return data.posts;
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function GetUpdatedcontents(paths, filters) {
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      let modifyPath = "";
      let modifyfilters = [];
      if (Array.isArray(filters) && filters.length > 0) {
        for (let a of filters) {
          modifyfilters.push(a.split(" ").join("_").toLocaleUpperCase());
        }
      }
      if (paths !== undefined) {
        modifyPath = paths;
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify(modifyfilters),
      };

      // Make the API call
      let url =
        filters === undefined
          ? `${path}home/updated-contents?topic=${modifyPath}`
          : `${path}home/updated-contents?topic=${modifyPath}&filters=${modifyfilters}`;
      const response = await fetch(url, requestOptions);
      // Make the API call
      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to get updated content");
      } else {
        const data = await response.json();
        setUpdatedcontents(data.posts);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function GetPopulargenres(paths, filters) {
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      let modifyPath = "";
      let modifyfilters = [];
       if (Array.isArray(filters) && filters.length > 0) {
         for (let a of filters) {
           modifyfilters.push(a.split(" ").join("_").toLocaleUpperCase());
         }
       }
      if (paths !== undefined) {
        modifyPath = paths;
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify(modifyfilters),
      };

      // Make the API call
      let url =
        filters === undefined
          ? `${path}home/popular-genres?topic=${modifyPath}`
          : `${path}home/popular-genres?topic=${modifyPath}&filters=${modifyfilters}`;
      const response = await fetch(url, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to get popular genres");
      } else {
        const data = await response.json();
        setPopulargenres(data.posts);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function GetPopulargenresCategory() {
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}user/profile/interests/category`, requestOptions);
      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to get popular genres category");
      } else {
        const data = await response.json();
        setGeneresCategory(data.category);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    GetPopulargenresCategory();
  }, []);
  return (
    <HomeContext.Provider
      value={{
        GetSpotlightdata,
        GetUpdatedcontents,
        GetPopulargenres,
        populargenres,
        GetPopulargenresCategory,
        generesCategory,
        updatedcontents,
        Spotlightdata,
        topics,
      }}>
      {loading && <Loader />}
      {children}
    </HomeContext.Provider>
  );
};
export const UseHomeContext = () => {
  return useContext(HomeContext);
};
