import React from "react";
import { CloseIcon } from "../common/Icons";
const InfoPopup = ({ setshowsetInfoPopup }) => {
  return (
    <div className="max-w-[342px] sm:max-w-[418px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] sm: py-[22px] sm:px-[42px] pb-[11px] pt-[38px] px-4 text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
      <p className=" font-normal sm:text-base text-xs">
        This section features a range of materials specifically tailored to bridge academic learning
        with practical skills needed in various industries. Explore these resources to gain
        insights, practical knowledge, and relevant expertise crucial for success in your
        professional journey.
      </p>
      <div
        className="absolute right-[20px] top-[15px] cursor-pointer"
        onClick={() => setshowsetInfoPopup(false)}>
        <CloseIcon />
      </div>
    </div>
  );
};
export default InfoPopup;
