import React, { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
import { useParams } from "react-router-dom";

export default function ShowPdf() {
  const [fileType, setfileType] = useState("");
  const [pdfData, setPdfData] = useState([]);
  const { getUserPostById } = UseCreatePostData();
  const { id } = useParams();
  async function getpostData() {
    let data = await getUserPostById(id);
    setPdfData(data);
  }
  useEffect(() => {
    getpostData();
  }, [id]);

  useEffect(() => {
    if (Object.keys(pdfData).length !== 0 && pdfData?.awsUrl.length > 0) {
      const fetchFileFromUrl = async (url) => {
        const fileName = url.split("/").pop().split("?")[0].split("_")[0];
        setfileType(fileName.includes(".pdf") ? "pdf" : "");
      };
      fetchFileFromUrl(pdfData?.awsUrl[0]);
    }
  }, [pdfData]);

  return (
    <>
      {Object.keys(pdfData).length !== 0 && fileType === "pdf" ? (
        <div style={{ position: "relative" }}>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.min.js`}>
            <div className="w-full h-[100vh]">
              <Viewer fileUrl={pdfData?.awsUrl[0]} />
            </div>
          </Worker>
        </div>
      ) : Object.keys(pdfData).length !== 0 && fileType !== "pdf" ? (
        <div className="w-full min-h-screen flex items-center justify-center">
          <img className="w-full object-contain max-h-screen" src={pdfData?.awsUrl[0]} alt="" />
        </div>
      ) : null}
    </>
  );
}
