import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Spotlight from "../Home/Spotlight";
import SeeAllButton from "../Home/SeeAllButton";
import { CloseIcon, PlusIcon } from "../common/Icons";
import Pic from "../../assets/images/png/Esther.png";
import userImg from "../../assets/images/png/user-img2.png";
import colorsBg from "../../assets/images/png/colorsBg.png";
import dropDownIcon from "../../assets/icons/dropdown-arrow.svg";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
import DOMPurify from "dompurify";
import { UseTopCreatorsContext } from "../ContextApis/TopCreatorsAndFollow";
import { UseQuestionPaperContext } from "../ContextApis/QuestionPaper";
import { StaggeredGrid, StaggeredGridItem } from "react-staggered-grid";
import { BeatLoader } from "react-spinners";

export default function Home() {
  const [questionPaper, setQuestionPaper] = useState(null);
  const [examFilterPopup, setExamFilterPopup] = useState(false);
  const [universityFilterPopup, setUniversityFilterPopup] = useState(false);
  const [examContent, setexamContent] = useState(false);
  const [universityContent, setuniversityContent] = useState(true);
  const [checkFollowing, setCheckFollowing] = useState([]);
  const [spacData, setSpacData] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState({
    university: false,
    Semester: false,
    Specialisation: false,
    Exam: false,
  });
  const [universityAllData, setuniversityAllData] = useState({
    university: "",
    Specialisation: "",
    Semester: "",
    Exam: "",
  });

  const { searchUniversityData, searchUniversity } = UseProfileContext();
  const {
    getWritesUpsData,
    getWritesByExam,
    writesupsData,
    getNotes,
    getExamNotes,
    notesData,
    getRecommendationNewUser,
    recommendationData,
    getRecomadationByExam,
    getQuestionPaperByExam,
    getQuestionPaperByUniversity,
    checkIsFollow,
  } = UseCreatePostData();
  const {
    questionPapers,
    pdfFileName,
    getQuestionPaper,
    getQuestionPaperPDF,
    getAllNotesFromOneDrive,
    oneDriveNotesData,
  } = UseQuestionPaperContext();
  const { filterpeoplelocation, getPeoplebylocations, unFollowUser, followUser } =
    UseTopCreatorsContext();
  const navigate = useNavigate();
  function getPdf(name) {
    sessionStorage.setItem("pdfFileName", name);
    getQuestionPaperPDF(name);
  }
  const showYear = (tabIndex) => {
    setQuestionPaper(questionPaper === tabIndex ? null : tabIndex);
  };
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options).replace(",", "");
  };
  useEffect(() => {
    if (universityAllData.university) {
      for (let data in searchUniversityData) {
        if (universityAllData.university === data) {
          setSpacData(searchUniversityData[data]);
        }
      }
    }
  }, [universityAllData.university]);

  const universityData = [...Object.keys(searchUniversityData)];
  const SpecialisationData = [...spacData];
  const examData = ["Haryana police", "Delhi police", "Rajasthan police"];

  function handleUniversityData() {
    getWritesUpsData(universityAllData);
    getNotes(universityAllData);
    setUniversityFilterPopup(false);
    getRecommendationNewUser(universityAllData);
    getQuestionPaperByUniversity(universityAllData);
    getQuestionPaper(universityAllData);
    sessionStorage.setItem("universityData", JSON.stringify(universityAllData));
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  async function isFollowed() {
    let id = [];
    if (Array.isArray(filterpeoplelocation)) {
      for (let filterfollow of filterpeoplelocation) {
        let message = await checkIsFollow(filterfollow.userName);
        if (message === "following") {
          id.push(filterfollow.id);
        }
      }
      setCheckFollowing(id);
    }
  }
  useEffect(() => {
    if (universityAllData.university.length > 0) {
      searchUniversity(universityAllData.university);
    }
  }, [universityAllData.university]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (universityFilterPopup || examFilterPopup) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
  const onhandelchange = (e) => {
    const { name, value } = e.target;
    setuniversityAllData({ ...universityAllData, [name]: value });
    setDropdownVisible({
      university: name === "university" && value.length > 0,
      Specialisation: name === "Specialisation" && value.length > 0,
      Exam: name === "Exam" && value.length > 0,
    });
  };

  const handleDropdownChange = (name, value) => {
    setuniversityAllData({ ...universityAllData, [name]: value });
    setDropdownVisible({ ...dropdownVisible, [name]: false });
  };

  const renderDropdownOptions = (name, options) => {
    return options
      .filter((option) => option.toLowerCase().includes(universityAllData[name].toLowerCase()))
      .map((option, index) => (
        <p
          key={index}
          className="text-sm py-0.5 cursor-pointer hover:bg-gray-100 rounded"
          onClick={() => handleDropdownChange(name, option)}>
          {option}
        </p>
      ));
  };
  const popupRef = useRef();

  function handleClickOutside(event) {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setExamFilterPopup(false);
      setUniversityFilterPopup(false);
    }
  }

  useEffect(() => {
    getPeoplebylocations();
    if (filterpeoplelocation?.length !== 0) {
      isFollowed();
    }
  }, [filterpeoplelocation?.length > 0]);

  function followUnfollow(id) {
    if (checkFollowing.includes(id)) {
      let ids = checkFollowing.filter((item) => item !== id);
      setCheckFollowing(ids);
    } else {
      setCheckFollowing([...checkFollowing, id]);
    }
  }

  function extractDateFromFilename(filename) {
    // Remove the file extension
    const nameWithoutExtension = filename.split(".").slice(0, -1).join(".");

    // Find the year (last 4 digits) and month (substring after the year)
    const yearMatch = nameWithoutExtension.match(/(\d{4})/g);
    const monthMatch = nameWithoutExtension.match(/([a-zA-Z]+)$/);

    // Extract the year and month
    const year = yearMatch && yearMatch.length > 1 ? yearMatch[1] : null; // Second year found
    const month = monthMatch ? monthMatch[0] : null;

    if (year && month) {
      // Capitalize the first letter of the month
      const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
      return `${formattedMonth} ${year}`;
    } else {
      return null;
    }
  }

  function storeExamName(examData) {
    let exam = { Exam: examData.Exam };
    sessionStorage.setItem("exam", JSON.stringify(exam));
  }

  return (
    <div>
      {(examFilterPopup || universityFilterPopup) && (
        <div
          onClick={handleClickOutside}
          className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
      )}
      {examFilterPopup && (
        <div
          ref={popupRef}
          className="max-sm:max-w-[312px] max-w-[376px] w-full bg-white shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] pt-[34px] pb-[25px] px-[15px] rounded-[16px] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[50] flex flex-col items-start">
          <div onClick={() => setExamFilterPopup(false)} className="absolute top-3 right-[14px]">
            <CloseIcon />
          </div>
          <label className="max-md:text-sm max-sm:text-xs" htmlFor="">
            Exam name*
          </label>
          <div className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer w-full">
            <input
              type="text"
              onChange={onhandelchange}
              className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
              name="Exam"
              placeholder="Exam name"
              value={universityAllData.Exam}
              id="School"
            />
            <img src={dropDownIcon} alt="dropDownIcon" />
            <img src="" alt="" />
            {dropdownVisible.Exam && (
              <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                {renderDropdownOptions("Exam", examData)}
              </div>
            )}
          </div>
          <div className="max-sm:text-start max-md:text-center w-full">
            <button
              onClick={() => {
                getExamNotes(universityAllData);
                getWritesByExam(universityAllData);
                getQuestionPaperByExam(universityAllData);
                getRecomadationByExam(universityAllData);
                storeExamName(universityAllData);
                setExamFilterPopup(false);
              }}
              className="px-4 text-xxs sm:text-sm h-[28px] sm:h-[36px]  border border-[#BEC1C3] rounded-[100px] mt-5">
              Save
            </button>
          </div>
        </div>
      )}
      {universityFilterPopup && (
        <div
          ref={popupRef}
          className=" max-sm:max-w-[312px] max-w-[376px] w-full bg-white  pt-[34px] pb-[25px] px-[15px] rounded-[16px] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[50] flex flex-col items-start shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
          <div
            onClick={() => setUniversityFilterPopup(false)}
            className="absolute top-3 right-[14px]">
            <CloseIcon />
          </div>
          <label className="max-md:text-sm max-sm:text-xs" htmlFor="">
            University name/Institute name*
          </label>
          <div className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer w-full">
            <input
              type="text"
              onChange={onhandelchange}
              className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
              name="university"
              placeholder="University name"
              value={universityAllData.university}
              id="School"
            />
            <img src={dropDownIcon} alt="dropDownIcon" />
            <img src="" alt="" />
            {dropdownVisible.university && (
              <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                {renderDropdownOptions("university", universityData)}
              </div>
            )}
          </div>
          <label className="mt-4 max-sm:text-xs max-md:text-sm" htmlFor="">
            Specialisation*
          </label>
          <div className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer w-full">
            <input
              type="text"
              onChange={onhandelchange}
              className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
              name="Specialisation"
              placeholder="specialisation name"
              value={universityAllData.Specialisation}
              id="School"
            />
            <img src={dropDownIcon} alt="dropDownIcon" />
            <img src="" alt="" />
            {dropdownVisible.Specialisation && (
              <div className="absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                {renderDropdownOptions("Specialisation", SpecialisationData)}
              </div>
            )}
          </div>
          <label className="mt-4 max-sm:text-xs max-md:text-sm" htmlFor="">
            Semester*
          </label>
          <select
            onChange={onhandelchange}
            className="border border-[#BEC1C3] mt-[6px] rounded-[100px] outline-none  h-[40px] relative flex items-center ps-6 pe-4 cursor-pointer w-[110px]"
            name="Semester"
            value={universityAllData.Semester}
            id="">
            <option disabled value="">
              Sem
            </option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8 </option>
          </select>
          <div className="max-sm:text-start max-md:text-center w-full">
            <button
              onClick={handleUniversityData}
              className="px-4 text-xxs sm:text-sm h-[28px] sm:h-[36px]  border border-[#BEC1C3] rounded-[100px] mt-5">
              Save
            </button>
          </div>
        </div>
      )}
      <div className="max-sm:max-w-[294px] max-md:max-w-[370px] max-w-[480px] bg-[#EEEFEF] rounded-[100px] p-1 sm:p-2 flex text-center mx-auto text-[20px] max-md:text-base max-sm:font-semibold max-sm:text-xs max-sm:mt-3 max-sm:mb-4 my-10">
        <button
          onClick={() => {
            setUniversityFilterPopup(true);
            setexamContent(false);
            setuniversityContent(true);
          }}
          className={`h-[25px] sm:h-[32px] lg:h-[40px] w-full rounded-[100px]  ${
            universityContent ? "bg-primary text-white" : "text-black"
          }`}>
          University
        </button>
        <button
          onClick={() => {
            setExamFilterPopup(true);
            setexamContent(true);
            setuniversityContent(false);
          }}
          className={`h-[25px] sm:h-[32px] lg:h-[40px] w-full rounded-[100px]  ${
            examContent ? "bg-primary text-white" : "text-black"
          }`}>
          Exams
        </button>
      </div>
      {Array.isArray(recommendationData) && recommendationData.length > 0 && (
        <div>
          <p className="text-center sm:mt-10 text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold">
            Recommendations
          </p>
          <section className="mt-4 sm:mt-10 px-6 max-sm:px-4">
            <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 gap-y-7 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 max-sm:gap-y-4">
              {recommendationData.slice(0, 9).map((item, index) => {
                return (
                  <div key={index}>
                    <Link to={`/home/PostAsViewByUser/${"id"}`}>
                      <Spotlight data={item} />
                    </Link>
                  </div>
                );
              })}
            </div>
            <NavLink to="/streamrecommondations">
              <div className=" text-center lg:mb-10 sm:mb-12 mb-4">
                <SeeAllButton name={"See All"} />
              </div>
            </NavLink>
            <hr />
          </section>
        </div>
      )}
      {Array.isArray(notesData) && notesData.length > 0 && (
        <section className="mt-4 sm:mt-10 px-6 max-sm:px-4">
          <p className="text-center mb-4 sm:mb-10 text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold">
            Notes
          </p>
          <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-y-7 gap-x-4 lg:mb-10 sm:mb-12 mb-4 max-sm:gap-y-4">
            {notesData.slice(0, 9).map((item, index) => {
              return (
                <div key={index}>
                  <Link
                    target={item.url ? "_blank" : ""}
                    to={item.user ? `/home/PostAsViewByUser/${item.id}` : `/showpdf2/${item.id}`}>
                    <Spotlight data={item} />
                  </Link>
                </div>
              );
            })}
          </div>
          <NavLink to="/streamnotespage">
            <div className=" text-center lg:mb-10 sm:mb-12 mb-4">
              <SeeAllButton name={"See All"} />
            </div>
          </NavLink>
          <hr />
        </section>
      )}
      {Array.isArray(questionPapers) && questionPapers.length > 0 && (
        <section className="px-6 max-sm:px-4">
          <div className="container mx-auto mt-4 sm:my-10 lg:mb-10 sm:mb-12 mb-4">
            <p className="text-center mb-4 sm:mb-10 text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold">
              Question papers
            </p>
            <StaggeredGrid
              // columnWidth={} // width of each column
              columns={windowWidth >= 1536 ? 3 : windowWidth > 640 ? 2 : 1} // 0 would adjust columns
              alignment={1} // 0 : start , 1 : center , 2 : end
              horizontalGap={
                windowWidth > 1024 ? 80 : windowWidth > 768 ? 50 : windowWidth > 624 ? 40 : 20
              }
              verticalGap={windowWidth > 768 ? 40 : 20} // horizontal gap between grid items
              fitHorizontalGap={true}>
              {questionPapers.slice(0, 6).map((item, index) => {
                return (
                  <StaggeredGridItem key={index} index={index}>
                    <div className="flex items-center justify-between flex-col ">
                      <div
                        onClick={() => {
                          showYear(index);
                          getPdf(item.name);
                        }}
                        style={{ boxShadow: "0px 8px 20px 0px #2F327D1A" }}
                        className="flex items-center justify-between w-full px-6 md:px-[30px] xl:px-[40px] xxl:px-[54px] max-md:rounded-[6px] rounded-[16px] h-[80px] md:h-[100px] lg:h-[160px]">
                        <div className="overflow-hidden">
                          <p className="text-xl max-xl:text-[20px] max-lg:text-[18px] max-md:text-[14px] lg:font-medium question_paper_name relative">
                            {item.name}
                          </p>
                        </div>
                        <button className="add_qus_btn ms-2">
                          <PlusIcon />
                        </button>
                      </div>
                      {questionPaper === index && (
                        <div
                          style={{ boxShadow: "0px 8px 20px 0px #2F327D1A" }}
                          className="flex flex-col gap-[42px] max-md:gap-[20px] max-md:py-[20px] py-[20px] justify-center items-center w-full max-md:rounded-[6px] rounded-[16px] md:mt-[30px] mt-[18px] px-2">
                          {pdfFileName.length > 0 ? (
                            pdfFileName.map((items, i) => {
                              return (
                                <div className="overflow-hidden bg-[#EBF2FF] rounded-[16px] ">
                                  <Link
                                    target="_blank"
                                    key={i}
                                    to={`/showpdf2/${items.id}`}
                                    className="whitespace-nowrap text-[16px] max-md:text-sm max-sm:text-xs px-4 py-3 max-md:p-2 cursor-pointer relative inline-block">
                                    {items.name.split("$$")[1].split(".")[0].split("_")[0]}{" "}
                                    {items.name.split("$$")[1].split(".")[0].split("_")[1]}
                                  </Link>
                                </div>
                              );
                            })
                          ) : (
                            <div className="flex items-center justify-center">
                              <BeatLoader
                                color="#4d8aff"
                                height={80}
                                width={15}
                                margin={2}
                                radius={10}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </StaggeredGridItem>
                );
              })}
            </StaggeredGrid>
          </div>
          <div className="text-center">
            <button
              onClick={() => navigate("/questionpaper")}
              className="text-center lg:mb-10 sm:mb-12 mb-4 ">
              <SeeAllButton name={"See All"} />
            </button>
          </div>
          <hr />
        </section>
      )}
      {Array.isArray(filterpeoplelocation) && filterpeoplelocation.length > 0 && (
        <section className="px-6 max-sm:px-4">
          <div className="container m-auto lg:mb-10 sm:mb-12 mb-4">
            <p className="text-center my-5 sm:mt-10 text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold">
              Recommended people
            </p>
            <div className="flex flex-wrap gap-x-[84px] gap-y-7 max-sm:gap-y-4 max-xl:gap-x-[65px] max-md:gap-x-[45px] max-sm:gap-x-[15px] sm:mt-10">
              {Array.isArray(filterpeoplelocation) &&
                filterpeoplelocation.length > 0 &&
                filterpeoplelocation.slice(0, 9).map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{ boxShadow: "0px 20.77px 51.93px 0px #2F327D1A" }}
                      className="people_card bg-[#ffffff] rounded-[4px] md:rounded-[16px] overflow-hidden">
                      <Link to={`/ViewByUserprofile/${item.userName}`}>
                        <div className="relative rounded-[16px]">
                          <img
                            className="w-full max-sm:h-[156px] max-md:h-[165px] [400px]"
                            src={colorsBg}
                            alt="colorsBg"
                          />
                          <img
                            className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-md:w-[125px] max-md:h-[125px] max-lg:w-[160px] max-lg:h-[160px] max-xl:w-[200px] max-xl:h-[200px] max-2xl:w-[240px] max-2xl:h-[240px] max-sm:p-3 rounded-full object-cover object-top w-[324px] h-[324px]"
                            src={item.profileImageUrl || userImg}
                            alt="peoples"
                          />
                        </div>
                      </Link>
                      <div className="flex flex-col items-center justify-between pt-[21px] px-[12px] pb-[24px] text-center max-sm:px-4 max-md:px-7 max-md:pt-2 max-sm:py-2 max-md:pb-[10px]">
                        <div>
                          <p className="text-xl max-md:text-base max-sm:text-sm font-medium lg:font-semibold">
                            {item.fullName}
                          </p>
                          <p className="text-lg max-sm:text-xxs max-md:text-xs py-5 max-md:py-[6px] max-sm:p-1 max-lg:py-3">
                            {item.designation || "N/A"}
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            if (checkFollowing.includes(item.id)) {
                              unFollowUser(item.userName);
                            } else {
                              followUser(item.userName);
                            }
                            followUnfollow(item.id);
                          }}
                          className={`text-base max-md:text-sm h-[28px]  px-[41px] md:h-[60px] rounded-[100px] max-md:px-2 max-sm:mt-1 ${
                            !checkFollowing.includes(item.id)
                              ? "bg-primary text-white"
                              : "border border-[#BEC1C3]"
                          }`}>
                          {checkFollowing.includes(item.id) ? "Following" : "Follow"}
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <NavLink to="/streamrecommendedpeople">
            <div className=" text-center lg:mb-10 sm:mb-12 mb-4">
              <SeeAllButton name={"See All"} />
            </div>
          </NavLink>
        </section>
      )}
      <hr />

      {Array.isArray(writesupsData) && writesupsData.length > 0 && (
        <section className="px-6 max-sm:px-4">
          <div className="container m-auto lg:mb-10 sm:mb-12 mb-4">
            <p className="text-center my-5 sm:mt-10 text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold">
              Write-Ups
            </p>
            <div className="flex items-center justify-between sm:mt-10 gap-[40px] max-md:gap-12 max-sm:gap-4 flex-wrap">
              {Array.isArray(writesupsData) &&
                writesupsData.length !== 0 &&
                writesupsData.splice(0, 4).map((items, index) => {
                  const sanitizedHtml = DOMPurify.sanitize(items.description);
                  return (
                    <Link
                      to={`/home/PostAsViewByUser/${items.id}`}
                      key={index}
                      className="border broder-[#BEC1C3] p-10 max-lg:p-7 max-md:p-4 max-sm:py-[13px] max-sm:px-[23px] write_ups_card rounded-[6px]">
                      <span className="text-base max-md:text-xs font-normal leading-6  bg-[#F8F8F8] min-w-[200px] text-center py-1 rounded-[10px] px-6">
                        # Tips and tricks
                      </span>
                      <div
                        className="text-base max-md:text-xs mt-[10px]"
                        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                      />
                      <div className="flex items-center mt-[15px]">
                        <img
                          className="min-w-[36px] max-w-[36px] h-[36px] rounded-[50%]"
                          src={items.user ? items.user.profileImageUrl : Pic}
                          alt=""
                        />
                        <div className="ms-[10px]">
                          <p className="text-base max-md:text-xs font-semibold lg:font-normal">
                            {items.user.fullName}
                          </p>
                          <p className="text-base max-md:text-xxs font-normal text-[#BEC1C3]">
                            {formatDate(items.updatedAt)}
                          </p>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
          <NavLink to="/streamwriteups">
            <div className=" text-center lg:mb-10 sm:mb-12 mb-4">
              <SeeAllButton name={"See All"} />
            </div>
          </NavLink>
        </section>
      )}
    </div>
  );
}
