import React from "react";
import { BeatLoader } from "react-spinners";

export default function Loader() {
  return (
    <div className="fixed top-0 left-0 z-[100000000] bg-white w-full h-screen flex justify-center items-center">
      <BeatLoader color="#4d8aff" height={80} width={15} margin={2} radius={10} />
    </div>
  );
}
