import React, { useEffect, useRef, useState } from "react";
import { data } from "../common/Helper";
import shape from "../../assets/images/svg/Shape.svg";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { BackArrowIcon, CloseIcon } from "../common/Icons";
import ForgotPassPopup from "../popup/ForgotPassPopup";
import NewPasswordPopup from "../popup/NewPasswordPopup";
import EmailOtp from "../popup/EmailOtp";
import { UseInterestContext } from "../ContextApis/SettingsContext";
import showToast from "../ToastSucess";
import showErrorToast from "../ToastError";
import { toast } from "react-toastify";
import bansal_sndp from "../../assets/images/jpeg/dummyimage.jpg";
import { UseProfileContext } from "../ContextApis/ProfileContext";
function PrivacyandSecurity() {
  const { resetPassword, blockeddata, getblockedaccounts } =
    UseInterestContext();
  const { unblockUser } = UseProfileContext();
  const location = useLocation();
  const [id, setId] = useState(false);
  const [unblock, setUnblock] = useState(false);
  const popupRef = useRef();
  const [ids, setIds] = useState([]);
  const [searchdata, setSearchdata] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [retypeNewPassword, setRetypeNewPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();
  const [existingPassword, setExistingPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [newgenratePassword, setNewGenratePassword] = useState("");
  const strength = getPasswordStrength(newgenratePassword);

  // Translate strength to a range value (0 to 100 for the slider)
  const strengthPercentage = (strength / 4) * 100;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await resetPassword(
        existingPassword,
        newgenratePassword,
        newPasswordConfirm
      );
      setExistingPassword("");
      setNewGenratePassword("");
      setNewPasswordConfirm("");
    } catch (error) {
      console.log("Failed to reset password: " + error.message);
    }
  };
  function getPasswordStrength(password) {
    let strength = 0;
    if (password.length > 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[@$!%*?&#]/.test(password)) strength += 1;
    return strength;
  }
  const filterdata = data.filter((items) =>
    items.name.toLowerCase().includes(searchdata.toLowerCase())
  );
  useEffect(() => {
    getblockedaccounts();
  }, []);
  function followhandle(id) {
    setIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item != id) : [...prev, id]
    );
  }
  const handleUnblockUser = async () => {
    let user = blockeddata[id].userName;
    if (blockeddata && user) {
      await unblockUser(user);
      toast.success("user sucessfully unblocked");
      setUnblock(false);
      getblockedaccounts();
    }
  };
  const isPrivacyPath =
    location.pathname === "/settings/privacy-security/privacy-term";
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setUnblock(false);
        setForgotPassword(false);
        setNewPassword(false);
        setVerifyOtp(false);
      }
    }
    if (unblock) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [forgotPassword, newPassword, verifyOtp, unblock]);
  return (
    <div className=" pb-14">
      {(forgotPassword || newPassword || verifyOtp || unblock) && (
        <div className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
      )}
      {forgotPassword && (
        <ForgotPassPopup
          setForgotPassword={setForgotPassword}
          setVerifyOtp={setVerifyOtp}
        />
      )}
      {verifyOtp && (
        <EmailOtp
          setVerifyOtp={setVerifyOtp}
          path={false}
          setNewPassword={setNewPassword}
        />
      )}
      {newPassword && <NewPasswordPopup setNewPassword={setNewPassword} />}
      {!isPrivacyPath && (
        <>
          <div className=" flex gap-4 pt-4 sm:hidden px-4">
            <button onClick={() => navigate("/settings")}>
              <BackArrowIcon />
            </button>
            <span className=" font-medium text-black text-base leading-5">
              Privacy and Security
            </span>
          </div>
          <div className=" bg-[#F8F8F8] py-1 lg:py-6 px-8 mb-5 lg:mb-[30px] text-center lg:text-start mt-4 sm:mt-0">
            <h3 className="text-base lg:text-xl text-center lg:text-start font-normal text-black leading-7">
              Change password
            </h3>
          </div>
          <div className=" max-w-[1336px] mx-auto px-4 sm:px-3 lg:px-12">
            <p className="text-sm lg:text-base text-black font-normal lg:leading-6">
              You’ll be logged out of all sessions except this one to protect
              your account if anyone is trying to gain access. Your password
              must be at least 6 characters and should include a combination of
              numbers, letters and special characters (!$@%).
            </p>
            <div className=" mt-4 sm:mt-5 lg:mt-[30px]">
              <div className=" flex lg:items-center flex-col lg:flex-row gap-[46px] sm:px-2 lg:px-0">
                <form
                  onSubmit={handleSubmit}
                  className="  lg:w-[600px] flex flex-col gap-5 lg:gap-[30px]"
                >
                  <input
                    id="name"
                    type="text"
                    placeholder="Current password"
                    value={existingPassword}
                    onChange={(e) => setExistingPassword(e.target.value)}
                    required
                    className="placeholder:text-[#BEC1C3] w-full placeholder:font-normal placeholder:text-sm sm:placeholder:text-base placeholder:leading-6 border border-[#BEC1C3] px-5 py-3 outline-none h-[40px] lg:h-[47px] rounded-[100px]"
                  />
                  <div className=" flex xl:items-center gap-5 2xl:gap-[46px] 2xl:flex-row flex-col flex-wrap xl:flex-nowrap">
                    <div className="w-full">
                      <input
                        id="name"
                        required
                        type="text"
                        placeholder="New password"
                        value={newgenratePassword}
                        onChange={(e) => setNewGenratePassword(e.target.value)}
                        className="placeholder:text-[#BEC1C3] lg:w-[600px] w-full placeholder:font-normal placeholder:text-sm sm:placeholder:text-base placeholder:leading-6 border border-[#BEC1C3] px-5 py-3 outline-none h-[40px] lg:h-[47px] rounded-[100px]"
                      />
                    </div>
                    <div className="max-2xl:w-full">
                      <div className="flex flex-col gap-7 max-2xl:w-full">
                        <label className="text-[#BEC1C3] lg:font-semibold lg:text-base font-normal sm:text-sm text-xs">
                          Strength
                        </label>
                        <input
                          type="range"
                          
                          min="0"
                          max="100"
                          value={strengthPercentage}
                          className="w-full 2xl:w-[150px] bg-[#71D9A6] accent-[#71D9A6]"
                          readOnly
                        />
                        {/* <label className="text-[#BEC1C3] lg:font-semibold lg:text-base font-normal sm:text-sm text-xs">
                          {strengthPercentage === 0
                            ? 'Weak'
                            : strengthPercentage <= 50
                              ? 'Moderate'
                              : 'Strong'}
                        </label> */}
                      </div>
                    </div>
                    {/* <div className=" w-full">
                      <input
                        id="name"
                        required
                        type="text"
                        placeholder="New password"
                        value={newgenratePassword}
                        onChange={(e) => setNewGenratePassword(e.target.value)}
                        className="placeholder:text-[#BEC1C3] lg:w-[600px] w-full placeholder:font-normal placeholder:text-sm sm:placeholder:text-base placeholder:leading-6 border border-[#BEC1C3] px-5 py-3 outline-none h-[40px] lg:h-[47px] rounded-[100px]"
                      />
                    </div> */}
                    {/* <div className="max-2xl:w-full">
                      <div className="flex items-center gap-7 max-2xl:w-full">
                        <input
                          type="range"
                          className="w-full 2xl:w-[150px] bg-[#71D9A6] accent-[#71D9A6]"
                        />
                        <label className="text-[#BEC1C3] lg:font-semibold lg:text-base font-normal sm:text-sm text-xs">
                          Strength
                        </label>
                      </div>
                    </div> */}
                  </div>
                  <input
                    id="name"
                    required
                    type="text"
                    placeholder="Re-type new password"
                    value={newPasswordConfirm}
                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                    className="placeholder:text-[#BEC1C3] w-full placeholder:font-normal placeholder:text-sm sm:placeholder:text-base placeholder:leading-6 border border-[#BEC1C3] px-5 py-3 outline-none h-[40px] lg:h-[47px] rounded-[100px]"
                  />
                  <div className=" lg:my-[30px] my-4 sm:my-5 text-center">
                    <button
                      type="submit"
                      className=" text-white bg-[#4D8AFF] rounded-[100px] font-medium lg:text-lg leading-6 py-[10px] px-[30px] lg:py-[18px] lg:px-[40px] text-[14px]"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
              {/* <button
                onClick={() => setForgotPassword(true)}
                className="text-sm font-normal lg:text-base text-center lg:text-start lg:font-semibold text-black leading-5 mt-4 sm:mt-5 lg:mt-[30px]"
              >
                Forgot password ?
              </button> */}
            </div>
          </div>

          <div className=" bg-[#F8F8F8] py-1 lg:py-6 px-8 mb-[30px] text-center lg:text-start">
            <h3 className="text-base lg:text-xl text-center lg:text-start font-normal text-black leading-7">
              Blocked accounts
            </h3>
          </div>
          {/* //////////////////////        */}
          <div className=" max-w-[1274px] mx-auto px-4 sm:px-12">
            {blockeddata.length === 0 ? (
              <p className="text-center text-gray-500 mt-4 text-lg">
                No blocked accounts
              </p>
            ) : (
              blockeddata.map((item, index) => (
                <div
                  className="flex items-center justify-between sm:mt-50 mt-[18px] md:mt-10"
                  key={item.name}
                >
                  <div className="flex items-center gap-4">
                    <div>
                      <img
                        className="h-[42px] min-w-[42px] rounded-full max-w-[42px] lg:h-[50px] lg:min-w-[50px] lg:max-w-[50px]"
                        src={item.profileImageUrl || bansal_sndp}
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="font-semibold lg:text-lg text-base">
                        {item.fullName}
                      </p>
                      <p className="font-normal text-sm lg:text-md">
                        {item.designation}
                      </p>
                    </div>
                  </div>
                  <button
                    className="border md:px-[30px] md:py-2 lg:px-[44px] lg:py-[18px] p-2 rounded-full px-[16px] py-[8px] text-sm lg:text-base 
                     
                      text-[black] bg-[white]"
                    onClick={() => {
                      setUnblock(true);
                      setId(index);
                    }}
                  >
                    Unblock
                  </button>
                </div>
              ))
            )}
          </div>
          {/* //////////////////////        */}
          <div className=" bg-[#F8F8F8] py-1 lg:py-6 px-8 mt-7 mb-4 sm:my-5 lg:my-[30px] text-center lg:text-start">
            <h3 className="text-base lg:text-xl text-center lg:text-start font-normal text-black leading-7">
              Privacy terms and account control
            </h3>
          </div>
          <p className="text-sm lg:text-base text-black font-normal lg:leading-6 max-w-[1274px] mx-auto lg:px-12 px-3">
            The Privacy Policy explains how your personal information is
            collected, used, and disclosed by Wised. You can deactivate/delete
            your account(s) from here.
          </p>
          <Link to="/settings/privacy-security/privacy-terms">
            <div className=" my-6 lg:my-[30px] text-center">
              <button className=" text-white bg-[#4D8AFF] rounded-[100px] font-medium lg:text-lg leading-6 py-[10px] px-[30px] lg:py-[18px] lg:px-[40px] text-[14px]">
                See more
              </button>
            </div>
          </Link>
        </>
      )}
      {unblock && (
        <div
          ref={popupRef}
          className="max-w-[320px] sm:max-w-[444px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] xl:py-[59px] xl:px-10 sm:py-[53px] sm:px-[75px] py-[21px] px-[34px] flex-col shadow"
        >
          <p className=" font-medium sm:text-base text-sm text-center ">
            Unblock {blockeddata.userName}?
          </p>
          <p className="xl:text-base font-normal text-sm sm:my-[30px] my-17 text-center">
            They will now be able to see your profile and posts on Wised. We
            won't let them know that you've unblocked them.
          </p>
          <div className="flex justify-center">
            <button className="inline-block me-[25px]">
              <div className="h-[40px]  justify-center items-center bg-primary flex rounded-[100px] ">
                <button
                  onClick={handleUnblockUser}
                  className=" text-white font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-primary rounded-[100px] mx-auto sm:px-7 px-6"
                >
                  Unblock
                </button>
              </div>
            </button>
            <button className="inline-block">
              <div className="h-[40px]  justify-center items-center bg-white flex rounded-[100px] ">
                <button
                  className=" text-black font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-white rounded-[100px] mx-auto sm:px-7 px-6 border-grey border"
                  onClick={() => setUnblock(false)}
                >
                  Cancel
                </button>
              </div>
            </button>
          </div>
          <button
            className="absolute right-[20px] top-[15px] cursor-pointer"
            onClick={() => setUnblock(false)}
          >
            <CloseIcon />
          </button>
        </div>
      )}
      <Outlet />
    </div>
  );
}

export default PrivacyandSecurity;
