import React, { useEffect, useMemo } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { UsePDFContext } from "../ContextApis/PDF";
import { useParams } from "react-router-dom";

const PdfViewer = () => {
  const { pdf, fetchPdf } = UsePDFContext();
  const { id } = useParams();
  useEffect(() => {
    fetchPdf(id);
  }, []);
  return (
    <div style={{ height: "600px", width: "100%" }}>
      {pdf ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.min.js">
          <div className="w-full h-[100vh]">
            <Viewer fileUrl={URL.createObjectURL(pdf)} />
          </div>
        </Worker>
      ) : (
        <div className="h-screen flex items-center justify-center">
          <p className="text-xl font-semibold">Loading PDF...</p>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
