import React, { useState } from "react";
import deleteGif from "../assets/images/png/delete.gif";
import DeleteSucessfully from "./DeleteSucessfully";
import { Link } from "react-router-dom";
import { UseProfileContext } from "../components/ContextApis/ProfileContext";
function DeleteAccountpop({
  content,
  setDeletePopup,
  handleEducationDeleteData,
  contents,
  id,
  handleProfessionalDeleteData,
}) {
  const { deleteProfessionalDetails, deleteEducation } = UseProfileContext();
  function handleDelete() {
    if (contents === "professional") {
      deleteProfessionalDetails(id);
      handleProfessionalDeleteData();
    } else {
      deleteEducation(id);
      handleEducationDeleteData();
    }
    setDeletePopup(false);
  }
  return (
    <>
      <div className="w-full h-[100vh] flex justify-center items-center fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className=" lg:h-[400px] bg-white rounded-[18px] md:rounded-[40px] sm:h-[360px] sm:w-[360px]  h-[266px] w-[251px] lg:w-[540px] flex flex-col justify-center items-center shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
          <img
            className="max-sm:w-[69px] max-sm:h-[69px] max-md:w-[124px] max-md:h-[124px]"
            src={deleteGif}
            alt="delete_icon"
          />
          <p className=" text-black leading-4 lg:leading-6 font-normal text-xs sm:text-sm lg:text-md text-center mt-6">
            Are you sure you want to delete
            <span className=" block">these {contents !== "" ? contents : content} details ?</span>
          </p>
          <div className="mt-10 flex gap-4 lg:gap-14">
            <button
              // onClick={() => {
              //   setDeletePopup(false);
              //   {
              //     contents === "professional"
              //       ? handleProfessionalDeleteData()
              //       : handleEducationDeleteData();
              //   }
              // }}
              onClick={handleDelete}
              className="font-normal text-sm sm:text-base text-black border border-[#BEC1C3]text-white  bg-white duration-500 px-6 sm:px-8 rounded-[100px] text-nowrap h-[40px] lg:h-[60px]">
              Delete
            </button>
            <button
              onClick={() => setDeletePopup(false)}
              className="font-normal text-sm sm:text-base text-white border border-[#BEC1C3]text-white bg-primary duration-500 px-6 sm:px-8 rounded-[100px] text-nowrap h-[40px] lg:h-[60px]">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteAccountpop;
