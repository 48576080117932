const StepBar = ({ activeSteps, totalSteps }) => {
  return (
    <>
      <div className="flex gap-1 justify-between md:justify-center mt-4 md:mt-[36px] px-5">
        {[...Array(totalSteps)].map((_, index) => (
          <span
            key={index}
            className={`inline-block w-20 h-1.5 rounded-md ${
              index === activeSteps ? "bg-blue-500" : "bg-[#EBF2FF]"
            }`}
          ></span>
        ))}
      </div>
    </>
  );
};

export default StepBar;
