import React from "react";
import { useState, useEffect } from "react";
import Book_icon from "../../assets/images/png/Book_icon.png";
import PopularGenres1 from "../../assets/images/png/PopularGenres1.png";
const Genres = ({ data, index }) => {
  const [genrersData, setGenresData] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 644) {
        if (index >= 3) {
          setGenresData(false);
        }
      }
    };
    handleResize();
  }, [data.content]);
  return (
    <>
      <div className="relative rounded-xl overflow-hidden">
        <img src={PopularGenres1} alt="" className="w-full" />
        <div className="lg:py-5 sm:py-2 py-1 absolute bg-[rgba(255, 255, 255, 0.4)] w-full bottom-0 backdrop-blur-[5.63793px] text-center bg-[#c8c6c666]">
          <p className="text-white font-semibold lg:text-xl px-3  sm:text-base text-xxs pb-3">{data.split("_").join(" ")}</p>
          <div className="">
            <img
              src={Book_icon}
              alt=""
              className=" pb-2 mx-auto lg:w-[36px] sm:w-[21px] w-[10px]"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Genres;
