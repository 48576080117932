import React, { useEffect, useRef, useState } from "react";
import { BackArrowIcon } from "../common/Icons";
import DeactivatingAccountPopup from "./DeactivatingAccountPopup";
import { useNavigate } from "react-router-dom";
import SureDeleteAccount from "../../helpsettingpop/SureDeleteAccount";
import Forgetpassword from "../../helpsettingpop/Forgetpassword";
import DeleteAccount from "../../helpsettingpop/DeleteAccount";
import DeleteAccountpop from "../../helpsettingpop/DeleteAccountpop";
import DeleteSucessfully from "../../helpsettingpop/DeleteSucessfully";
import DeactivateConfiromPopup from "../../helpsettingpop/DeactivateConfiromPopup";
import { UseInterestContext } from "../ContextApis/SettingsContext";
const listItem = [
  {
    items:
      " Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your languagepreference or login information.",
  },
  {
    items:
      "Company: when this policy mentions “Company,” “we,” “us,” or “our,”    it refers to UNIVERSITA that is responsible for your information under this Privacy Policy.",
  },
  {
    items:
      "Country: where UNIVERSITA or the owners/founders of UNIVERSITA are based, in this case is India.",
  },
  {
    items:
      "  Customer: refers to the company, organization or person that signs up to use the UNIVERSITA Service to manage the relationships with your consumers or service users.,",
  },
  {
    items:
      "  Device: any internet connected device such as a phone, tablet,computer or any other device that can be used to visit UNIVERSITA and use the services.",
  },
  {
    items:
      "  IP address: Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.",
  },
  {
    items:
      " Personnel: refers to those individuals who are employed by UNIVERSITA or are under contract to perform a service on behalf of one of the parties described herein.",
  },
  {
    items:
      " Personal Data: any information that directly, indirectly, or in connection with other information including a personal identification number, allows for the identification or identifiability of a natural person.",
  },
  {
    items:
      "  Service: refers to the service provided by UNIVERSITA as described in the relative terms (if available) and on this platform.",
  },
  {
    items:
      "    Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.",
  },
  {
    link: "https://www.universita.com/privacy-terms",
    items: "  Website: UNIVERSITA’s site, which can be accessed via this URL: ",
  },
  {
    items: "   You: a person or entity that is registered with UNIVERSITA to use the Services.",
  },
];

const PrivacyTerms = () => {
  const [deletewised, setDeletewised] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [surepopup, setsurepopup] = useState(false);
  const [forget, setForget] = useState(false);
  const [confiromDeletePopup, setConfiromDeletePopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [successDeletePopup, setSuccessDeletePopup] = useState(false);
  const [content, setContent] = useState("");
  const [fixResion, setFixResion] = useState("");
  const [reportDiscription, setReportDiscription] = useState("");
  const [password, setPassword] = useState("");
  const { deactivateAccount, deleteAccount } = UseInterestContext();
  function deactivateUser() {
    let deactivateData = {
      reasonEnum: fixResion,
      description: reportDiscription,
      password: password,
    };

    deactivateAccount(deactivateData);
  }
  function deleteUser() {
    let deleteData = {
      reasonEnum: fixResion,
      description: reportDiscription,
      password: password,
    };
    deleteAccount(deleteData);
  }
  const navigate = useNavigate();
  useEffect(() => {
    if (
      deletewised ||
      deactivate ||
      surepopup ||
      forget ||
      confiromDeletePopup ||
      successDeletePopup ||
      deletePopup
    ) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [
    deletewised,
    deactivate,
    surepopup,
    forget,
    confiromDeletePopup,
    successDeletePopup,
    deletePopup,
  ]);

  function hideLastPopup() {
    setTimeout(() => {
      setSuccessDeletePopup(false);
      navigate("/login");
      localStorage.clear();
      sessionStorage.clear();
    }, 3000);
  }

  const popupRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setDeactivate(false);
        setDeletewised(false);
        setsurepopup(false);
        setForget(false);
        setDeletePopup(false);
        setConfiromDeletePopup(false);
        setSuccessDeletePopup(false);
      }
    }
    if (
      deactivate ||
      deletewised ||
      surepopup ||
      forget ||
      deletePopup ||
      confiromDeletePopup ||
      successDeletePopup
    ) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    deactivate,
    deletewised,
    surepopup,
    forget,
    deletePopup,
    confiromDeletePopup,
    successDeletePopup,
  ]);
  return (
    <>
      {(deactivate ||
        deletewised ||
        surepopup ||
        forget ||
        deletePopup ||
        confiromDeletePopup ||
        successDeletePopup) && (
        <div className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full max-sm:overflow-auto"></div>
      )}
      {deactivate && (
        <div ref={popupRef}>
          <DeactivatingAccountPopup
            setFixResion={setFixResion}
            setDeactivate={setDeactivate}
            setsurepopup={setsurepopup}
            setForget={setForget}
            fixResion={fixResion}
            title="Deactivating "
            title2=" Wised account"
            subHeading="Let us know the reason you want to deactivate the account."
          />
        </div>
      )}
      {deletewised && (
        <div ref={popupRef}>
          <DeactivatingAccountPopup
            setDeactivate={content === "" ? setDeactivate : setDeletewised}
            setFixResion={setFixResion}
            setsurepopup={setsurepopup}
            setForget={setForget}
            fixResion={fixResion}
            title="Deleting"
            title2=" Wised account"
            subHeading="We're sorry to see you go. We'd like to know why you're deleting your account as we may be able to help with common issues.."
          />
        </div>
      )}
      {surepopup && (
        <div ref={popupRef}>
          <SureDeleteAccount
            setReportDiscription={setReportDiscription}
            setsurepopup={setsurepopup}
            reportDiscription={reportDiscription}
            setForget={setForget}
            data={content === "" ? "deactivating" : "detete"}
          />
        </div>
      )}

      {forget && (
        <div ref={popupRef}>
          <Forgetpassword
            password={password}
            setPassword={setPassword}
            setForget={setForget}
            setDeletePopup={setDeletePopup}
          />
        </div>
      )}
      {deletePopup && (
        <div ref={popupRef}>
          <DeleteAccount
            popupName={content === "" ? "Deactivating" : "Delete"}
            details={
              content === ""
                ? "As per the GDPR policy, your data will be deleted after an year. In the meantime we might use your data for optimising our services. You can reactivate your account by logging in to your Wised account."
                : "As per the GDPR policy, your data will be deleted after an year. In the meantime we might use your data for optimising our services."
            }
            setDeletePopup={setDeletePopup}
            setConfiromDeletePopup={setConfiromDeletePopup}
          />
        </div>
      )}
      {confiromDeletePopup && (
        <div ref={popupRef}>
          <DeactivateConfiromPopup
            setConfiromDeletePopup={setConfiromDeletePopup}
            setSuccessDeletePopup={setSuccessDeletePopup}
            hideLastPopup={hideLastPopup}
            deactivateUser={deactivateUser}
            deleteUser={deleteUser}
            details={content === "" ? "deactivate" : "delete"}
            btnName={content === "" ? "Deactivate" : "Delete"}
          />
        </div>
      )}
      {successDeletePopup && (
        <div ref={popupRef}>
          <DeleteSucessfully content={content === "" ? "Deactivated" : "Deleted"} />
        </div>
      )}

      <div className="mt-4 sm:mt-0">
        <div className="flex items-center gap-4 sm:hidden px-4 mb-4">
          <span className="cursor-pointer" onClick={() => window.history.back()}>
            <BackArrowIcon />
          </span>
          <h2 className="font-medium text-base text-black capitalize">
            Privacy policy and account control
          </h2>
        </div>
        <h2 className="font-normal text-base  text-center lg:text-start lg:text-xl text-black capitalize py-3 lg:py-6 px-4 md:px-8 bg-light-300">
          Account control
        </h2>
        <div className="px-5 md:px-[30px] py-4 sm:py-8">
          <p className="text-black font-normal text-[14px] sm:text-base">
            <span className="block text-black font-medium text-[14px] sm:text-base mb-2">
              Deactivate/Delete your account
            </span>
            If you want to take a break from Wised, you can temporarily deactivate this account. If
            you want to permanently delete your account, let us know.
          </p>
          <div className="flex flex-wrap sm:-mx-3  mt-4 sm:mt-8 lg:mt-10">
            <div className="w-full lg:w-1/2 sm:px-3 xl:pe-[88px] ">
              <div
                onClick={() => {
                  setDeactivate(true);
                  setContent("");
                }}
                className="flex items-center gap-2.5 pb-1.5">
                <input className="w-[18px] h-[18px] " type="checkbox" id="Deactivate" />
                <label
                  htmlFor="Deactivate"
                  className="text-sm lg:text-base font-medium text-black capitalize">
                  Deactivate
                </label>
              </div>
              <p className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135]">
                Deactivate Deactivating your account will temporarily disable your profile, making
                it invisible. You will have the option to reactivate your account by logging in at
                any time. Please note that this action does not permanently delete your account or
                its data and you can deactivate your account once in a month.
              </p>
            </div>
            <div className="w-full lg:w-1/2 sm:px-3 xl:pe-[88px] mt-4 sm:mt-6 md:mt-8 lg:mt-0">
              <div
                onClick={() => {
                  setDeletewised(true);
                  setContent("delete");
                }}
                className="flex items-center gap-2.5 pb-1.5">
                <input className="w-[18px] h-[18px] " type="checkbox" id="delete" />
                <label
                  htmlFor="delete"
                  className="text-sm lg:text-base font-medium text-black capitalize">
                  Delete
                </label>
              </div>
              <p className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135]">
                Deactivate Deactivating your account will temporarily disable your profile, making
                it invisible. You will have the option to reactivate your account by logging in at
                any time. Please note that this action does not permanently delete your account or
                its data and you can deactivate your account once in a month.
              </p>
            </div>
          </div>
        </div>
        <h2 className="font-normal text-base sm:text-[20px] md:text-xl text-black capitalize py-[5px] sm:py-3 md:py-6 px-4 md:px-8 bg-light-300 text-center sm:text-start">
          Privacy policy
        </h2>
        <div className="px-5 sm:px-7 lg:pe-[72px] lg:ps-[61px] py-4 sm:py-8 sm:mb-10 lg:mb-0">
          <span className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135]">
            Updated at 2021-04-19
          </span>
          <p className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135] mt-4 md:mt-6">
            UNIVERSETIA. ('we," "our," or "us") is committed to protecting your privacy. This
            Privacy Policy explains how your personal information is collected, used, and disclosed
            by UNIVERSETIA.
          </p>
          <p className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135]">
            This Privacy Policy applies to our website,{" "}
            <a
              className="whitespace-break-spaces"
              href="mailto:https://www.universetia.com/privacy-terms">
              https://www.universetia.com/privacy-terms
            </a>
            , and its associated subdomains (collectively, our "Service"). By accessing or using our
            Service, you signify that you have read, understood, and agree to our collection,
            storage, use, and disclosure of your personal information as described in this Privacy
            Policy and our Terms of Service
          </p>
          <p className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135]">
            Definitions and key terms
          </p>
          <p className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135]">
            For this Privacy Policy:
          </p>

          <ul className="!list-disc px-5">
            {listItem.map((item, index) => (
              <li
                key={index}
                className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135]">
                {item.items} <a href={item.link}>{item.link}</a>
              </li>
            ))}
          </ul>

          <h2 className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135]">
            What Information Do We Collect?
          </h2>
          <p className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135]">
            We collect information from you when you visit our service, register, place an order,
            subscribe to our newsletter, respond to a survey{" "}
            <span className="block">or fill out a form.</span>
          </p>
          <ul className="list-disc px-5">
            <li className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135]">
              Name / Username
            </li>
            <li className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135]">
              Phone Numbers
            </li>
            <li className="font-normal text-sm sm:text-[14px] lg:text-base text-[#303135]">
              Email Addresses
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PrivacyTerms;
