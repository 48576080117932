import React, { useState, useRef, useEffect } from "react";
import cut from "../../assets/images/png/cut.png";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { UseAuth } from "../ContextApis/AuthContext";
import { CloseIcon } from "../common/Icons";

function EmailOtp({ setVerifyOtp, setNewPassword, email, setForgotPassword }) {
  const [error, setError] = useState(null);
  const inputRefs = useRef([]);
  const { verifyForgotOtp, forgotPassword } = UseAuth();

  function handlesubmit(e) {
    e.preventDefault();
    let hasError = false;
    const otp = inputRefs.current.map((input) => input.value).join("");

    inputRefs.current.forEach((input) => {
      if (!input.value) {
        hasError = true;
      }
    });

    setError(hasError);

    if (!hasError) {
      verifyForgotOtp(otp, setVerifyOtp, setNewPassword, email);
    }
  }

  const handleInputChange = (index, event) => {
    const { value } = event.target;

    if (value.length === 1 && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      if (inputRefs.current[index].value === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        inputRefs.current[index].value = "";
      }
    }
  };
  function handleResendOtp() {
    forgotPassword(email, setVerifyOtp, setForgotPassword);
  }

  return (
    <>
      <div className="flex justify-center items-center fixed top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 z-[60] w-full">
        <div className="w-[92%] sm:w-[540px] bg-white rounded-[18px] md:rounded-[40px] py-5 sm:py-9 max-sm:px-[10px] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
          <div className="flex items-center justify-between px-3 sm:px-5 pb-5 md:pb-0 max-md:left-0">
            <p className="font-semibold text-base">Enter your OTP</p>
            <button onClick={() => setVerifyOtp(false)}>
              <CloseIcon />
            </button>
          </div>
          {error === null ? (
            <p className="text-center font-medium text-md max-md:text-sm md:mt-4">
              Please enter the 6 digit code sent on your mail.
            </p>
          ) : (
            <p className="text-center font-medium text-[#FF0000] text-md max-md:text-sm md:mt-4">
              Incorrect code. Please try again.
            </p>
          )}
          <form onSubmit={handlesubmit}>
            <div className="flex justify-center mt-3 md:mt-6 space-x-4">
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  autoFocus={index === 0}
                  className="border-b-2 border-black w-[35px] sm:w-12 h-12 text-center text-xl max-md:text-base outline-none"
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(e) => handleInputChange(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                />
              ))}
            </div>
            <div className="text-center">
              <button
                onClick={handleResendOtp}
                type="button"
                className="text-md max-md:text-sm font-medium text-center text-[#4D8AFF] mt-12 cursor-pointer">
                Resend code
              </button>
            </div>
            <div className="text-center">
              <button className="md:mt-4 mt-[35px] px-1 md:px-0 w-full max-sm:text-xs">
                <Button btnname="Verify" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EmailOtp;
