import React from "react";
import sadFaceGig from "../assets/gif/sad-face.gif";

const DeleteSucessfully = ({ content }) => {
  return (
    <div className=" bg-white rounded-[18px] md:rounded-[40px] flex flex-col justify-center items-center max-sm:h-[200px] max-sm:w-[245px] sm:p-5 sm:pb-7 shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] z-[50] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
      <img className="max-sm:w-[70px] max-sm:h-[70px]" src={sadFaceGig} alt="sad-Face" />
      <p className=" text-black max-sm:text-sm  lg:leading-6 font-normal  text-center px-3 mt-8">
        {content} successfully. <br />
        We are sorry to see you go :(
      </p>
    </div>
  );
};

export default DeleteSucessfully;
