import React, { useEffect, useState } from "react";
import { CrossIcon } from "../common/Icons";
import { UseAuth } from "../ContextApis/AuthContext";

const ForgotPassPopup = ({ setVerifyOtp, setForgotPassword, setEmail, email }) => {
  const [error, setError] = useState("");
  const { forgotPassword } = UseAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.includes("@gmail.com")) {
      forgotPassword(email, setVerifyOtp, setForgotPassword);
    } else {
      setError("Please enter a valid Gmail address.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="sm:max-w-[540px] w-[92%] bg-white p-[16px] sm:p-[22px] lg:p-[30px] rounded-[18px] md:rounded-[40px] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[60] flex flex-col items-start shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] Forgot_Password">
        <div className="flex w-full justify-between items-center">
          <p className="text-base font-semibold text-[#000000]">Forgot Password</p>
          <button type="button" onClick={() => setForgotPassword(false)} className="cursor-pointer">
            <CrossIcon />
          </button>
        </div>

        <p className="text-sm sm:text-base text-medium mt-5 text-center">
          Please enter your email, so we can send you the verification code to your email address.
        </p>
        <input
          required
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="mt-5 max-w-[430px] w-full bg-[#F8F8F8] h-[56px] px-8 rounded-[100px] placeholder:text-[14px] sm:placeholder:text-[16px] outline-none border border-gray-300 max-sm:text-[14px] m-auto"
        />
        {error && <p className="text-red-500 text-sm mt-2 text-center w-full">{error}</p>}
        <button
          type="submit"
          className="w-[150px] bg-primary rounded-[100px] h-[56px] text-[14px] sm:text-base text-white m-auto mt-5">
          Continue
        </button>
      </div>
    </form>
  );
};

export default ForgotPassPopup;
