import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BackArrowIcon } from "../common/Icons";
import { UseInterestContext } from "../ContextApis/SettingsContext";
import showErrorToast from "../ToastError";

function Notifications() {
  const {
    getNotificationSettings,
    notificationSettings,
    updateNotificationSettings,
    setNotificationSettings,
  } = UseInterestContext();
  const navigate = useNavigate();

  const handleToggle = async (nameVal, value) => {
    try {
      let updateSetting;
      if (nameVal === "closeAll" && value === false) {
        updateSetting = {
          closeAll: true,
          likes: false,
          profileSuggestions: false,
          newContentsInLibrary: false,
          newContentsInInterest: false,
          tagged: false,
          trending: false,
          firstPosts: false,
        };
        setNotificationSettings(updateSetting);
        await updateNotificationSettings(updateSetting);
      } else if (nameVal !== "closeAll" && notificationSettings["closeAll"] === true) {
        updateSetting = {
          ...notificationSettings,
          [nameVal]: !value,
          closeAll: false,
        };
        setNotificationSettings(updateSetting);
        await updateNotificationSettings(updateSetting);
      } else {
        updateSetting = { ...notificationSettings, [nameVal]: !value, closeAll: false };
        setNotificationSettings(updateSetting);
        await updateNotificationSettings(updateSetting);
      }
    } catch (err) {
      showErrorToast("Failed to update notification settings");
    }
  };

  useEffect(() => {
    getNotificationSettings();
  }, []);

  const goback = () => {
    navigate(-1);
  };

  const formatSettingName = (setting) => {
    return setting
      .replace(/([A-Z])/g, " $1") // Add space before capital letters
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
      .replace(/([a-z0-9])([A-Z])/g, "$1 $2"); // Add space between lowercase and uppercase letters
  };

  return (
    <div className="bg-[#FCFCFC]">
      <div className="max-w-[1114px] mx-auto sm:py-[32px] lg:py-[65px] 2xl:py-[88px] px-4">
        <div className="flex gap-4 pt-4 sm:hidden">
          <button onClick={goback}>
            <BackArrowIcon />
          </button>
          <span className="font-medium text-black text-base leading-5">Notifications</span>
        </div>
        {notificationSettings.length === 0 ? (
          <p className="text-center font-normal text-lg text-gray-600 mt-8">
            No notifications available.
          </p>
        ) : (
          Object.keys(notificationSettings).map((setting) => {
            return (
              <div className="flex items-center justify-between mt-6" key={setting}>
                <p className="font-normal text-sm lg:text-lg text-black leading-6">
                  {formatSettingName(setting)}
                </p>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={notificationSettings[setting] === true}
                    onChange={() => handleToggle(setting, notificationSettings[setting])}
                    className="sr-only peer"
                  />
                  <div
                    className={`relative w-[41px] h-[18px] bg-black ms-9 rounded-full peer ${
                      notificationSettings[setting] === false
                        ? "bg-gray-700"
                        : "peer-checked:bg-[#4D8AFF]"
                    }`}>
                    <div
                      className={`absolute top-[2px] left-[2px] w-[14px] h-[14px] bg-white rounded-full transition-transform ${
                        notificationSettings[setting] ? "translate-x-[23px]" : ""
                      }`}></div>
                  </div>
                </label>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
  
}

export default Notifications;
