import React, { useEffect, useState } from "react";
import Spotlight from "../../components/Home/Spotlight";
import { LeftIcon } from "../../components/common/Icons";
import { UseHomeContext } from "../ContextApis/HomeContext";
import { UseInterestContext } from "../ContextApis/SettingsContext";
const AllSpotlight = () => {
  const { Spotlightdata, GetSpotlightdata } = UseHomeContext();
  const { getInterestData } = UseInterestContext();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; // Display 9 items per page
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  // Calculate the total number of pages
  const totalPages = Math.ceil(Spotlightdata.length / itemsPerPage);

  // Get the data for the current page
  const currentItems = Spotlightdata.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      scrollToTop();
    }
  };

  // Handle next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
      scrollToTop();
    }
  };

  async function callApis() {
    let storeTopic = sessionStorage.getItem("topic");
    let insData = await getInterestData();
    GetSpotlightdata(
      storeTopic !== null
        ? storeTopic
        : typeof insData === "object" && Object.values(insData).length !== 0
        ? ""
        : ""
    );
  }
  useEffect(() => {
    callApis();
  }, []);
  return (
    <>
      <section className="px-6 max-sm:px-4">
        <div className="md:pt-[40px] sm:pt-[50px] flex items-center sm:justify-center justify-start md:pb-[40px] sm:pb-[50px] pb-4">
          <button onClick={() => window.history.back()}>
            <LeftIcon />
          </button>
          <span className=" text-black font-semibold sm:text-lg text-base text-center ">
            Spotlight
          </span>
        </div>
        <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 gap-y-7 max-sm:gap-y-4">
          {currentItems.map((item, index) => {
            return (
              <div key={index}>
                <Spotlight data={item} />
              </div>
            );
          })}
        </div>
        <div className="flex justify-center items-center gap-4 mt-6 md:mb-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="px-4 py-1 md:py-2 bg-[#4d8aff] text-white rounded disabled:opacity-50 max-md:text-sm">
            Prev
          </button>
          <p className="max-md:text-sm">
            Page {currentPage} of {totalPages}
          </p>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-1 md:py-2 bg-[#4d8aff] text-white rounded  disabled:opacity-50 max-md:text-sm">
            Next
          </button>
        </div>
      </section>
    </>
  );
};
export default AllSpotlight;
