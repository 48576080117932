import React from "react";
import { CloseIcon } from "../components/common/Icons";
function DeleteAccount({ popupName, setDeletePopup, setConfiromDeletePopup, details }) {
  return (
    <>
      <div className=" h-[264px] bg-white rounded-[18px] md:rounded-[40px] w-[92%] md:w-[523px] lg:w-[620px] py-5 sm:pt-2 sm:pb-[22px] flex flex-col  justify-center sm:justify-end shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] z-[50] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div className="sm:flex justify-end hidden">
          <button
            onClick={() => setDeletePopup(false)}
            className="hidden md:flex absolute top-5 right-5">
            <CloseIcon />
          </button>
        </div>
        <h2 className=" text-black leading-6 font-semibold lg:font-medium text-sm sm:text-md lg:text-lg text-center flex justify-center gap-2">
          {popupName} <span className="sm:block hidden"> Wised account</span>
        </h2>
        <p className=" font-normal text-sm lg:text-base leading-6 text-black text-center mt-5 lg:mt-9 w-[86%] mx-auto">
          {details}
        </p>
        <div className="flex justify-center gap-4 sm:gap-[60px] lg:gap-[64px] mt-5 sm:mt-5 lg:mt-5">
          <button
            onClick={() => {
              setConfiromDeletePopup(true);
              setDeletePopup(false);
            }}
            className="font-normal text-sm sm:text-base text-black border border-[#BEC1C3]text-white  bg-white duration-500 px-6 sm:px-8 rounded-[100px] text-nowrap h-[40px] lg:h-[60px]">
            Continue
          </button>
          <button
            onClick={() => setDeletePopup(false)}
            className="font-normal text-sm sm:text-base text-white border border-[#BEC1C3]text-white bg-primary duration-500 px-6 sm:px-8 rounded-[100px] text-nowrap h-[40px] lg:h-[60px]">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default DeleteAccount;
