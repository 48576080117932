import React from "react";


const PersonaliseCommonBtn = ({ onClick }) => {
  return (
    <>
        <button
          onClick={onClick}
          type="submit"
          className="text-base max-sm:text-sm font-normal text-white py-2 px-6 bg-primary rounded-[100px] !leading-6 h-14 max-w-[330px] w-[330px]">
          Continue
        </button>
    </>
  );
};

export default PersonaliseCommonBtn;
