import { createContext, useContext, useState } from "react";
import { path } from "../common/Api";
const PDFContext = createContext();

export function PDFContextProvider({ children }) {
  const [pdf, setPdf] = useState("");
  const fetchPdf = async (id) => {
    try {
      const response = await fetch(`${path}auth/download?fileId=${id}`);

      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }

      const blob = await response.blob();
      setPdf(blob);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };
  return <PDFContext.Provider value={{ pdf, fetchPdf }}>{children}</PDFContext.Provider>;
}

export function UsePDFContext() {
  return useContext(PDFContext);
}
