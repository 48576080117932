import React from "react";

function Button({ btnname, changecolor }) {
  return (
    <>
      <button
        className={`font-normal text-sm sm:text-base  ${
          btnname === "Sign Up" && !changecolor
            ? " text-black border border-[#BEC1C3]"
            : "text-white  bg-primary"
        }  duration-500  h-[56px] px-5 max-w-[330px] w-full sm:px-10 rounded-[100px] text-nowrap `}
      >
        {btnname}
      </button>
    </>
  );
}

export default Button;
