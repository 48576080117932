import React from "react";
import deleteGif from "../assets/images/png/delete.gif";
function DeactivateConfiromPopup({
  setSuccessDeletePopup,
  setConfiromDeletePopup,
  hideLastPopup,
  details,
  btnName,
  deactivateUser,
  deleteUser,
}) {
  return (
    <>
      <div className=" lg:h-[400px] bg-white rounded-[18px] md:rounded-[40px] sm:h-[360px] sm:w-[360px]  h-[266px] w-[92%] lg:w-[540px] flex flex-col justify-center items-center shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] z-[50] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <img
          className="max-sm:w-[69px] max-sm:h-[69px] max-md:w-[124px] max-md:h-[124px]"
          src={deleteGif}
          alt="delete_icon"
        />
        <p className=" text-black leading-4 lg:leading-6 font-normal text-sm lg:text-md text-center mt-6">
          Are you sure you want to {details} <br /> your account?
        </p>
        <div className="mt-10 flex gap-4 lg:gap-14">
          <button
            onClick={() => {
              setConfiromDeletePopup(false);
              setSuccessDeletePopup(true);
              hideLastPopup();
              if (btnName === "Deactivate") {
                deactivateUser();
              } else {
                deleteUser();
              }
            }}
            className="font-normal text-sm sm:text-base text-black border border-[#BEC1C3]text-white  bg-white duration-500 px-6 sm:px-8 rounded-[100px] text-nowrap h-[40px] lg:h-[60px]">
            {btnName}
          </button>
          <button
            onClick={() => setConfiromDeletePopup(false)}
            className="font-normal text-sm sm:text-base text-white border border-[#BEC1C3]text-white bg-primary duration-500 px-6 sm:px-8 rounded-[100px] text-nowrap h-[40px] lg:h-[60px]">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default DeactivateConfiromPopup;
