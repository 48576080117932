import logo from "../../assets/images/png/Ellipse 1.png";
import designer1 from "../../assets/images/png/designer.png";
import designer2 from "../../assets/images/svg/designer2.svg";
import designer3 from "../../assets/images/svg/designer3.svg";
import designer4 from "../../assets/images/svg/designer4.svg";
export const data = [
  {
    name: "Ram",
    course: "ux/ui Designer",
    follow: "false",
    img: designer1,
  },
  {
    name: "Shyam",
    course: "ux/ui Designer",
    follow: "false",
    img: designer2,
  },
  {
    name: "Ajay",
    course: "ux/ui Designer",
    follow: "false",
    img: designer3,
  },
  {
    name: "Suman",
    course: "ux/ui Designer",
    follow: "false",
    img: designer4,
  },
  {
    name: "Suresh",
    course: "ux/ui Designer",
    follow: "false",
    img: designer1,
  },
  {
    name: "Vijay",
    course: "ux/ui Designer",
    follow: "false",
    img: designer2,
  },
  {
    name: "Kartik",
    course: "ux/ui Designer",
    follow: "false",
    img: designer3,
  },
  {
    name: "Kumal",
    course: "ux/ui Designer",
    follow: "false",
    img: designer4,
  },
  {
    name: "Vikram",
    course: "ux/ui Designer",
    follow: "false",
    img: designer1,
  },
  {
    name: "Ajit",
    course: "ux/ui Designer",
    follow: "false",
    img: designer2,
  },
];


export const recommondedPeopledata = [
  {
    img: logo,
    name: "Darrell Steward",
    title: "QA Engineer",
    follow: false,
  },
  {
    img: logo,
    name: "Darrell Steward",
    title: "QA Engineer",
    follow: false,
  },
  {
    img: logo,
    name: "Darrell Steward",
    title: "QA Engineer",
    follow: false,
  },
  {
    img: logo,
    name: "Darrell Steward",
    title: "QA Engineer",
    follow: false,
  },
  {
    img: logo,
    name: "Darrell Steward",
    title: "QA Engineer",
    follow: false,
  },
  {
    img: logo,
    name: "Darrell Steward",
    title: "QA Engineer",
    follow: false,
  },
  {
    img: logo,
    name: "Darrell Steward",
    title: "QA Engineer",
    follow: false,
  },
  {
    img: logo,
    name: "Darrell Steward",
    title: "QA Engineer",
    follow: false,
  },
  {
    img: logo,
    name: "Darrell Steward",
    title: "QA Engineer",
    follow: false,
  },
  {
    img: logo,
    name: "Darrell Steward",
    title: "QA Engineer",
    follow: false,
  },
];

export const RecommendationsData = [
  {
    img: "",
    descripition:
      "Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efficitur neque. Ut diam quam, semper iaculis condimentum ac, vestibulum eu nisl.",
    name: "Floyd Miles",
    dateTime: "09:15 pm. 26 Dec 23",
  },
  {
    img: "",
    descripition:
      "Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efficitur neque. Ut diam quam, semper iaculis condimentum ac, vestibulum eu nisl.",
    name: "Floyd Miles",
    dateTime: "09:15 pm. 26 Dec 23",
  },
  {
    img: "",
    descripition:
      "Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efficitur neque. Ut diam quam, semper iaculis condimentum ac, vestibulum eu nisl.",
    name: "Floyd Miles",
    dateTime: "09:15 pm. 26 Dec 23",
  },
];
