import React, { createContext, useContext, useEffect, useState } from "react";
import Loader from "../Loader";
import { path } from "../common/Api";
const QuestionPaperContext = createContext();

export function QuestionPaperContextProvider({ children }) {
  const [questionPapers, setQuestionPapers] = useState([]);
  const [allQuestionPapers, setAllQuestionPapers] = useState([]);
  const [pdfFileName, setPdfFileName] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getQuestionPaper(universityParem) {
    setLoading(true);
    setQuestionPapers([]);
    let universityData = {};
    if (universityParem !== undefined) {
      universityData = universityParem;
    } else if (sessionStorage.getItem("universityData") !== null) {
      universityData = JSON.parse(sessionStorage.getItem("universityData"));
    } else {
      universityData = {
        university: "Babasaheb Ambedkar Marathwada University Aurangabad (BAMU)",
        Specialisation: "Chemical Engineering",
        Semester: "3",
      };
    }

    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      // Make the API call
      const url = `${path}auth/folder_contents?folderPath=Wised Content QP/${universityData.university}/${universityData.Specialisation}/${universityData.Semester}`;

      const response = await fetch(url, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        const data = await response.json();
        setQuestionPapers(data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function getAllQuestionPaper() {
    setLoading(true);
    let universityData = {};
    if (sessionStorage.getItem("universityData") !== null) {
      universityData = JSON.parse(sessionStorage.getItem("universityData"));
    } else {
      universityData = {
        university: "Babasaheb Ambedkar Marathwada University Aurangabad (BAMU)",
        Specialisation: "Chemical Engineering",
        Semester: "3",
      };
    }

    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      // Make the API call
      const url = `${path}auth/all_contents?folderPath=Wised Content QP/${universityData.university}/${universityData.Specialisation}/${universityData.Semester}`;

      const response = await fetch(url, requestOptions);

      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        const data = await response.json();
        setAllQuestionPapers(data.items);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  }
  async function getQuestionPaperPDF(paperName) {
    setPdfFileName([]);
    let universityData = {};
    if (sessionStorage.getItem("universityData") !== null) {
      universityData = JSON.parse(sessionStorage.getItem("universityData"));
    } else {
      universityData = {
        university: "Babasaheb Ambedkar Marathwada University Aurangabad (BAMU)",
        Specialisation: "Chemical Engineering",
        Semester: "3",
      };
    }
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      // Make the API call
      const url = `${path}auth/folder_contents?folderPath=Wised Content QP/${universityData.university}/${universityData.Specialisation}/${universityData.Semester}/${paperName}`;

      const response = await fetch(url, requestOptions);
      // Check if the response is not okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed To Get Education Details");
      } else {
        const data = await response.json();
        setPdfFileName(data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  }


  useEffect(() => {
    getQuestionPaper();
    getAllQuestionPaper();
  }, []);

  return (
    <QuestionPaperContext.Provider
      value={{
        questionPapers,
        pdfFileName,
        getQuestionPaper,
        getQuestionPaperPDF,
        getAllQuestionPaper,
        allQuestionPapers,
      }}>
      {loading && <Loader />}
      {children}
    </QuestionPaperContext.Provider>
  );
}

export function UseQuestionPaperContext() {
  return useContext(QuestionPaperContext);
}
