import React, { useCallback, useEffect, useRef, useState } from "react";
import { DeleteIcon, EditIcon } from "../common/Icons";
import shape from "../../assets/images/svg/Shape.svg";
import { useNavigate } from "react-router-dom";
import EditInterest from "../../helpsettingpop/EditInterest";
import Educationpop from "../../helpsettingpop/Educationpop";
import ProfessionalPopup from "../../helpsettingpop/ProfessionalPopup";
import DeleteAccountpop from "../../helpsettingpop/DeleteAccountpop";
import cut from "../../assets/images/png/cut.png";
import Button from "../Button/Button";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import { UseInterestContext } from "../ContextApis/SettingsContext";
import showErrorToast from "../ToastError";
import { toast } from "react-toastify";
import _ from "lodash";
function Accountsetting() {
  const {
    profileData,
    editProfile,
    educationDtls,
    setEducationDtls,
    setProfessionalDtls,
    professionalDtls,
    checkUsernameAvailability,
    updateEmail,
  } = UseProfileContext();
  const { interestData, setInterestData, verifyOtp, forgotPassword, getAllInterestData } =
    UseInterestContext();
  // const [interestTags, setInterestTag] = useState(Object.values(interestData));
  const [showAddInterest, setshowAddInterest] = useState(false);
  const [content, setContent] = useState("");
  const [id, setId] = useState(undefined);
  const [educationalPopup, setEducationalPopup] = useState(false);
  const [professionalPopup, setProfessionalPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    day: "",
    month: "",
    year: "",
    email: "",
  });
  const profileAllData = {
    fullName: formData.name,
    userName: formData.username,
    dob: ` ${formData.day <= 9 ? "0" : ""}${formData.day}-${formData.month <= 9 ? "0" : ""}${
      formData.month
    }-${formData.year}`,
  };
  const [initialData, setInitialData] = useState({
    name: "",
    username: "",
    email: "",
    dob: {
      day: "",
      month: "",
      year: "",
    },
  });
  const [prevEmail, setPrevEmail] = useState("");
  const [professionalData, setProfessionalData] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [conformation, setConformation] = useState(false);
  const [error, setError] = useState(null);
  const [emailChanged, setEmailChanged] = useState(false);
  const [usernameAvailability, setUsernameAvailability] = useState(null);
  const [usernameChanged, setUsernameChanged] = useState(false);
  const [isUsernameValid, setIsUsernameValid] = useState("");
  const inputRefs = useRef([]);
  // Debounced username availability check
  const debouncedCheckUsernameAvailability = useCallback(
    _.debounce(async (username) => {
      try {
        const response = await checkUsernameAvailability(username);
        setUsernameAvailability(response);
        setIsUsernameValid(response?.available ?? false);
      } catch (error) {
        console.error("Error checking username availability:", error);
      }
    }, 5000),
    [checkUsernameAvailability]
  );
  // Debounced updateEmail function
  // const debouncedUpdateEmail = useCallback(
  //   _.debounce(async (email) => {
  //     try {
  //       await updateEmail(email);
  //       setPrevEmail(email); // Update previous email after successful API call
  //       navigate("/login");
  //     } catch (error) {
  //       console.error("Error updating email:", error);
  //     }
  //   }, 5000),
  //   []
  // );
  useEffect(() => {
    if (usernameChanged) {
      debouncedCheckUsernameAvailability(formData.username);
    } else {
      setIsUsernameValid(true); // Assume valid if unchanged
    }
  }, [formData.username, usernameChanged, debouncedCheckUsernameAvailability]);
  // Handler for username change
  const handleUserChange = (e) => {
    const newUsername = e.target.value;
    setFormData((prev) => ({ ...prev, username: newUsername }));
    setUsernameChanged(newUsername !== initialData.username); // Mark username as changed if it differs from initial
  };
  const handleemailchnage = (e) => {
    const newemail = e.target.value;
    setFormData((prev) => ({ ...prev, email: newemail }));
    setEmailChanged(newemail !== initialData.email);
  };
  // useEffect(() => {
  //   if (emailChanged) {
  //     debouncedUpdateEmail(formData.email);
  //   }
  // }, [formData.email, emailChanged, debouncedUpdateEmail]);
  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);
  const handleConfirmAndSubmit = async (e) => {
    e.preventDefault();
    // Check for validation errors
    let hasError = false;
    inputRefs.current.forEach((input) => {
      if (!input.value) {
        hasError = true;
      }
    });
    if (hasError) {
      setError(true);
      return;
    }
    try {
      const email = profileData.email;
      const otp = inputRefs.current.map((input) => input.value).join("");

      // Verify the OTP
      const response = await verifyOtp(otp, email);

      if (response.ok === true) {
        // OTP is valid, proceed to update the profile
        await editProfile(profileAllData);
        if (emailChanged) {
          await updateEmail(formData.email);
          toast.success("Email updated successfully.");
          navigate("/login"); // Redirect to login after email update
        }
        setPopup(false);
        setEditMode(false);
      } else {
        // OTP is invalid
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      // Handle errors
      toast.error("Error updating profile: " + error.message);
    }
  };
  // Fetch profile data on mount
  const fetchProfileData = async () => {
    const dob = profileData?.dob;
    const [day, month, year] = dob ? dob.split("-") : [null, null, null];
    setFormData({
      name: profileData?.fullName,
      username: profileData?.userName,
      day: day?.split("")[day.split("")[0] === "0" ? 1 : 0],
      month: month?.split("")[month.split("")[0] === "0" ? 1 : 0],
      year: year,
      email: profileData?.email,
    });
  };
  function deleteInterestData(data) {
    let updateInterests = interestData.filter((item) => !data.includes(item));
    setInterestData(updateInterests);
  }
  useEffect(() => {
    if (profileData !== undefined) {
      fetchProfileData();
    }
  }, [profileData]);
  const handleInputChange = (index, e) => {
    if (e.target.value.length === e.target.maxLength) {
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex].focus();
      }
    }
  };
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && e.target.value === "") {
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        inputRefs.current[prevIndex].focus();
      }
    }
  };
  function onhandelchange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }
  const submithandler = async (e) => {
    e.preventDefault();
    if (editMode) {
      const email = profileData.email;
      const response = await forgotPassword(email, setPopup);
      if (response) {
        console.log("OTP Generation Response:", response);
      }
    } else {
      setEditMode(true);
    }
  };
  const handleCancel = () => {
    setPopup(false);
  };
  useEffect(() => {
    if (conformation) {
      setEditMode(false);
      setConformation(false);
    }
  }, [conformation]);

  useEffect(() => {
    getAllInterestData();
  }, []);
  // education popup functions
  function addeducationalData(value) {
    setEducationDtls([...educationDtls, value]);
  }
  function handleEducationDeleteData() {
    const filterdata = educationDtls.filter((items, _) => items.id !== id);
    setEducationDtls(filterdata);
  }
  function handleEducationEdit(updatedData) {
    let index = educationDtls.findIndex((item) => item.id === id);
    let updatedEducationData = [...educationDtls];
    updatedEducationData[index] = updatedData;
    setEducationDtls(updatedEducationData);
  }
  // Profassional popup functions
  function addProfessionalData(value) {
    setProfessionalDtls([...professionalDtls, value]);
  }
  function handleProfessionalDeleteData() {
    const filterdata = professionalDtls.filter((items, _) => items.id !== id);
    setProfessionalDtls(filterdata);
  }
  function handleProfessionalEdit(updatedData) {
    let index = professionalDtls.findIndex((item) => item.id === id);
    let updatedProfessionalData = [...professionalDtls];
    updatedProfessionalData[index] = updatedData;
    setProfessionalDtls(updatedProfessionalData);
  }
  if (showAddInterest || educationalPopup || professionalPopup || deletePopup || popup) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
  const getColorClass = (value) => (value ? "text-black" : "text-grey");
  const navigate = useNavigate();
  const popupRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setEducationalPopup(false);
        setProfessionalPopup(false);
        setshowAddInterest(false);
        setPopup(false);
      }
    }
    if (educationalPopup || professionalPopup || showAddInterest || popup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [educationalPopup, professionalPopup, showAddInterest, popup]);
  return (
    <div className=" relative max-lg:pb-12">
      {(showAddInterest || deletePopup || educationalPopup || professionalPopup || popup) && (
        <div className="fixed top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 z-[50] w-full flex justify-center backdrop-blur-[10px] h-full items-center"></div>
      )}
      {showAddInterest && (
        <div ref={popupRef}>
          <EditInterest deleteInterestData={deleteInterestData} value={setshowAddInterest} />
        </div>
      )}
      {deletePopup && (
        <div ref={popupRef}>
          <DeleteAccountpop
            id={id}
            content="educational"
            setDeletePopup={setDeletePopup}
            handleEducationDeleteData={handleEducationDeleteData}
            handleProfessionalDeleteData={handleProfessionalDeleteData}
            contents={content}
          />
        </div>
      )}
      {professionalPopup && (
        <div ref={popupRef}>
          <ProfessionalPopup
            setProfessionalPopup={setProfessionalPopup}
            addProfessionalData={addProfessionalData}
            professionalData={professionalDtls}
            setProfessionalData={setProfessionalData}
            id={id}
            handleProfessionalEdit={handleProfessionalEdit}
          />
        </div>
      )}
      {educationalPopup && (
        <div ref={popupRef}>
          <Educationpop
            setEducationalPopup={setEducationalPopup}
            addeducationalData={addeducationalData}
            educationData={educationDtls}
            setEducationData={setEducationDtls}
            id={id}
            handleEducationEdit={handleEducationEdit}
          />
        </div>
      )}
      {popup && (
        <>
          <div
            ref={popupRef}
            className="flex justify-center items-center fixed top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 z-[60]">
            <div className="w-[100%] sm:w-[540px] bg-white rounded-[18px] md:rounded-[40px] py-5 sm:py-9 max-sm:px-[10px] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
              <div className="flex items-center justify-between max-sm:px-4 px-5 pb-5 md:pb-0 max-md:left-0">
                <p className="font-semibold text-base ms-3 md:ms-0">Enter your OTP</p>
                <img className="cursor-pointer" onClick={handleCancel} src={cut} alt="" />
              </div>
              {error === null ? (
                <p className="text-center font-medium text-md max-md:text-sm md:mt-4">
                  Please enter the 6 digit code sent on your mail.
                </p>
              ) : (
                <p className="text-center font-medium text-[#FF0000] text-md max-md:text-sm md:mt-4">
                  Incorrect code. Please try again.
                </p>
              )}
              <form onSubmit={handleConfirmAndSubmit}>
                <div className="flex justify-center mt-3 md:mt-6 space-x-4">
                  {[0, 1, 2, 3, 4, 5].map((index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      autoFocus={index === 0}
                      className="border-b-2 border-black w-12 h-12 text-center text-xl max-md:text-base outline-none"
                      ref={(el) => (inputRefs.current[index] = el)}
                      onChange={(e) => handleInputChange(index, e)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                    />
                  ))}
                </div>
                <p className="text-md max-md:text-sm font-medium text-center text-[#4D8AFF] mt-12 cursor-pointer">
                  Resend code
                </p>
                <div className="text-center">
                  <button
                    type="submit"
                    className="md:mt-4 mt-[35px] px-1 md:px-0 w-full max-sm:text-xs">
                    <Button btnname="Verify" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
      <div className=" flex gap-4 pt-4 sm:hidden px-4">
        <img
          className="cursor-pointer"
          onClick={() => navigate("/settings")}
          width={"18px"}
          src={shape}
          alt="shape"
        />
        <span className=" font-medium text-black text-base leading-5">Account</span>
      </div>
      <div className="bg-[#fefefe] ">
        <div className=" bg-[#F8F8F8] py-1 lg:py-6 px-8 mb-6 lg:mb-[30px] text-center lg:text-start mt-4 sm:mt-0">
          <h3 className="text-base lg:text-xl text-center lg:text-start font-normal text-black leading-7">
            Profile
          </h3>
        </div>
        <form onSubmit={submithandler} className=" w-full lg:max-w-[558px] lg:ps-[70px] px-4">
          <div className="flex flex-col gap-1  lg:gap-3 lg:mt-[30px] mt-6">
            <label htmlFor="name" className="text-sm lg:text-base font-medium leading-5 text-black">
              Full name
            </label>
            <input
              id="name"
              name="name"
              required
              disabled={!editMode}
              onChange={onhandelchange}
              type="text"
              placeholder="Name"
              value={formData.name}
              className=" w-full font-normal text-sm h-[40px] lg:h-[48px] lg::text-base leading-6 border border-[#BEC1C3] px-5 py-3 outline-none rounded-[100px]"
            />
          </div>
          <div className="flex flex-col gap-1  lg:gap-3 lg:mt-[30px] mt-6">
            <label
              htmlFor="Username"
              className="text-sm lg:text-base font-medium leading-5 text-black">
              Username
            </label>
            <input
              id="Username"
              required
              disabled={!editMode}
              onChange={handleUserChange}
              type="text"
              name="username"
              placeholder="Username"
              value={formData.username}
              className="w-full font-normal text-sm h-[40px] lg:h-[48px] lg::text-base leading-6 border border-[#BEC1C3] px-5 outline-none rounded-[100px] mt-1  ps-[26px] py-[12px]"
            />
            {usernameChanged &&
              formData.username &&
              (editMode ? (
                isUsernameValid ? (
                  <p className="text-green-500 text-sm">Username is available.</p>
                ) : (
                  <p className="text-red-500 text-sm">Username is not available.</p>
                )
              ) : (
                ""
              ))}
          </div>
          <div className="lg:mt-[30px] mt-6">
            <h3 className="text-black font-medium leading-6 text-sm lg:text-lg lg:mt-[30px] mt-6">
              DOB
            </h3>
            <div>
              <div className="flex flex-col gap-1  lg:gap-3 lg:mt-3 mt-1">
                <div className="flex lg:gap-[30px] gap-2">
                  <div className="flex flex-col gap-1 w-1/3">
                    <label
                      htmlFor="date"
                      className="font-medium text-black text-xs md:text-sm xl:text-base capitalize">
                      Date
                    </label>
                    <div
                      className={` flex justify-center items-center w-full max-w-[103px] lg:max-w-[110px] font-normal text-sm h-[40px] lg:h-[48px] lg::text-base leading-6 border border-[#BEC1C3] px-2 outline-none rounded-[100px] ${getColorClass(
                        formData.day
                      )}`}>
                      <select
                        id="day"
                        disabled={!editMode}
                        className={`font-normal text-[14px] w-full smtext-xs:lg:text-base text-black outline-none bg-transparent uppercase  px-3 py-3  ${getColorClass(
                          formData.day
                        )}`}
                        name="day"
                        value={formData.day}
                        onChange={onhandelchange}
                        defaultValue="DD">
                        <option disabled value="">
                          dd
                        </option>
                        {[...Array(31)].map((_, i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 w-1/3">
                    <label
                      htmlFor="month"
                      className="font-medium text-black text-xs md:text-sm xl:text-base capitalize">
                      Month
                    </label>
                    <div
                      className={` flex justify-center items-center max-w-[103px] w-full lg:max-w-[150px] font-normal text-sm h-[40px] lg:h-[48px] lg::text-base leading-6 border border-[#BEC1C3] py-3 outline-none rounded-[100px] ${getColorClass(
                        formData.month
                      )}`}>
                      <select
                        id="month"
                        disabled={!editMode}
                        className={`font-normal text-[14px] lg:text-base w-full outline-none uppercase  px-4 py-3 ${getColorClass(
                          formData.month
                        )}`}
                        value={formData.month}
                        name="month"
                        onChange={onhandelchange}>
                        <option disabled value="">
                          mm
                        </option>
                        {[...Array(12)].map((_, i) => (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 w-1/3">
                    <label
                      htmlFor="year"
                      className="font-medium text-black text-xs md:text-sm xl:text-base capitalize">
                      Year
                    </label>
                    <div
                      className={` flex justify-center items-center max-w-[103px] w-full lg:max-w-[150px] font-normal text-sm h-[40px] lg:h-[48px] lg::text-base leading-6 border border-[#BEC1C3] py-3 outline-none rounded-[100px] ${getColorClass(
                        formData.year
                      )}`}>
                      <select
                        id="year"
                        disabled={!editMode}
                        className={`font-normal text-black  text-[14px] lg:text-base w-full outline-none uppercase px-4 py-3 ${getColorClass(
                          formData.year
                        )}`}
                        value={formData.year}
                        onChange={onhandelchange}
                        name="year"
                        defaultValue="YYYY">
                        <option disabled value="">
                          YYYY
                        </option>
                        {[...Array(100)].map((_, i) => {
                          const year = new Date().getFullYear() - i;
                          return (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1  lg:gap-3 lg:mt-[30px] mt-6">
            <label
              htmlFor="email"
              className="text-sm lg:text-base font-medium leading-5 text-black">
              Email address
            </label>
            <input
              id="email"
              required
              name="email"
              disabled={!editMode}
              onChange={handleemailchnage}
              type="email"
              placeholder="Email"
              value={formData.email}
              className=" w-full font-normal text-sm h-[40px] lg:h-[48px] lg::text-base leading-6 border border-[#BEC1C3] px-5 py-3 outline-none rounded-[100px]"
            />
          </div>
          <div className=" my-6 lg:my-[30px] text-center">
            <button className=" text-white bg-[#4D8AFF] rounded-[100px] font-normal lg:text-lg leading-6 py-[10px] px-[30px] lg:py-[18px] lg:px-[40px] text-[14px]">
              {editMode ? "Save" : " Edit"}
            </button>
          </div>
        </form>

        <div className=" bg-[#F8F8F8] py-1 lg:py-6 px-8">
          <h3 className="text-base lg:text-xl text-center lg:text-start font-normal text-black leading-7">
            Interests
          </h3>
        </div>
        <div className="flex flex-wrap justify-center gap-5 mt-6 overflow-y-auto bg-white px-4">
          {Array.isArray(interestData) && interestData.length !== 0 ? (
            interestData.map((interests, index) => {
              return (
                <div
                  key={index}
                  className={`text-[#4D8AFF] text-base max-sm:text-xs font-normal h-[37px] px-[18px] items-center justify-center border border-[#4D8AFF] rounded-md flex gap-2 bg-white`}>
                  <p>{interests}</p>
                </div>
              );
            })
          ) : (
            <p className="text-base lg:text-lg ">No interests found.</p>
          )}
        </div>
        <div
          onClick={() => setshowAddInterest(true)}
          className=" my-6 lg:my-[30px] text-center px-4">
          <button className=" text-white bg-[#4D8AFF] rounded-[100px] font-normal lg:text-lg leading-6 py-[10px] px-[30px] lg:py-[18px] lg:px-[40px] text-[14px]">
            Edit
          </button>
        </div>
        <div className=" bg-[#F8F8F8] py-1 lg:py-6 px-8 text-center lg:text-start">
          <h3 className="text-base lg:text-xl text-center lg:text-start font-normal text-black leading-7">
            Educational details
          </h3>
        </div>
        <div className="px-4 sm:px-[14px] lg:px-16 flex flex-col">
          {Array.isArray(educationDtls) &&
            educationDtls.map((items, index) => {
              return (
                <div
                  key={index}
                  className="w-full  bg-[#F7F7F7] py-3 px-9 lg:p-[60px] rounded-xl mx-auto  overflow-auto max-sm:text-xs mt-6 lg:mt-8">
                  <div className="flex items-center">
                    <div className="w-full flex flex-col gap-1">
                      <p className="text-xs lg:text-base text-black font-normal leading-6">
                        {items.universityName}
                      </p>
                      <p className="text-xs lg:text-base text-black font-normal leading-6">
                        {items.instituteName}
                      </p>
                      <p className="text-xs lg:text-base text-black font-normal leading-6">
                        {items.specializationStream}
                      </p>
                      <p className="text-xs lg:text-base text-black font-normal leading-6">
                        {items.fromDate}
                        {items.toDate !== null && items.toDate !== "" && " /"}{" "}
                        {items.toDate !== null && items.toDate !== "" && items.toDate}
                      </p>
                      {items.currentYearClass !== 0 && (
                        <p className="text-xs lg:text-base text-black font-normal leading-6">
                          {items.currentYearClass} Year, Semester {items.currentSemester}
                        </p>
                      )}
                    </div>
                    <div className="flex gap-6">
                      <button
                        className="w-[22px] h-[22px] lg:w-[33px] lg:h-[33px] "
                        onClick={() => {
                          setEducationalPopup(true);
                          setId(items.id);
                        }}
                        type="button">
                        <EditIcon />
                      </button>
                      <button
                        className="w-[22px] h-[22px] lg:w-[33px] lg:h-[33px] "
                        onClick={() => {
                          setDeletePopup(true);
                          setId(items.id);
                          setContent("");
                        }}
                        type="button">
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className=" my-6 lg:my-[30px] text-center">
          <button
            onClick={() => {
              setEducationalPopup(true);
              setId(undefined);
            }}
            className=" text-white bg-[#4D8AFF] rounded-[100px] font-normal lg:text-lg leading-6 py-[10px] px-[30px] lg:py-[18px] lg:px-[40px] text-[14px]">
            Add new
          </button>
        </div>
        <div className=" bg-[#F8F8F8] py-1 lg:py-6 px-8 text-center lg:text-start">
          <h3 className="text-base lg:text-xl text-center lg:text-start font-normal text-black leading-7">
            Professional details
          </h3>
        </div>
        <div className="px-4 sm:px-[14px] lg:px-16 flex flex-col">
          {Array.isArray(professionalDtls) &&
            professionalDtls.map((value, index) => {
              return (
                <div
                  key={index}
                  className="w-full bg-[#F7F7F7] py-3 px-9 lg:p-[60px] rounded-xl mx-auto  overflow-auto max-sm:text-xs max-md:mt-4 mt-6 lg:mt-8">
                  <div className="flex items-center">
                    <div className="w-full flex flex-col gap-1">
                      <div>
                        <p className="text-xs lg:text-base text-black font-normal leading-6">
                          {value.industry}
                        </p>
                        <p className="text-xs lg:text-base text-black font-normal leading-6">
                          {value.companyName}
                        </p>
                        <p className="text-xs lg:text-base text-black font-normal leading-6">
                          {value.designation}
                        </p>
                        <p className="text-xs lg:text-base text-black font-normal leading-6">
                          {value.Location}
                        </p>
                        <p className="text-xs lg:text-base text-black font-normal leading-6">
                          {value.fromDate}
                          {value.toDate !== null && value.toDate !== "" && " /"}{" "}
                          {value.toDate !== null && value.toDate !== "" && value.toDate}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-6">
                      <button
                        className=" w-[22px] h-[22px] lg:w-[33px] lg:h-[33px]"
                        type="button"
                        onClick={() => {
                          setProfessionalPopup(true);
                          setId(value.id);
                        }}>
                        <EditIcon />
                      </button>
                      <button
                        className=" w-[22px] h-[22px] lg:w-[33px] lg:h-[33px]"
                        type="button"
                        onClick={() => {
                          setDeletePopup(true);
                          setContent("professional");
                          setId(value.id);
                        }}>
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className=" my-6 lg:my-[30px] text-center">
          <button
            onClick={() => {
              setProfessionalPopup(true);
              setId(undefined);
            }}
            className=" text-white bg-[#4D8AFF] rounded-[100px] font-normal lg:text-lg leading-6 py-[10px] px-[30px] lg:py-[18px] lg:px-[40px] text-[14px]">
            Add new
          </button>
        </div>
      </div>
    </div>
  );
}
export default Accountsetting;
