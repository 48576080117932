import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import profileDummyImg from "../../assets/images/jpeg/dummyimage.jpg";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import pdfImage from "../../assets/images/png/pdficon.png";
const Spotlight = ({ data, index }) => {
  const [checkIndex, setCheckIndex] = useState(true);
  const [truncatedContent, setTruncatedContent] = useState("");
  const [pdf, setPdf] = useState(null);
  const [images, setImages] = useState([]);
  const [oneDrivePdf, setOneDrivePdf] = useState([]);
  const [pageRendering, setPageRendering] = useState(false);
  const truncateText = (text, length) => {
    return text && text.length > length ? text.substring(0, length) + "..." : text;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Get the components of the date
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const month = date.getMonth(); // getMonth returns 0-indexed month (0 = January, 11 = December)
    const year = date.getFullYear();

    // Format the time in 12-hour format with am/pm
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesFormatted = minutes < 10 ? "0" + minutes : minutes;

    // Format the date
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const formattedDate = `${hours}:${minutesFormatted} ${ampm} | ${day} ${months[month]} ${year
      .toString()
      .slice(-2)}`;

    return formattedDate;
  }

  let contentMaxLength;
  let titleMaxLength;
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (data.type !== "WRITE_UPS") {
        if (screenWidth >= 1288) {
          contentMaxLength = 120;
          titleMaxLength = 20;
        } else if (screenWidth < 1288 && screenWidth >= 1024) {
          contentMaxLength = 90;
          titleMaxLength = 9;
        } else if (screenWidth < 1024 && screenWidth >= 644) {
          contentMaxLength = 30;
          titleMaxLength = 5;
        } else if (screenWidth < 480 && screenWidth >= 0) {
          contentMaxLength = 30;
          titleMaxLength = 5;
        } else {
          contentMaxLength = 45;
          titleMaxLength = 5;
        }
        if (screenWidth >= 644) {
          if (index === 3) {
            setCheckIndex(false);
          }
        }
      } else {
        if (screenWidth >= 1536) {
          contentMaxLength = 750;
          titleMaxLength = 50;
        } else if (screenWidth <= 1535 && screenWidth >= 1280) {
          contentMaxLength = 570;
          titleMaxLength = 25;
        } else if (screenWidth <= 1270 && screenWidth >= 1024) {
          contentMaxLength = 450;
          titleMaxLength = 25;
        } else if (screenWidth < 1024 && screenWidth >= 768) {
          contentMaxLength = 200;
          titleMaxLength = 15;
        } else if (screenWidth < 768 && screenWidth >= 640) {
          contentMaxLength = 170;
          titleMaxLength = 15;
        } else {
          contentMaxLength = 200;
          titleMaxLength = 15;
        }
        if (screenWidth >= 644) {
          if (index === 3) {
            setCheckIndex(false);
          }
        }
      }

      setTruncatedContent(truncateText(data.description, contentMaxLength));
      // setTruncatedTitle(truncateText(data.title, titleMaxLength));
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data.content, truncateText, contentMaxLength, titleMaxLength]);
  const [fileType, setfileType] = useState("");

  async function loadPdf(pdfLink) {
    try {
      setPageRendering(true);
      const _PDF_DOC = await pdfjsLib.getDocument({ url: pdfLink }).promise;
      setPdf(_PDF_DOC);
      setPageRendering(false);
    } catch (error) {
      console.error("Error loading PDF: ", error.message);
    }
  }
  async function renderPage() {
    if (!pdf) return;
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      await page.render({ canvasContext: context, viewport }).promise;
      imagesList.push(canvas.toDataURL("image/png"));
    }
    setImages(imagesList);
    setPageRendering(false);
  }

  useEffect(() => {
    if (data?.awsUrl?.length > 0) {
      const fetchFileFromUrl = async (url) => {
        const fileName = url.split("/").pop().split("?")[0].split("_")[0];
        setfileType(fileName.includes(".pdf") ? "pdf" : "");
        if (fileName.includes(".pdf")) {
          loadPdf(data?.awsUrl[0]);
        }
      };
      fetchFileFromUrl(data.awsUrl[0]);
    }
  }, [data?.awsUrl]);

  useEffect(() => {
    renderPage();
  }, [pdf]);

  if (checkIndex) {
    return (
      <Link
        target={data.url ? "_blank" : ""}
        to={data.user ? `/home/PostAsViewByUser/${data.id}` : `/showpdf2/${data.id}`}
        className="max-sm:rounded-[6px] rounded-[15px] border border-grey card_shadow lg:h-[580px] sm:h-[370px] shadow-[0_8px_20px_0_#2F327D1A] h-[265px] flex flex-col justify-between overflow-hidden">
        <div>
          {data?.url && (
            <div className="lg:h-[340px] sm:h-[216px] h-[155px] w-full overflow-hidden relative pt-5">
              <img
                className="w-full h-full rounded-[15px] max-sm:rounded-[6px]  object-contain object-top"
                src={pdfImage}
                alt="pdfImage"
              />
            </div>
          )}
          {data.awsUrl && data.awsUrl.length > 0 && (
            <div className="lg:h-[340px] sm:h-[216px] h-[155px] w-full overflow-hidden relative">
              <img
                src={fileType === "pdf" ? images[0] : data.awsUrl[0]}
                alt="images"
                className="w-full h-full rounded-[15px] max-sm:rounded-[6px]  object-cover object-top"
              />
              {images.length > 1 && (
                <span className="bg-[#ededed] p-1 px-2 md:p-2 absolute right-2 sm:right-[19px] bottom-2 sm:bottom-[17px] rounded-[40px] max-md:text-sm">
                  {images.length} Pages
                </span>
              )}
              {data?.awsUrl.length > 1 && (
                <span className="bg-[#fafafa] p-1 px-2 md:p-2 absolute right-2 sm:right-[19px] bottom-2 sm:bottom-[17px] rounded-[40px] max-md:text-sm">
                  {data?.awsUrl.length} Pages
                </span>
              )}
            </div>
          )}

          {data.description && (
            <div className="lg:pt-3 lg:pb-3 lg:px-7 sm:px-3 px-2 lg:mx-3 sm:mx-1  flex flex-col justify-between">
              <p
                className="font-normal sm:text-base text-xs text-black sm:pb-3 lg:pb-6 pb-[10px] sm:pt-4 pt-2"
                dangerouslySetInnerHTML={{ __html: truncatedContent }}></p>
            </div>
          )}
        </div>
        {data.user ? (
          <div className="flex items-center pb-4 lg:px-7 sm:px-3 px-2 lg:mx-3 sm:mx-1">
            <img
              src={data?.user.profileImageUrl || profileDummyImg}
              alt="recommendations"
              className="max-sm:mr-1 mr-3 max-md:min-w-[36px] max-md:max-w-[36px] max-md:h-[36px] min-w-[48px] max-w-[48px] h-[48px] rounded-[50%]"
            />
            <div>
              <p className="font-bold lg:text-lg md:text-sm text-xs text-black">
                {data?.user?.fullName}
              </p>
              <p className="lg:text-base md:text-xs text-xxs text-grey text-nowrap">
                {formatDate(data.createdAt)}
              </p>
            </div>
          </div>
        ) : (
          <p className="pb-4 lg:px-7 sm:px-3 px-2 lg:mx-3 sm:mx-1 font-bold lg:text-lg md:text-sm text-xs text-black">
            {data.name.split(".")[0]}
          </p>
        )}
      </Link>
    );
  }
};

export default Spotlight;
