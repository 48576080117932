import React, { useEffect, useState } from "react";
import { BackArrowIcon, CloseIcon, Location } from "../common/Icons";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import { useParams } from "react-router-dom";
const AboutPopup = ({ setAboutPopup, userName }) => {
  const {
    educationDtls,
    professionalDtls,
    getProfessionalByUsernameDetails,
    getEducationByUsernameDetails,
  } = UseProfileContext();
  const { username } = useParams();
  const [educationData, setEducationData] = useState([]);
  const [professionalData, setProfessionalData] = useState([]);
  async function educations() {
    let userEducation = await getEducationByUsernameDetails(userName);
    let userProfessional = await getProfessionalByUsernameDetails(userName);
    setEducationData(userEducation);
    setProfessionalData(userProfessional);
  }
  useEffect(() => {
    if (username !== undefined) {
      educations();
    } else {
      setEducationData([...educationDtls]);
      setProfessionalData([...professionalDtls]);
    }
  }, [username]);
  return (
    // <div className="w-full fixed top-0 left-0 h-screen z-50 backdrop-blur-[10px]">
    <div className="sm:max-w-[540px] fixed top-0 left-0 max-sm:h-screen sm:top-1/2 sm:left-1/2 sm:-translate-y-1/2 sm:-translate-x-1/2 bg-[white] w-full z-50 pl-[46px] sm:pl-[106px] sm:rounded-[40px] pb-6 sm:pb-[48px] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] flex flex-col">
      <div className="sm:p-[47px_8px_0px_0px]">
        <div className="flex sm:justify-between items-center py-[10px] max-sm:gap-4 max-sm:-ml-5">
          <button onClick={() => setAboutPopup(false)} className="sm:hidden">
            <BackArrowIcon />
          </button>
          <p className="text-lg font-medium text-center text-black"> About</p>
          <button onClick={() => setAboutPopup(false)} className="mr-11 hidden sm:block">
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className="sm:p-[0px_8px_0px_0px] flex-grow h-full overflow-y-auto">
        <div className="xl:max-h-[450px] 2xl:max-h-[500px]  h-full sm:max-h-[360px] overflow-y-auto">
          <p className="text-base font-medium text-black mt-6  ">Experience</p>
          <ol
            className={`mt-[22px] relative after:absolute after:h-[94%] after:w-[1px] ${
              professionalData.length > 1 ? "after:bg-[#BEC1C3]" : ""
            } after:top-[8px] after:left-[4px]`}>
            {professionalData.map((items) => {
              return (
                <div className="mt-4 relative">
                  <div className=" w-[9px] h-[9px] bg-[#BEC1C3] rounded-[50%] absolute left-[0px] top-[8px]"></div>
                  <div className="ms-4">
                    <p className="text-base font-medium text-black ">{items.designation}</p>
                    <p className="text-xs font-medium text-black my-2">{items.industry}</p>
                    <div className="flex gap-2">
                      <p className="text-[10px] font-normal text-black  ">
                        {items.fromDate.split("-")[2]}-
                        {items.toDate !== null ? items.toDate.split("-")[2] : "Present"}
                      </p>
                      <div className="flex ">
                        <Location />
                        <p className="text-[10px] font-normal text-black ms-0.5 ">
                          {items.location}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </ol>

          <p className="text-base font-medium text-black mt-6  ">Education</p>
          <div
            className={`mt-[22px] relative after:absolute after:h-[94%] after:w-[1px] ${
              educationData.length > 1 ? "after:bg-[#BEC1C3]" : ""
            } after:top-[8px] after:left-[4px]`}>
            {educationData.map((items) => {
              return (
                <div className="relative">
                  <div className=" w-[9px] h-[9px] bg-[#BEC1C3] rounded-[50%] absolute left-[0px] top-[8px] "></div>
                  <div className="ms-4 mt-4">
                    <p className="text-base font-medium text-black ">{items.universityName}</p>
                    <p className="text-xs font-normal text-black mt-1">{items.instituteName}</p>
                    <p className="text-[10px] font-normal text-black my-1 ">
                      {items.specializationStream}
                    </p>
                    <div className="flex gap-6">
                      <p className="text-[10px] font-normal text-black  ">
                        {items?.fromDate?.split("-")[2]}-
                        {items?.toDate !== null ? items?.toDate?.split("-")[2] : "Present"}
                      </p>
                      <div className="flex ">
                        <Location />
                        <p className="text-[10px] font-normal text-black  ms-0.5">
                          {items.location}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default AboutPopup;
