import React, { useState } from "react";
import Spotlight from "../../components/Home/Spotlight";
import { useNavigate } from "react-router-dom";
import { BackArrowIcon } from "../common/Icons";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";

const NotesPage = () => {
  const { notesData } = UseCreatePostData();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9; // Display 9 items per page
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  // Calculate the total number of pages
  const totalPages = Math.ceil(notesData.length / itemsPerPage);

  // Get the data for the current page
  const currentItems = notesData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  function goBack() {
    navigate(-1);
  }

  // Handle previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      scrollToTop();
    }
  };

  // Handle next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
      scrollToTop();
    }
  };

  return (
    <div className="px-4">
      <div>
        <div className="flex justify-start ps-3 sm:ps-0 sm:justify-center items-center gap-4 my-4 sm:my-11">
          <button className="cursor-pointer" onClick={goBack}>
            <BackArrowIcon />
          </button>
          <p className="text-black font-semibold sm:text-lg text-base">Notes</p>
        </div>
        <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 gap-y-[40px] max-sm:gap-y-4">
          {currentItems.map((item, index) => (
            <div key={index}>
              <Spotlight data={item} />
            </div>
          ))}
        </div>
        {/* Pagination Controls */}
        <div className="flex justify-center items-center gap-4 mt-6 md:mb-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="px-4 py-1 md:py-2 bg-[#4d8aff] text-white rounded disabled:opacity-50 max-md:text-sm">
            Prev
          </button>
          <p className="max-md:text-sm">
            Page {currentPage} of {totalPages}
          </p>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-1 md:py-2 bg-[#4d8aff] text-white rounded  disabled:opacity-50 max-md:text-sm">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotesPage;
