export function getToken() {
  let localTokenStore = JSON.parse(localStorage.getItem("tokens"));
  let sessionTokenStore = JSON.parse(sessionStorage.getItem("tokens"));
  let newToken = localTokenStore !== null ? localTokenStore : sessionTokenStore;
  return newToken;
}
export function getTokenTime() {
  let time = JSON.parse(localStorage.getItem("tokensExpireTime"));
  return time;
}
