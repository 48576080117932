import React from "react";

const ImagePreviewModal = ({ show, onClose, imgSrc }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-[100]">
      <div className="relative ">
        <div className="">
          <img
            src={imgSrc}
            alt="Preview"
            className="rounded-xl object-cover sm:max-w-[400px] sm:min-w-[400px] w-[250px] max-h-[500px]"
          />
          <button
            className="absolute top-0 right-0 px-2 py-1 text-white bg-black rounded-md"
            onClick={() => onClose(false)}
          >
            X
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImagePreviewModal;
