import React, { useState } from "react";
import { CrossIcon } from "../common/Icons";
import eye from "../../assets/images/svg/eye.svg";
import open_eye from "../../assets/images/svg/open-eye.svg";
import { UseAuth } from "../ContextApis/AuthContext";

const NewPasswordPopup = ({ setNewPassword, email }) => {
  const [error, setError] = useState(false);
  const [showhidepassword, setShowhidepassword] = useState({
    password: false,
    conformpassword: false,
  });
  const [newPasswords, setNewPasswords] = useState({
    pass: "",
    confrPass: "",
  });
  const { updateForgotPassword } = UseAuth();
  function handleChangePassword(e) {
    const { name, value } = e.target;
    setNewPasswords((prev) => ({ ...prev, [name]: value }));
    setError(false);
  }
  // onClick={() => {
  //   if (
  //     newPasswords.pass === newPasswords.confrPass &&
  //     newPasswords.pass !== "" &&
  //     newPasswords.confrPass !== ""
  //   ) {
  //     setNewPassword(false);
  //   } else {
  //     setError(true);
  //   }
  // }}
  function hnadleSubmit() {
    if (
      !/[A-Z]/.test(newPasswords.pass) ||
      !/[a-z]/.test(newPasswords.pass) ||
      !/[0-9]/.test(newPasswords.pass) ||
      !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(newPasswords.pass) ||
      !newPasswords.pass.length >= 8
    ) {
      setError("Password should contain one [A-Z],[a-z],[0-9],[@#$&*] and minimum 8 characters");
    } else if (newPasswords.pass !== newPasswords.confrPass) {
      setError("Password and Confirm Password does not match");
    }
    if (
      /[A-Z]/.test(newPasswords.pass) &&
      /[a-z]/.test(newPasswords.pass) &&
      /[0-9]/.test(newPasswords.pass) &&
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(newPasswords.pass) &&
      newPasswords.pass.length >= 8 &&
      newPasswords.pass === newPasswords.confrPass
    ) {
      updateForgotPassword(email, newPasswords.pass, newPasswords.confrPass, setNewPassword);
    }
  }
  return (
    <div>
      <div className="sm:max-w-[540px] w-[90%] bg-white p-[16px] sm:p-[22px] lg:p-[30px] rounded-[18px] md:rounded-[40px] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[60] flex flex-col items-start shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] Forgot_Password">
        <img src="" alt="" />

        <div className="flex w-full justify-between  items-center">
          <p className="text-base font-semibold text-[#000000] ">Create new password</p>
          <button onClick={() => setNewPassword(false)} className="cursor-pointer">
            <CrossIcon />
          </button>
        </div>

        <p className="text-sm sm:text-base text-medium  w-full mt-5 ">
          Please enter your new password for your acccont security{" "}
        </p>
        <p className="text-sm sm:text-base text-medium  w-full mt-5 ">New password</p>
        <div className="flex bg-[#F8F8F8] mx-auto w-full  mt-3 xl:mt-5 h-[56px] ps-8 justify-between pe-5 py-3 rounded-[100px] items-center border border-[#BEC1C3]">
          <input
            required
            name="pass"
            value={newPasswords.pass}
            onChange={handleChangePassword}
            type={showhidepassword.password ? "text" : "password"}
            placeholder="Confirm your password"
            className="outline-none bg-transparent w-full pe-2 max-sm:text-[14px]"
          />
          <button
            type="button"
            onClick={() =>
              setShowhidepassword({
                ...showhidepassword,
                password: showhidepassword.password ? false : true,
              })
            }>
            {!showhidepassword.password ? (
              <img width={"21px"} src={eye} alt="eye" />
            ) : (
              <img width={"21px"} src={open_eye} alt="eye" />
            )}
          </button>
        </div>
        <p className="text-sm sm:text-base text-medium  w-full mt-5 ">Confirm password</p>
        <div className="flex bg-[#F8F8F8] w-full mx-auto mt-3 xl:mt-5 h-[56px] ps-8 justify-between pe-5 py-3 rounded-[100px] items-center border border-[#BEC1C3]">
          <input
            required
            name="confrPass"
            value={newPasswords.confrPass}
            onChange={handleChangePassword}
            type={showhidepassword.conformpassword ? "text" : "password"}
            placeholder="Confirm your password"
            className="outline-none bg-transparent w-full pe-2 max-sm:text-[14px]"
          />
          <button
            type="button"
            onClick={() =>
              setShowhidepassword({
                ...showhidepassword,
                conformpassword: showhidepassword.conformpassword ? false : true,
              })
            }>
            {!showhidepassword.conformpassword ? (
              <img width={"21px"} src={eye} alt="eye" />
            ) : (
              <img width={"21px"} src={open_eye} alt="eye" />
            )}
          </button>
        </div>
        {error && <p className="max-sm:text-sm text-center text-[red] w-full pt-2">{error}</p>}
        <button
          onClick={hnadleSubmit}
          type="button"
          className="max-w-[330px] bg-primary rounded-[100px] w-[150px] h-[56px] text-[14px] sm:text-base text-white m-auto mt-5">
          Save
        </button>
      </div>
    </div>
  );
};

export default NewPasswordPopup;
