import { NavLink, useLocation } from "react-router-dom";
import { BackArrowIcon, RightArrowIcon } from "../common/Icons";

const sidebarData = [
  {
    tabs: "accounts",
    path: "accounts-settings",
  },

  {
    tabs: "Privacy and security",
    path: "privacy-security",
  },
  {
    tabs: "Notifications",
    path: "notifications-settings",
  },
  {
    tabs: "Help",
    path: "helps",
  },
  {
    tabs: "Logout",
    path: "logout",
  },
];

const SideBar = ({ onSidebarClick, selectedPath, isMobile }) => {
  const location = useLocation();
  const handleClick = (path) => {
    if (typeof onSidebarClick === "function") {
      onSidebarClick(path);
    }
  };

  const isActive = (path) => {
    return location.pathname.includes(`/settings/${path}`);
  };
  return (
    <div className="mt-3 sm:mt-10 lg:mt-[88px] px-6 md:px-5 xl:ps-7 flex flex-col gap-[26px] sm:gap-8">
      <div className="flex items-center gap-1.5 sm:hidden mb-2.5">
        <BackArrowIcon />
        <h2 className="font-medium text-base text-black capitalize">
          settings
        </h2>
      </div>

      {sidebarData.map((tab, index) => {
        return (
          <div key={index}>
            <NavLink
              to={`${tab.path}`}
              onClick={() => handleClick(`${tab.path}`)}
              className={`font-normal text-sm md:text-base leading-6 duration-500 sm:py-2 md:py-2 lg:py-[18px] 
            sm:px-6 lg:px-8 xl:px-10 rounded-[100px] text-nowrap capitalize flex sm:inline-block items-center justify-between ${
              isActive(tab.path)
                ? "sm:bg-primary sm:text-white"
                : "bg-transparent text-tertiary"
            }`}
            >
              {tab.tabs}
              <span className="sm:hidden">
                <RightArrowIcon />
              </span>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};

export default SideBar;
