import React from "react";

const SeeAllButton = ({name}) => {
  return (
    <>
     <div className="inline-block">
     <div className="h-[40px]  justify-center items-center bg-primary flex rounded-[100px] ">
      <button
        className=" text-white font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center bg-primary rounded-[100px] mx-auto sm:px-7 px-6">
            {name}
      </button>
      </div>
     </div>
    </>
  );
};
export default SeeAllButton;
