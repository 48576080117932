import React, { useRef } from "react";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import { BackArrowIcon, CloseIcon } from "../common/Icons";
import { UseAuth } from "../ContextApis/AuthContext";

function Verifyemail({ setPersonlisePopup, signInRef, setVerifyOtpPopup, setSignUpPopup, email }) {
  // const [error, setError] = useState(null);
  const inputRefs = useRef([]);
  const { verifyOtp, generateOtp } = UseAuth();

  function handlesubmit(e) {
    e.preventDefault();
    let hasError = false;
    const otpValue = inputRefs.current.map((input) => input.value).join("");

    inputRefs.current.forEach((input) => {
      if (!input.value) {
        hasError = true;
      }
    });

    // setError(hasError);
    let email = "register";
    if (!hasError) {
      verifyOtp(otpValue, setVerifyOtpPopup, setPersonlisePopup, email);
    }
  }

  const handleInputChange = (index, event) => {
    const { value } = event.target;

    if (value.length === 1 && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      if (inputRefs.current[index].value === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        inputRefs.current[index].value = "";
      }
    }
  };

  function handleResendOtp() {
    generateOtp(email, setVerifyOtpPopup, setSignUpPopup);
  }

  return (
    <>
      <div
        ref={signInRef}
        className="max-md:h-screen md:w-[540px] w-full bg-white popup_shadow md:rounded-[40px] py-5 sm:py-9 max-sm:px-[10px] fixed top-0 start-0 md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 z-[50]">
        <div className="flex items-center md:justify-between max-sm:px-4 px-5 pb-5 md:pb-0 max-md:fixed max-md:w-full max-md:left-0">
          <button className="cursor-pointer" onClick={() => setVerifyOtpPopup(false)}>
            <BackArrowIcon />
          </button>
          <p className="font-semibold text-base ms-3 md:ms-0">Verify your mail</p>
          <div onClick={() => setVerifyOtpPopup(false)} className="cursor-pointer hidden md:block">
            <CloseIcon />
          </div>
        </div>
        {/* {error !== null ? ( */}
        <p className="text-center font-medium text-md max-md:text-xs mt-20 md:mt-4">
          Please enter the 6 digit code sent on your mail.
        </p>
        {/* // ) : ( */}
        {/* //   <p className="text-center font-medium text-[#FF0000] text-md max-md:text-xs mt-20 md:mt-4">
        //     Incorrect code. Please try again.
        //   </p> */}
        {/* )} */}
        <form onSubmit={handlesubmit}>
          <div className="flex justify-center mt-3 md:mt-6 space-x-4">
            {[0, 1, 2, 3, 4, 5].map((index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                autoFocus={index === 0}
                className="border-b-2 border-black w-[35px] sm:w-12 h-12 text-center text-xl max-md:text-base outline-none"
                ref={(el) => (inputRefs.current[index] = el)}
                onChange={(e) => handleInputChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
              />
            ))}
          </div>
          <div className="text-center">
            <button
              onClick={handleResendOtp}
              type="button"
              className="text-md max-md:text-xs font-medium text-center text-[#4D8AFF] mt-12 cursor-pointer">
              Resend code
            </button>
          </div>
          <div className="text-center">
            <button className="md:mt-4 mt-[52px] px-1 md:px-0 w-full max-sm:text-xs">
              <Button btnname="Verify" />
            </button>
          </div>
        </form>
        <p className="font-normal text-[12px] text-[#BEC1C3] text-center mt-9 md:mt-5">
          By continuing you’re indicating that you accept our{" "}
        </p>
        <p className="font-normal text-[12px] text-[#BEC1C3] text-center">
          <Link className="font-semibold text-primary" to="/terms">
            Terms of use
          </Link>
          and
          <Link className="font-semibold text-primary" to="/privacypolicy">
            {" "}
            Privacy Policy
          </Link>
        </p>
      </div>
    </>
  );
}

export default Verifyemail;
