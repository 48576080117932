import React from "react";
import Pic from "../../assets/images/png/Esther.png";
import { writeups } from "../../Store/HomeData";
import { Link, useNavigate } from "react-router-dom";
import { BackArrowIcon } from "../common/Icons";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
function WriteUps() {
  const { writesupsData } = UseCreatePostData();
  const navigate = useNavigate();
  function goback() {
    navigate(-1);
  }
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options).replace(",", "");
  };
  return (
    <div>
      <div className=" flex justify-start ps-3 sm:ps-0 sm:justify-center items-center gap-4 my-6  sm:my-11">
        <button onClick={goback}>
          <BackArrowIcon />
        </button>
        <p className=" text-black font-semibold sm:text-lg text-base ">Write-Ups</p>
      </div>
      <div className="container mx-auto px-4">
        <div className=" flex items-center flex-wrap gap-4">
          {Array.isArray(writesupsData)&&
            writesupsData.map((value, index) => {
              return (
                <Link
                  to="/home/PostAsViewByUser"
                  key={index}
                  className="border broder-[#BEC1C3] sm:py-10 py-2 px-2 sm:px-[33px] write_ups_card rounded-[6px] w-[47%] sm:w-full">
                  <div className=" text-end sm:text-start">
                    <span className="text-base max-md:text-xs font-normal leading-6  bg-[#F8F8F8] min-w-[200px] text-center py-1 rounded-[10px] px-6">
                      # Tips and tricks
                    </span>
                  </div>
                  <div>
                    {/* <div
                      className="text-base max-md:text-xs mt-[10px] h-[128px] overflow-hidden sm:h-auto text-ellipsis"
                      dangerouslySetInnerHTML={{ __html: value.description }}
                    /> */}
                    {/* <p>{value.description}</p> */}
                  </div>
                  <div className="flex items-center mt-[15px]">
                    {/* <img
                      className="max-md:min-w-[36px] max-md:h-[36px]"
                      src={value.user.profileImageUrl ? value.user?.profileImageUrl : Pic}
                      alt=""
                    /> */}
                    <div className="ms-[10px]">
                      {/* <p className="text-xs sm:text-md text-black font-semibold sm:font-bold max-md:text-xs">
                        {value.user?.fullName}
                      </p> */}
                      {/* <p className="text-base max-md:text-xxs font-normal text-[#BEC1C3]">
                        {formatDate(value.updatedAt)}
                      </p> */}
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default WriteUps;
