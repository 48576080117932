import React, { useEffect, useRef, useState } from "react";
import { SearchIcon2, BackArrowIcon, CloseIcon, Vertical_edit_btn } from "../common/Icons";
import bansal_sndp from "../../assets/images/jpeg/dummyimage.jpg";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import { UseTopCreatorsContext } from "../ContextApis/TopCreatorsAndFollow";
import success_giff from "../../assets/gif/success.gif";
import { toast } from "react-toastify";
import showToast from "../ToastSucess";
import { useLocation } from "react-router-dom";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
function Following({ setFollowingPopup, onSendData, setIsblock, isBlock, setUserData, userData }) {
  const { followingData, getFollowingData, blockUser, reportUser } = UseProfileContext();
  const { unFollowUser, followUser } = UseTopCreatorsContext();
  const [searchData, setSearchData] = useState("");
  const [showThreeDots, setShowThreeDots] = useState(false);
  const [block, setBlock] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [id, setId] = useState(false);
  const [updatedFollowingsData, setUpdatedFollowingsData] = useState(followingData);
  const [report, setReport] = useState(false);
  const [reported, setReported] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState("");
  const [reason, setReason] = useState("");
  const popupRef = useRef();
  const location = useLocation();
  const [followedUserID, setfollowedUserID] = useState("");
  const { checkIsFollow } = UseCreatePostData();
  // Function to handle unfollowing
  const handleUnfollowClick = async (username) => {
    try {
      await unFollowUser(username);
      setUpdatedFollowingsData(updatedFollowingsData.filter((item) => item.userName !== username));
      let changeData = { ...userData, followingCount: userData.followingCount - 1 };
      setUserData(changeData);
    } catch (error) {
      console.error("Failed to unfollow user:", error);
    }
  };
  async function handleFollow(username) {
    followUser(username);
  }

  async function filterfollowuser() {
    let id = [];
    if (Array.isArray(followingData)) {
      for (let filter of followingData) {
        let message = await checkIsFollow(filter?.userName);
        if (message === "following" && !id.includes(filter.id)) {
          id.push(filter.id);
        }
      }
      setfollowedUserID(id);
    }
  }
  useEffect(() => {
    if (Array.isArray(followingData) && followingData.length > 0) {
      filterfollowuser();
    }
  }, [Array.isArray(followingData) && followingData.length > 0]);

  function followUnfollow(id) {
    if (followedUserID.includes(id)) {
      let ids = followedUserID.filter((item) => item !== id);
      setfollowedUserID(ids);
    } else {
      setfollowedUserID([...followedUserID, id]);
    }
  }

  const handleReportSubmit = async () => {
    const reportData = {
      reportedUserName: followingData[id] ? followingData[id].userName : "",
      reportReason: "SPAM",
      reportDescription: reason,
    };
    try {
      await reportUser(reportData);
      setReported(true);
      setReport(false);
      getFollowingData();
    } catch (error) {
      console.error("Failed to report user:", error);
    }
  };
  function handleClick(e) {
    e.preventDefault();
    handleReportSubmit();
  }
  const filteredData = Array.isArray(updatedFollowingsData)
    ? updatedFollowingsData.filter((item) =>
        item.fullName.toLowerCase().includes(searchData.toLowerCase())
      )
    : [];
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowThreeDots(false);
        setBlock(false);
        setBlocked(false);
        setReport(false);
      }
    }

    if (showThreeDots || block || blocked || report || reported) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showThreeDots, block, blocked, , report, reported]);
  const handleBlockUser = async () => {
    let user = followingData[id].userName;
    if (followingData && user) {
      await blockUser(user);
      setUpdatedFollowingsData(updatedFollowingsData.filter((item) => item.userName !== user));
      let changeData = { ...userData, followingCount: userData.followingCount - 1 };
      setUserData(changeData);
      setBlocked(true);
      setBlock(false);
    }
  };
  return (
    <>
      {(showThreeDots || block || blocked || report || reported) && (
        <div className="backdrop-blur-  [10px] z-[61] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
      )}
      {block && (
        <div
          ref={popupRef}
          className="max-w-[320px] sm:max-w-[444px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] sm: py-[22px] sm:px-[42px] pb-[11px] pt-[38px] px-4 text-center w-full overflow-hidden z-[61] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] flex-col">
          <p className=" font-medium sm:text-base text-sm text-center  ">
            Block {followingData.userName} ?
          </p>
          <p className="xl:text-base font-normal text-sm sm:my-6 my-4 text-center">
            They won't be able to find your profile and posts on Wised. We won't let them know that
            you've blocked them.
          </p>
          <div className="flex justify-center pb-6">
            <button className="inline-block me-[25px]">
              <div className="h-[40px]  justify-center items-center bg-primary flex rounded-[100px] ">
                <button
                  onClick={handleBlockUser}
                  className=" text-white font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-primary rounded-[100px] mx-auto sm:px-7 px-6">
                  Block
                </button>
              </div>
            </button>
            <button className="inline-block">
              <div className="h-[40px]  justify-center items-center bg-white flex rounded-[100px] ">
                <button
                  className=" text-black font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-white rounded-[100px] mx-auto sm:px-7 px-6 border-grey border"
                  onClick={() => setBlock(false)}>
                  Cancel
                </button>
              </div>
            </button>
          </div>
          <div
            className="absolute right-[20px] top-[15px] cursor-pointer"
            onClick={() => setBlock(false)}>
            <CloseIcon />
          </div>
        </div>
      )}
      {blocked && (
        <div
          ref={popupRef}
          className="max-w-[320px] sm:max-w-[444px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center w-full overflow-hidden z-[61] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] flex-col px-9 py-6 ">
          <p className=" font-medium sm:text-base text-sm text-center ">
            Blocked {filteredData.userName}
          </p>
          <p className="xl:text-base font-normal text-sm  text-center pt-[11px]">
            You can unblock them from their profile or your settings.
          </p>
          <div
            className="absolute right-[20px] top-[15px] cursor-pointer"
            onClick={() => setBlocked(false)}>
            <CloseIcon />
          </div>
        </div>
      )}
      <div className="max-sm:h-full sm:max-w-[523px] md:max-w-[620px] sm:rounded-[40px] w-full bg-white pb-[49px] sm:pb-0 fixed top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2 z-[60] shadow">
        <div className="flex items-center justify-start sm:justify-between sm:px-[33px] mt-4 sm:mt-0 ms-4 sm:pt-[49px] max-sm:gap-4">
          <div
            onClick={() => setFollowingPopup(false)}
            className="cursor-pointer h-[14px] w-[16px]">
            <BackArrowIcon />
          </div>
          <p className="text-center text-sm  sm:text-base lg:text-xl font-semibold text-[black]  mb-[18px]">
            {userData.followingCount} Following
          </p>
          <div onClick={() => setFollowingPopup(false)} className="hidden sm:block me-4">
            <CloseIcon />
          </div>
        </div>
        <div className="text-center flex justify-center items-center mt-[18px] sm:mt-6 md:mt-[39px] px-4 sm:px-[44px] pb-3 bg-white">
          <div className="flex justify-between bg-[#EEEFEF] w-[641px] items-center rounded-[40px] px-4 pe-10">
            <input
              type="text"
              placeholder="search"
              className="border-none outline-none bg-transparent md:h-[58px] w-full px-2 text-base h-[40px] "
              onChange={(e) => setSearchData(e.target.value)}
            />
            <SearchIcon2 />
          </div>
        </div>
        <div className="sm:h-[479px] sm:overflow-auto sm:me-3 sm:ps-[43px] sm:pe-[11px] sm:mb-[37px] sm:px-[16px] px-4 pe-10">
          <div>
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <div
                  className="flex items-center justify-between sm:mt-50 mt-[18px] md:mt-10"
                  key={item.userName}>
                  <div className="flex items-center gap-2 md:gap-4">
                    <div className="rounded-full">
                      <img
                        className="h-[42px] min-w-[42px] rounded-full max-w-[42px] lg:h-[50px] lg:min-w-[50px] lg:max-w-[50px]"
                        src={item.profileImageUrl || bansal_sndp}
                        alt="profile-img"
                      />
                    </div>
                    <div>
                      <p className="font-bold lg:text-lg text-base">{item.fullName}</p>
                      <p className="font-normal text-sm lg:text-[18px]">{item.designation}</p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    {location.pathname.startsWith("/profile") ? (
                      <button
                        className="border md:px-[30px] md:py-2 lg:px-[44px] lg:py-[18px] p-2 rounded-full text-sm lg:text-base text-[black] bg-[white]"
                        onClick={() => {
                          handleUnfollowClick(item.userName);
                          onSendData(updatedFollowingsData ? updatedFollowingsData.length : 0);
                        }}>
                        Unfollow
                      </button>
                    ) : (
                      <button
                        className={`border md:px-[30px] md:py-2 lg:px-[44px] lg:py-[18px] p-2 rounded-full text-sm lg:text-base text-[black] ${
                          !followedUserID.includes(item.id)
                            ? "bg-[#4D8AFF] text-white"
                            : "text-[black] bg-[white]"
                        }`}
                        onClick={() => {
                          if (!followedUserID.includes(item.id)) {
                            handleFollow(item.userName);
                          } else {
                            unFollowUser(item.userName);
                          }
                          followUnfollow(item.id);
                        }}>
                        {!followedUserID.includes(item.id) ? "Follow" : "Unfollow"}
                      </button>
                    )}
                    <div
                      onClick={() => {
                        setShowThreeDots(true);
                        setId(index);
                      }}
                      className="ms-[33px] sm:me-[44px] cursor-pointer three_dot_follow">
                      <Vertical_edit_btn />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                {" "}
                <div className="text-center text-gray-500">No following users found.</div>
              </>
            )}
          </div>
        </div>
      </div>
      {showThreeDots && (
        <div
          ref={popupRef}
          className="max-w-[185px] sm:max-w-[320px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center w-full overflow-hidden z-[62] sm:h-auto sm:block flex sm:flex-row flex-col items-center sm:scroll-auto justify-center shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
          <button
            onClick={() => {
              setShowThreeDots(false);
              setBlock(true);
            }}
            className="font-normal sm:text-base text-xs text-red-500 py-2 inline-block cursor-pointer w-full">
            Block
          </button>
          <hr className="w-full" />
          <button
            onClick={() => {
              setReport(true);
              setShowThreeDots(false);
            }}
            className="font-normal sm:text-base text-xs text-red-500 py-2 inline-block cursor-pointer w-full">
            Report
          </button>
          <hr className="w-full" />
          <hr className="w-full" />
          <button
            className="font-normal sm:text-base text-xs py-2 inline-block w-full"
            onClick={() => setShowThreeDots(false)}>
            Cancel
          </button>
        </div>
      )}
      {report && (
        <form onSubmit={handleClick}>
          <div
            ref={popupRef}
            className="max-w-[320px] sm:max-w-[372px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center w-full overflow-hidden z-[80] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] sm:pt-[45px] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] xl:pb-10 sm:px-[18px] sm:pb-[45px] px-6 py-5 flex-col">
            <p className=" font-medium sm:text-base text-sm text-center ">
              Report {followingData[id]?.userName}?
            </p>
            <p className="xl:text-base font-normal text-sm sm:my-6 my-4 text-center">
              Use this option to report inappropriate content or behaviour and prevent further
              interaction with this user. Your action helps maintain a safe environment for our
              community. Thank you for your contribution to our platform's integrity.
            </p>
            <p className="xl:text-base font-normal text-sm sm:mb-6 mb-4  text-center">
              Why are you reporting this account ?
            </p>
            <div className="flex justify-center">
              <div className="">
                {["Spam", "Offensive contents", "Fake account", "Other"].map((type) => (
                  <div className="flex items-center sm:mb-4 mb-[6px]" key={type}>
                    <input
                      type="radio"
                      name="report"
                      required
                      id={type}
                      value={type}
                      checked={selectedReportType === type}
                      onChange={(e) => setSelectedReportType(e.target.value)}
                    />
                    <label
                      htmlFor={type}
                      className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal">
                      {type}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <input
              type="text"
              placeholder="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              className="bg-transparent border-b border-[#EEEFEF] w-[187px] max-sm:text-xs"
            />
            <div className="flex justify-center mt-6">
              <button
                type="submit"
                className=" text-white font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-primary rounded-[100px] mx-auto sm:px-7 px-6  me-[25px]">
                Report
              </button>
              <button className="inline-block">
                <div className="h-[40px]  justify-center items-center bg-white flex rounded-[100px] ">
                  <button
                    className=" text-black font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-white rounded-[100px] mx-auto sm:px-7 px-6 border-grey border"
                    onClick={() => {
                      setReport(false);
                    }}>
                    Cancel
                  </button>
                </div>
              </button>
            </div>
            <div
              className="absolute right-[20px] top-[15px] cursor-pointer"
              onClick={() => setReport(false)}>
              <CloseIcon />
            </div>
          </div>
        </form>
      )}
      {reported && (
        <div
          ref={popupRef}
          className="max-w-[320px] sm:max-w-[444px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]  text-center w-full overflow-hidden z-[80] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] flex-col  xl:px-[46px] xl:py-6 sm:px-[72px] sm:py-[47px] px-[39px] py-[21px] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] ">
          <img className="w-[72px] m-auto" src={success_giff} alt="" />
          <p className="xl:text-base font-normal text-sm sm:mt-[30px] sm:mb-[30px] text-center mt-[10px] mb-[10px]">
            Reported successfully. You can also block this account. They won't be able to find your
            profile and posts on Wised. We won't let them know that you've blocked them.
          </p>
          <div className="flex justify-center gap-5">
            <button
              onClick={() => {
                setBlock(true);
                setReported(false);
              }}
              className=" text-white font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-primary rounded-[100px] mx-auto sm:px-7 px-6">
              Block
            </button>

            <button
              className=" text-black font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-white rounded-[100px] mx-auto sm:px-7 px-6 border-grey border"
              onClick={() => {
                setReported(false);
              }}>
              Cancel
            </button>
          </div>
          <div
            className="absolute right-[20px] top-[15px] cursor-pointer"
            onClick={() => setReported(false)}>
            <CloseIcon />
          </div>
        </div>
      )}
    </>
  );
}

export default Following;
